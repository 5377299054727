import { Helmet } from 'react-helmet-async'

import { FORM_LOGIN_ADMIN } from '../../../utils/constantsForms'
import useAdminLogin from '../../../utils/hooks/useAdminLogin'
import useTimer from '../../../utils/hooks/useTimer'
import { Wave } from '../../../components/SvgIcon'
import HookFormFieldsArray from '../../../components/HookForm/HookFormFieldsArray'

import './styles.css'

const LoginAdmin = () => {
  const { formRef, handleAdminLogin, buttonText, resendCode, isFirstStep } = useAdminLogin()
  const { seconds, timer, onChangeTimerTime } = useTimer()

  const Footer = () => {
    return (
      <>
        <button className="buttonAdminLogin" type="submit">
          {buttonText}
        </button>
        {!isFirstStep && (
          <p className="textResetCode">
            Login If you haven't received the code, click{' '}
            <button
              type="button"
              className="resendCode"
              disabled={seconds !== 0}
              onClick={() => resendCode().then(() => onChangeTimerTime(60))}
            >
              here
            </button>{' '}
            {timer}
          </p>
        )}
      </>
    )
  }

  return (
    <section className="innerAdminLogin">
      <Helmet title="Daydrop: Admin Sign-in" />
      <img src="/images/positiveLogo.png" alt="logo" className="logoAdminLogin" />
      <div className="innerFormAdmin">
        <h1 className="titleHome">Admin Sign-in</h1>
        <HookFormFieldsArray
          ref={formRef}
          className="formLogin"
          globalName="adminLogin"
          defaultFields={FORM_LOGIN_ADMIN}
          onSubmit={(data) => handleAdminLogin(data).finally(() => onChangeTimerTime(60))}
          Footer={Footer}
        />
      </div>
      <Wave />
    </section>
  )
}

export default LoginAdmin
