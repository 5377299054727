import React, { forwardRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import ControllerField from './components/ControllerField'

const HookForm = (
  {
    fields,
    className,
    onSubmit = () => {},
    Footer = () => null,
    defaultValues,
    focusField,
    ...rest
  },
  ref
) => {
  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    formState,
    setFocus,
    resetField,
    getValues,
  } = useForm({ defaultValues })

  useEffect(() => {
    if (focusField) {
      setFocus(focusField)
    }
  }, [setFocus, focusField])

  const submitHandler = handleSubmit(onSubmit)

  React.useImperativeHandle(ref, () => {
    return {
      watch: watch,
      reset: reset,
      setError: setError,
      dirtyFields: formState.dirtyFields,
      setValue: setValue,
      handleSubmit: handleSubmit,
      submitHandler: submitHandler,
      formState: formState,
      resetField: resetField,
      getValue: getValues,
    }
  })

  return (
    <form className={className} onSubmit={submitHandler} {...rest}>
      {fields.map(({ name, rules, ...rest }) => (
        <ControllerField key={name} name={name} control={control} rules={rules} {...rest} />
      ))}
      <Footer onSubmit={submitHandler} formState={formState} />
    </form>
  )
}

export default forwardRef(HookForm)
