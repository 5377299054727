import r from '../../utils/request'

export const getAllUsers = (params) => {
  return r.get('/admin/users', params)
}

export const getUserInfoById = (userId) => {
  return r.get(`/admin/users/${userId}`)
}

export const getUserThemeContents = (params) => {
  return r.get(`/admin/users/${params.userId}/themes/${params.themeId}/contents`, params)
}

export const getContentStatisticsDates = (params) => {
  return r.get(`/admin/dashboard/${params.themeId}/content-statistics/dates`, params)
}

export const changeUserNotes = (params) => {
  return r.putWithToast(`/admin/users/${params.userId}/note`, params, { success: 'Saved Note!' })
}

export const getUserNote = (userId) => {
  return r.get(`/admin/users/${userId}/note`)
}

export const deleteUser = ({ userId }) => {
  return r.deleteWithToast(`/admin/users/${userId}`, { success: 'User successfully deleted 🗑' })
}

export const downloadExcelUsersTable = (params) => {
  return r.downloadFile('/admin/users/export', 'get', 'UsersExport.xlsx', params)
}

export const getAllUserSubscriptions = (params) => {
  return r.get(`/admin/users/${params.userId}/subscriptions`, params)
}

export const getUserThemeSubscription = (params) => {
  return r.get(`/admin/users/${params.userId}/themes/${params.themeId}`, params)
}

export const pauseUserTheme = (params) => {
  return r.putWithToast(`/admin/users/${params.userId}/themes/${params.themeId}/pause`, params, {
    success: `Theme ${params.themeName} successfully paused`,
  })
}

export const manageThemeSubscription = (params, toastMessages) => {
  return r.putWithToast(
    `/admin/users/${params.userId}/themes/${params.themeId}/manage`,
    params,
    toastMessages
  )
}

export const cancelImmediatelyAllSubscriptions = (userId) => {
  return r.postWithToast(`/admin/users/${userId}/themes/cancel-all`, null, {
    success: 'All subscriptions was cancelled',
  })
}

export const getUserActivities = (params) => {
  return r.get(`/admin/users/${params.userId}/activities`, params)
}
