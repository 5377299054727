import { Tooltip, Whisper } from 'rsuite'
import { useRef } from 'react'

import useMessages from '../../utils/hooks/useMessages'
import { ThList } from '../SvgIcon'
import EmojiPicker from '../EmojiPicker'
import CustomInput from '../CustomInput'
import MessageConstants from '../MessageConstants'

import './styles.css'

const MessageInput = ({
  label,
  value,
  error,
  onChange,
  tooltipRef,
  messageConstants = {},
  innerClassName = '',
  onChangePreviewText = () => {},
  showMessageCounter = false,
  inputRef = useRef(null),
  ...rest
}) => {
  const { replaceVariables, messagesCount, messageLimit } = useMessages(value, messageConstants)

  const changeValue = (message) => {
    onChange(message)
    onChangePreviewText(replaceVariables(message, messageConstants))
  }

  const onEmojiSelect = (emoji) => {
    const cursorPosition = inputRef.current?.selectionStart ?? 0
    const text = value.slice(0, cursorPosition) + emoji.native + value.slice(cursorPosition)

    changeValue(text)
  }

  return (
    <div className={innerClassName}>
      <div className="innerToolbar">
        <label className="inputLabel mb-0">{label}</label>
        <div className="d-flex align-items-stretch gap-2">
          <Whisper
            trigger={['hover', 'click']}
            placement="bottomEnd"
            controlId="control-id-focus"
            enterable
            speaker={
              <Tooltip className="constantTooltip">
                <MessageConstants constants={messageConstants} />
              </Tooltip>
            }
          >
            <button type="button" className="rs-btn buttonConstants">
              <ThList size={20} />
            </button>
          </Whisper>
          <EmojiPicker emojiPickerSettings={{ onEmojiSelect }} />
        </div>
      </div>

      <Whisper
        ref={tooltipRef}
        placement="bottomStart"
        trigger="auto"
        speaker={<Tooltip className="warningCloseVariables">{error?.message}</Tooltip>}
      >
        <div>
          <CustomInput
            onChange={(e) => changeValue(e.target.value)}
            value={value}
            error={error}
            as="textarea"
            inputRef={inputRef}
            innerClassName="textareaMessage"
            placeholder="Enter your SMS text..."
            {...rest}
          />
        </div>
      </Whisper>
      {showMessageCounter && (
        <p className="numberMessages">
          SMS: <span style={messagesCount >= 4 ? { color: 'red' } : {}}>{messagesCount}</span>,
          chars left: {messageLimit}
        </p>
      )}
    </div>
  )
}

export default MessageInput
