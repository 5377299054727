import { Exit } from '../../SvgIcon'

const LoggedIn = ({ eventTime }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <Exit size={20} fill="blue" />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">Login to profile</p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default LoggedIn
