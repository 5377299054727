import { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import {
  REPLACEMENT_PHONE,
  FEEDBACK_TYPES_NAME,
  PHONE_PATTERN_REPLACE,
  DATE_FORMAT_WITH_TIME,
} from '../../../utils/constants'
import StarRating from '../../StarRating'

const UserFeedback = ({
  type,
  user,
  message,
  rating,
  createdAt,
  themeName,
  contentName,
  feedbackTypes,
  externalContentId,
}) => {
  const [showFullMessage, setShowFullMessage] = useState(false)
  const userFullName = `${user?.firstName || 'Anonymous'} ${user?.lastName || ''}`
  const isThemeFeedback = type === feedbackTypes?.theme

  const messageType = FEEDBACK_TYPES_NAME[type]
  const displayMessage = showFullMessage ? message : message?.slice(0, 280)

  return (
    <div className="userFeedback">
      <div className="userInfoBlock">
        <p className="userIcon">{user?.id ? userFullName?.trim()[0] : '�'}</p>
        <div>
          <p className="feedbackUserName">{userFullName}</p>
          <p className="feedbackAdditionalInfo">
            {user?.phone?.replace(PHONE_PATTERN_REPLACE, REPLACEMENT_PHONE)}
          </p>
          {isThemeFeedback && <StarRating containerStyles="feedbackStarRating" value={rating} />}
        </div>
      </div>
      <div className="feedbackMessage">
        {isThemeFeedback && (
          <p className="titleFeedbackTheme">
            <b>{themeName}</b>: {externalContentId} | {contentName}
          </p>
        )}
        {displayMessage}
        {message.length > 280 && (
          <span className="showMoreBtn" onClick={() => setShowFullMessage((oldState) => !oldState)}>
            {showFullMessage ? ' Show less' : '...Show more'}
          </span>
        )}
      </div>
      <div className="feedbackInfo">
        <p className="feedbackDate">{moment(createdAt).format(DATE_FORMAT_WITH_TIME)}</p>
        <div className="feedbackType">{messageType}</div>
      </div>
    </div>
  )
}

export default connect(({ references }) => ({
  feedbackTypes: references.feedbackTypes,
}))(UserFeedback)
