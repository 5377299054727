import { isEmpty, map, snakeCase } from 'lodash'

import {
  CLOSING_VARIABLE_CHARS,
  DEFAULT_MESSAGE_CONSTANTS,
  OPENING_VARIABLE_CHARS,
} from '../../utils/constants'
import { Copy } from '../SvgIcon'

import './styles.css'

const MessageConstants = ({ constants }) => {
  if (isEmpty(constants)) {
    return <p className="titleSettingsMessageCont mb-0">The constant list is currently empty</p>
  }

  return (
    <div className="innerMessageSettings">
      <p className="titleSettingsMessageCont">
        Using these variables in the message will swap them for dynamic values:
      </p>
      {map(constants, (value, key) => {
        const constant = OPENING_VARIABLE_CHARS + snakeCase(key) + CLOSING_VARIABLE_CHARS
        const label = DEFAULT_MESSAGE_CONSTANTS?.[key]

        return (
          <p
            key={key}
            className="d-flex align-items-center justify-content-between gap-2 messageConstant"
            onClick={() => navigator?.clipboard?.writeText(constant)}
          >
            <span>
              {constant} -> {label}
            </span>
            <Copy size={15} style={{ minWidth: 15 }} fill="#FFFFFF" className="cursorPointer" />
          </p>
        )
      })}
    </div>
  )
}

export default MessageConstants
