import { useState } from 'react'
import { connect } from 'react-redux'

import { PHONE_PATTERN_REPLACE, REPLACEMENT_PHONE } from '../../../utils/constants'
import ModalChangePhone from '../../../components/Modals/ModalChangePhone'
import Button from '../../../components/Button'
import InputMask from '../../../components/InputMask'
import UserInformationForm from './components/UserInformationForm'

import './styles.css'

const AccountInformation = ({ userPhone }) => {
  const [showModalChangePhone, setShowModalChangePhone] = useState(false)

  return (
    <>
      <h1 className="titleProfileSecond mb-3 text-center text-md-start">Account details</h1>
      <div className="innerDetails">
        <div className="detail profileBlockInner">
          <UserInformationForm />
        </div>
        <div className="detail">
          <div className="profileBlockInner">
            <p className="textExplanation">
              To change your phone number, press the button below and follow the on-screen
              instructions. Don't forget to confirm your new number.
            </p>
            <div className="innerPhoneInfoBlock">
              <InputMask
                innerClassName="inputFormUserDropdownMenu itemFormUserInfo"
                label="Phone"
                value={userPhone.replace(PHONE_PATTERN_REPLACE, REPLACEMENT_PHONE)}
                disabled
              />
              <Button
                onClick={() => setShowModalChangePhone(true)}
                customClass="buttonModalChangePhone"
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ModalChangePhone show={showModalChangePhone} onHide={() => setShowModalChangePhone(false)} />
    </>
  )
}

export default connect(({ user }) => ({ userPhone: user.data.phone }))(AccountInformation)
