import { Table, Tooltip, Whisper } from 'rsuite'
import { get, isEmpty } from 'lodash'

const TextTooltipCell = ({
  rowData,
  dataKey,
  tooltipList = [],
  appendComponent = () => {},
  ...props
}) => {
  return (
    <Table.Cell {...props}>
      <Whisper
        placement="top"
        trigger="hover"
        disabled={isEmpty(tooltipList)}
        speaker={
          <Tooltip>
            {tooltipList.map(({ label, key }) => (
              <p key={key}>
                <b>{label}:</b> {get(rowData, key)}
              </p>
            ))}
          </Tooltip>
        }
      >
        <p className="text-decoration-underline">
          {get(rowData, dataKey)}
          {appendComponent()}
        </p>
      </Whisper>
    </Table.Cell>
  )
}

export default TextTooltipCell
