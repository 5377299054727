import { connect } from 'react-redux'
import { useEffect, useMemo, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import moment from 'moment-timezone'

import { MESSAGE_TIME_FORMAT } from '../../utils/constants'
import './styles.css'

const ChatMessage = ({
  sentBy,
  messageSentBy,
  onRead = () => {},
  externalMessageId,
  message = '',
  isRead = true,
  createdAt,
  userName = '',
  adminName = '',
}) => {
  const messageRef = useRef()
  const { inViewport } = useInViewport(messageRef)
  const time = useMemo(() => moment(createdAt).calendar(null, MESSAGE_TIME_FORMAT), [createdAt])
  const isAdminMessage = useMemo(() => messageSentBy?.admin === sentBy, [sentBy, messageSentBy])
  const senderName = useMemo(() => (isAdminMessage ? adminName : userName), [isAdminMessage])

  useEffect(() => {
    if (inViewport && !isRead && !isAdminMessage) {
      onRead(externalMessageId)
    }
  }, [inViewport])

  return (
    <div ref={messageRef} className={`innerChatMessage ${isAdminMessage ? 'adminMessage' : ''}`}>
      <p className="chatMessage">
        {message}
        <span className="time">
          <b>{senderName}</b> | {time}
        </span>
      </p>
    </div>
  )
}

export default connect(({ references }) => ({ messageSentBy: references.messageSentBy }))(
  ChatMessage
)
