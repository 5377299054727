const Fire = ({ size, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 40.5487L23.88 48.5487C22.04 50.3887 20.6 52.6687 20.16 55.2287C18.96 62.5887 24.72 68.9487 32 68.9487C39.28 68.9487 45.04 62.5887 43.84 55.2687C43.4 52.7087 42 50.3887 40.12 48.5887L32 40.5487Z"
        fill="#4A709A"
      />
      <path
        d="M46.24 15.1487C41.52 21.0287 32 17.7087 32 10.1487V4.0287C32 0.828696 28.44 -1.0913 25.8 0.668697C16.48 6.9087 0 20.8287 0 40.9487C0 52.6287 6.24 62.8287 15.56 68.3887C12.72 64.3087 11.32 59.1487 12.32 53.6687C13.08 49.5087 15.32 45.7487 18.36 42.7887L29.2 32.1087C30.76 30.5887 33.24 30.5887 34.8 32.1087L45.72 42.8687C48.68 45.7887 50.92 49.4687 51.64 53.5887C52.64 59.0287 51.36 64.1487 48.56 68.2287C56.12 63.6287 61.72 55.9887 63.4 47.0287C65.84 33.9487 60.16 21.5487 50.52 14.6287C49.2 13.6287 47.32 13.8287 46.24 15.1487Z"
        fill="#4A709A"
      />
    </svg>
  )
}

export default Fire
