import { isNil } from 'lodash'
import { Button, Tooltip, Whisper } from 'rsuite'

const IconButton = ({
  children,
  onClick,
  buttonClassName,
  renderTooltipContent = () => null,
  show = true,
  id = 'button',
  placement = 'top',
  type = 'submit',
  triggerTooltip = 'hover',
  disabledButton = false,
  disabledTooltip = false,
}) => {
  if (!show) {
    return null
  }

  return (
    <Whisper
      placement={placement}
      controlId={id}
      trigger={triggerTooltip}
      disabled={disabledTooltip || isNil(renderTooltipContent())}
      speaker={<Tooltip>{renderTooltipContent()}</Tooltip>}
    >
      <Button
        id={id}
        onClick={onClick}
        disabled={disabledButton}
        type={type}
        className={buttonClassName}
      >
        {children}
      </Button>
    </Whisper>
  )
}

export default IconButton
