import { toast } from 'react-toastify'

import { setModalShareConfig } from '../../../redux/actions/ui'
import { camelize } from '../../helpers'
import useGiftMessages from '../useGiftMessages'
import { SubscriptionStatus } from '../../../components/Statuses'

export const handleCreatedCoupon =
  ({ giftCoupon, giftCouponMessageId }) =>
  async (dispatch) => {
    const { getGiftMessageInfo } = useGiftMessages()

    const modalShareData = await getGiftMessageInfo(giftCouponMessageId)

    toast.success(`You have purchased "${giftCoupon.code}"!`)
    dispatch(setModalShareConfig(modalShareData))
  }

export const handleUpdateSubscription = (data) => (queryClient) => {
  const { themeId, oldStatus, newStatus, themeName } = camelize(data)
  queryClient.invalidateQueries({ queryKeys: ['userSubscriptions'] })
  queryClient.invalidateQueries({ queryKeys: ['themeDetails', themeId] })

  toast.success(
    <div className="line">
      Subscription "<b className="firstLetterUppercase">{themeName}</b>" updated from{' '}
      <SubscriptionStatus customClass="sm" status={oldStatus} /> to{' '}
      <SubscriptionStatus customClass="sm" status={newStatus} />
    </div>
  )
}
