import { Tag } from 'rsuite'

const CustomTag = ({ tagInfo, deleteTag }) => {
  return (
    <Tag
      closable
      className="tagTheme"
      size="lg"
      style={{ backgroundColor: tagInfo?.color, color: '#FFFFFF', fontWeight: 500 }}
      onClose={deleteTag}
    >
      {tagInfo?.name}
    </Tag>
  )
}

export default CustomTag
