import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { ISO_DATE_FORMAT, THEMES_COLORS } from '../../../utils/constants'

const CalendarDay = ({ date, isAdmin, onClick, currentMonth, allMessages }) => {
  const todayDate = moment()
  const isCurrentMonth = date.month() === currentMonth.date.month()
  const isToday = todayDate.isSame(date, 'day')
  const isPastDay = todayDate.isAfter(date)

  const dataAllMessagesByDate = allMessages.filter(
    ({ deliveryDate }) => deliveryDate === date.format(ISO_DATE_FORMAT)
  )

  const themeId = dataAllMessagesByDate[0]?.themeId

  const getCalendarDayClasses = () => {
    const classes = []
    if (!isCurrentMonth) classes.push('notCurrentMonth')
    if (isPastDay && !isEmpty(dataAllMessagesByDate)) classes.push('messageReceived')
    return classes.join(' ')
  }

  const calendarDayStyles = {
    backgroundColor:
      dataAllMessagesByDate.length === 1
        ? THEMES_COLORS[themeId % THEMES_COLORS.length]
        : 'transparent',
    color: dataAllMessagesByDate.length === 1 ? '#FFFFFF' : '',
    cursor: isPastDay || isToday ? 'pointer' : '',
  }

  const calendarDayClasses = getCalendarDayClasses()

  const handleDayClick = () => {
    if (!isEmpty(dataAllMessagesByDate) && (isPastDay || isAdmin)) onClick(date)
  }

  return (
    <td style={calendarDayStyles} className={calendarDayClasses} onClick={handleDayClick}>
      {date.format('DD')}
      {dataAllMessagesByDate.length > 1 && (
        <span className="eventsMore">{dataAllMessagesByDate.length}</span>
      )}
      {isToday && <span className="activeDate" />}
    </td>
  )
}

export default CalendarDay
