import { useState } from 'react'

import { Messenger } from '../SvgIcon'
import Chat from './component/Chat'

import './styles.css'

const ChatWindow = ({ isBlocked }) => {
  const [showChat, setShowChat] = useState(false)

  return (
    <div className="innerChat">
      <div onClick={() => setShowChat((oldState) => !oldState)} className="innerChatIcon">
        <Messenger fill="#FFFFFF" size={20} className="chatIcon" />
      </div>
      {showChat && <Chat isBlocked={isBlocked} onHide={() => setShowChat(false)} />}
    </div>
  )
}

export default ChatWindow
