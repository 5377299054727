import { useState } from 'react'
import { isNil } from 'lodash'

import { SpaceDashboard } from '../SvgIcon'
import './styles.css'

const GiftImage = ({ imgSrc, className = '', alt, ...rest }) => {
  const [isLoadError, setIsLoadError] = useState(false)

  if (isNil(imgSrc) || isLoadError) {
    return (
      <div className={`innerGiftMask ${className}`} {...rest}>
        <SpaceDashboard fill="#c1c8d2" size={20} />
      </div>
    )
  }

  return (
    <img
      className={className}
      onError={() => setIsLoadError(true)}
      src={imgSrc}
      alt={alt}
      {...rest}
    />
  )
}

export default GiftImage
