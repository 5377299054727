import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { OTP_FIELD } from '../../constantsForms'
import { changePhoneNumber, confirmChangePhoneNumber } from '../../../api/user'
import { setNewPhoneNumber } from '../../../redux/actions/user'
import useTimer from '../useTimer'

const useUpdatePhone = ({ onUpdatedPhone = () => {} }) => {
  const [showConfirm, setShowConfirm] = useState(false)

  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { seconds, timer, onChangeTimerTime } = useTimer()

  const handleRequestWithToast = {
    success: 'Sent verification code',
    pending: 'Sending code...',
  }

  const handleSendConfirmCode = (data) => {
    changePhoneNumber(data, handleRequestWithToast)
      .then(() => {
        setShowConfirm(true)
        onChangeTimerTime(60)

        formRef.current.update(0, {
          ...OTP_FIELD,
          onSuccess: () => formRef.current?.handleSubmit(handleConfirmChangePhoneNumber)(),
        })
      })
      .catch(() => formRef.current?.setError('phone', {}))
  }

  const handleConfirmChangePhoneNumber = (data) => {
    confirmChangePhoneNumber(data)
      .then(() => {
        const formattedPhone = data.phone.replace(/[\s()-]/g, '')
        dispatch(setNewPhoneNumber(formattedPhone))
        setShowConfirm(false)
      })
      .then(onUpdatedPhone)
      .catch(() => formRef.current?.setError('code', {}))
  }

  const handleUpdatePhone = (data) => {
    if (!showConfirm) {
      handleSendConfirmCode(data)
      return
    }

    handleConfirmChangePhoneNumber(data)
  }

  return {
    formRef,
    timerTime: timer,
    handleUpdatePhone,
    timeSeconds: seconds,
    handleSendConfirmCode,
    isConfirmStep: showConfirm,
    submitButtonText: showConfirm ? 'Confirm' : 'Update',
    hideConfirmStep: () => setShowConfirm(false),
  }
}

export default useUpdatePhone
