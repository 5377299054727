import { useCallback } from 'react'

import { FORM_LOGIN_USER } from '../../../utils/constantsForms'
import useUserLogin from '../../../utils/hooks/useUserLogin'
import HookFormFieldsArray from '../../../components/HookForm/HookFormFieldsArray'

import './styles.css'

const LoginUser = () => {
  const { formRef, timerTime, handleLogin, timeSeconds, isConfirmStep, handleSendConfirmCode } =
    useUserLogin()

  const Footer = useCallback(() => {
    return (
      <div className="innerFooterFormUserLogin">
        <button className="buttonUserLogin" type="submit">
          Login
        </button>
        {isConfirmStep && (
          <p className="textResetCode text-center">
            If you haven't received the code, click{' '}
            <button
              onClick={() => handleSendConfirmCode(formRef.current.watch())}
              disabled={timeSeconds !== 0}
              type="button"
              className="resendCode"
            >
              here
            </button>{' '}
            {timerTime}
          </p>
        )}
      </div>
    )
  }, [timeSeconds])

  return (
    <section className="userLogin userPages d-flex flex-column flex-grow-1">
      <div className="innerContentUserLogin flex-grow-1">
        <img src="/images/positiveLogo.png" alt="logo" className="appLogoLogin" />
        <div className="innerFormUserLogin">
          <h1 className="titleUserLogin">Sign In</h1>
          <HookFormFieldsArray
            ref={formRef}
            Footer={Footer}
            className="formUserLogin"
            globalName="userLogin"
            onSubmit={handleLogin}
            defaultFields={FORM_LOGIN_USER}
          />
        </div>
        <div />
      </div>
      <img src="/images/pier.webp" alt="Login" className="imgUserLogin" />
      <div className="darkerBg" />
    </section>
  )
}

export default LoginUser
