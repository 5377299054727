import { debounce } from 'lodash'

import { IconSearch } from '../../SvgIcon'
import PrimaryButton from '../../PrimaryButton'

const Header = ({ setSearchValue, setShowFormManage }) => {
  const searchTags = debounce((e) => setSearchValue(e.target.value), 300)

  return (
    <div className="topMenu">
      <div className="innerInputSearch">
        <IconSearch className="searchIcon" fill="#040849" size={20} />
        <input onChange={searchTags} placeholder="Search..." className="inputSearchList" />
      </div>
      <PrimaryButton
        customClass="buttonNewItemList"
        text="New"
        onClick={() => setShowFormManage((oldState) => !oldState)}
      />
    </div>
  )
}

export default Header
