import { useState } from 'react'
import { isEmpty } from 'lodash'

import ModalConfirm from '../Modals/ModalConfirm'
import Header from './component/Header'

import './styles.css'

const ManageList = ({
  Row,
  data,
  setData,
  removeItem,
  FormManage,
  createItem,
  tableHeader,
  title = '',
  emptyText = '',
  textConfirmDelete = '',
}) => {
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showFormManage, setShowFormManage] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const searchResult = data.filter(
    ({ name }) => !searchValue || name.toLowerCase().includes(searchValue.toLowerCase())
  )

  return (
    <div>
      <h1 className="titleManageList">{title}</h1>
      <div className="innerPageManageList">
        <Header setShowFormManage={setShowFormManage} setSearchValue={setSearchValue} />
        <FormManage
          show={showFormManage}
          setShow={setShowFormManage}
          onSubmit={createItem}
          buttonText="Create"
        />
        <table className="customTableManageList">
          <thead className="innerHeadTable">
            <tr>
              {tableHeader?.map(({ title, className = '' }, index) => (
                <th key={index} className={`headColumnManageList ${className}`}>
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          {!isEmpty(searchResult) && (
            <tbody>
              {searchResult.map((item) => (
                <Row
                  item={item}
                  key={item.id}
                  setShowModalDelete={setShowModalDelete}
                  setDataTags={setData}
                />
              ))}
            </tbody>
          )}
        </table>
        {isEmpty(searchResult) && <p className="emptyManageList">{emptyText}</p>}
        <ModalConfirm
          show={showModalDelete}
          onHide={() => setShowModalDelete(false)}
          title="Are you sure?"
          text={textConfirmDelete}
          textButton="Delete"
          handleClickButton={(item) => removeItem(item, setShowModalDelete)}
        />
      </div>
    </div>
  )
}

export default ManageList
