import React from 'react'

const CustomToggle = React.forwardRef(({ children, onClick, disabled = false }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      if (!disabled) {
        onClick(e)
      }
    }}
    className="toggleDrop"
  >
    {children}
  </a>
))

export default CustomToggle
