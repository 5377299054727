import InputDate from '../../../../components/InputDate'

const SelectAbsolutTime = ({ onChange, error, value }) => {
  return (
    <div className="innerCountDays">
      <p className="titleCountDays">The specific date the message was sent:</p>
      <InputDate
        value={value}
        error={error}
        onChange={onChange}
        showMonthDropdown={true}
        innerClassName="innerInputDate"
      />
    </div>
  )
}

export default SelectAbsolutTime
