import { PauseCircleFill } from '../../SvgIcon'

const SubscribingStarted = ({ themeName, eventTime }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <PauseCircleFill
          size={20}
          fill="var(--pause-color)"
          style={{ backgroundColor: 'var(--pause-color-text)', borderRadius: '50%' }}
        />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">
          User has verified phone number on the theme "
          <b className="firstLetterUppercase">{themeName}</b>"
        </p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default SubscribingStarted
