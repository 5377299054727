import { useMemo } from 'react'
import { camelCase } from 'lodash'
import moment from 'moment-timezone'

import ShowMoreText from '../../ShowMoreText'
import { CONTENT_STATUSES_DETAILS } from '../../../utils/constants'

const Message = ({ body, deliveryTime, status }) => {
  const formattedStatus = useMemo(() => camelCase(status), [status])

  return (
    <div className="innerMessage">
      <p className="deliveryTime">{moment(deliveryTime, 'HH:mm:ss').format('h:mm a')}</p>
      <p className="textMessages">
        <ShowMoreText text={body} maxChars={280} />
        <p
          style={{ color: CONTENT_STATUSES_DETAILS?.[formattedStatus]?.fill }}
          className="messageStatus"
        >
          <span
            style={{ backgroundColor: CONTENT_STATUSES_DETAILS?.[formattedStatus]?.fill }}
            className="point"
          />
          <span>{CONTENT_STATUSES_DETAILS?.[formattedStatus]?.label}</span>
        </p>
      </p>
    </div>
  )
}

export default Message
