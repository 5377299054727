import { useEffect, useRef, useState } from 'react'
import { delay } from 'bluebird'

import { updateInterest } from '../../../../api/interests'
import { Bin, Pencil } from '../../../../components/SvgIcon'
import FormManageInterest from './FormManageInterest'

const RowTable = ({ item, setShowModalDelete, setDataTags }) => {
  const [showChangeForm, setShowChangeForm] = useState(false)
  const pencilRef = useRef()

  const updateTagInfo = (dataInterest) => {
    updateInterest(dataInterest)
      .then(() => {
        setDataTags((oldState) =>
          oldState.map((item) => (item.id === dataInterest.id ? dataInterest : item))
        )
      })
      .then(() => setShowChangeForm(false))
  }

  const handleClickOutside = (e) => {
    const handleClickClosedForm =
      !pencilRef.current?.contains(e.target) && e.target.id === 'pencilId'

    return handleClickClosedForm && delay(200).then(() => setShowChangeForm(false))
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <>
      <tr>
        <td>
          <span className="interestName">{item?.name}</span>
        </td>
        <td className="text-center">{item?.usersCount}</td>
        <td className="cursorPointer text-center">
          {!showChangeForm && (
            <Pencil
              size={20}
              onClick={() => setShowChangeForm(!showChangeForm)}
              pencilRef={pencilRef}
            />
          )}
        </td>
        <td className="cursorPointer text-end">
          <Bin
            onClick={() => setShowModalDelete({ id: item.id })}
            size={20}
            fill="rgb(149 97 97)"
          />
        </td>
      </tr>
      <tr>
        <td className="rowFormInterest" colSpan="4">
          <FormManageInterest
            show={showChangeForm}
            value={item}
            setShow={setShowChangeForm}
            onSubmit={updateTagInfo}
            buttonText="Change"
          />
        </td>
      </tr>
    </>
  )
}

export default RowTable
