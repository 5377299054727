import { useMemo } from 'react'
import { isNil } from 'lodash'
import { Tooltip, Whisper } from 'rsuite'

import { SUBSCRIPTION_STATUSES } from '../../utils/constants'
import {
  AutoRenew,
  Bell,
  CancelIcon,
  CartFill,
  CreditCardOff,
  CurrencyDollar,
  Flag,
  Info,
  PauseCircleFill,
} from '../SvgIcon'
import { SubscriptionStatus } from '../Statuses'

import './styles.css'

const STATUS_BADGE_INFO = {
  [SUBSCRIPTION_STATUSES.ACTIVE]: {
    Icon: CurrencyDollar,
    additionalText: 'subscribed',
  },
  [SUBSCRIPTION_STATUSES.TRIAL]: { Icon: Bell, additionalText: 'started trial' },
  [SUBSCRIPTION_STATUSES.PAUSED]: { Icon: PauseCircleFill, additionalText: 'paused subscription' },
  [SUBSCRIPTION_STATUSES.FINISHED]: { Icon: Flag, additionalText: 'finished theme' },
  [SUBSCRIPTION_STATUSES.CANCELED]: { Icon: CancelIcon, additionalText: 'canceled subscription' },
  [SUBSCRIPTION_STATUSES.ABANDONED_CART]: {
    Icon: CartFill,
    additionalText: 'added to abandoned cart',
  },
  [SUBSCRIPTION_STATUSES.NON_RENEW_ACTIVE]: {
    Icon: AutoRenew,
    additionalText: 'stopped recurring payment',
  },
  [SUBSCRIPTION_STATUSES.NON_RENEW_TRIAL]: {
    Icon: AutoRenew,
    additionalText: 'stopped trial period',
  },
  [SUBSCRIPTION_STATUSES.PAYMENT_FAILED]: {
    Icon: CreditCardOff,
    additionalText: 'failed to collect payment',
  },
}

const SubscriptionStatusBadge = ({ status, total, totalAtPeriod, description }) => {
  const { Icon, additionalText } = useMemo(() => STATUS_BADGE_INFO[status], [status])

  return (
    <div className="subscriptionStatusBadge">
      <div className="innerSubscriptionStatusIcon">
        <Icon fill="var(--catalina-blue)" color="var(--catalina-blue)" size={23} />
      </div>
      <div className="innerInfo">
        <SubscriptionStatus status={status} customClass="sm" />
        <p className="total">
          Total: <span>{total}</span>
        </p>
        {totalAtPeriod > 0 && <span className="additionalInfo">+1 {additionalText}</span>}
      </div>
      {!isNil(description) && (
        <Whisper
          placement="autoHorizontal"
          trigger="hover"
          speaker={<Tooltip>{description}</Tooltip>}
        >
          <div className="iconInfo">
            <Info fill="var(--swatch--cta-blue)" size={25} />
          </div>
        </Whisper>
      )}
    </div>
  )
}

export default SubscriptionStatusBadge
