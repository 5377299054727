const PaperPlane = ({ size, fill, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 78 68"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.6 67.6L75.4 37.68C78.64 36.28 78.64 31.72 75.4 30.32L5.6 0.400006C2.96 -0.759994 0.04 1.20001 0.04 4.04001L0 22.48C0 24.48 1.48 26.2 3.48 26.44L60 34L3.48 41.52C1.48 41.8 0 43.52 0 45.52L0.04 63.96C0.04 66.8 2.96 68.76 5.6 67.6Z"
        fill={fill}
      />
    </svg>
  )
}

export default PaperPlane
