import r from '../utils/request'

export const getAllInterests = () => {
  return r.get('/interests')
}

export const createInterest = (params) => {
  return r.postWithToast('/admin/interests', params, { success: 'Interest created successfully' })
}

export const updateInterest = (params) => {
  return r.putWithToast(`/admin/interests/${params.id}`, params, {
    success: 'Interest updated successfully',
  })
}

export const deleteInterest = (id) => {
  return r.deleteWithToast(
    `/admin/interests/${id}`,
    {},
    { success: 'Interest has been successfully deleted' }
  )
}
