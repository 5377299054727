import moment from 'moment-timezone'
import { startCase } from 'lodash'

const useTime = () => {
  const getTime = (value) => {
    const dateTime = moment(value.toDate())
    const time = dateTime.format('HH:mm')
    const timeZone = dateTime.utcOffset() / 60
    return { time, timeZone }
  }

  const getMessageTime = (message) => {
    const { deliveryDate, deliveryTime } = message
    return moment(`${deliveryDate}T${deliveryTime}`)
  }

  const years = Array.from({ length: 30 }, (_, i) => ({
    title: 2021 + i,
  }))

  const months = moment.months().map((title, number) => ({ title, number }))

  const getTimezoneInfo = (name) => {
    if (!name) {
      return {}
    }

    const zoneAttribute = moment.tz(name).zoneAbbr()
    const cityName = startCase(name?.replace('America/', ''))
    const timezoneOffset = Math.round(moment.tz(name).utcOffset() / 60)
    return {
      title: `${cityName},  ${zoneAttribute}, (UTC ${
        timezoneOffset >= 0 ? '+' : ''
      }${timezoneOffset})`,
      timezoneOffset,
      name,
    }
  }

  const timeZone = moment.tz.zonesForCountry('US').map(getTimezoneInfo)
  const additionalTimezone = getTimezoneInfo('Europe/Kyiv')
  const allTimezones = [...timeZone, additionalTimezone]

  const weekdayShort = moment.weekdaysShort()
  const getWeekDays = (month, year) => {
    const endDate = moment.utc().year(year).month(month).endOf('month')
    const daysInMonth = Array.from({ length: endDate.date() }, (_, i) =>
      moment
        .utc()
        .year(year)
        .month(month)
        .date(i + 1)
    )

    const uniqueWeeks = daysInMonth
      .map((day) => ({ day, week: day.week() }))
      .filter(({ week }, i, arr) => arr.findIndex((info) => info.week === week) === i)

    return uniqueWeeks.map(({ week, day }) => ({
      week,
      days: Array.from({ length: 7 }, (_, i) =>
        moment.utc(day).week(week).startOf('week').add(i, 'day')
      ),
    }))
  }

  const getGreeting = () => {
    const currentHour = moment().format('HH')
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good morning'
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good afternoon'
    } else if (currentHour >= 18 && currentHour < 21) {
      return 'Good evening'
    } else {
      return 'Good night'
    }
  }

  return {
    years,
    months,
    getTime,
    getWeekDays,
    allTimezones,
    weekdayShort,
    getMessageTime,
    getTimezoneInfo,
    greeting: getGreeting(),
  }
}

export default useTime
