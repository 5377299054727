import r from '../../utils/request'

export const importThemeMessages = (params) => {
  return r.uploadWithToast(`/admin/themes/${params.id}/import`, params, 'put', {
    success: 'SMS content uploaded successfully',
    error: 'Invalid file format. Please upload a valid file',
  })
}

export const getThemeTags = (themeId) => {
  return r.get(`/admin/themes/${themeId}/tags`)
}

export const changeThemeTags = (params) => {
  return r.put(`/admin/themes/${params.id}/tags/sync`, params)
}

export const deleteThemeTag = (themeId, tagId) => {
  return r.delete(`/admin/themes/${themeId}/tags/${tagId}`)
}
