import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Table } from 'rsuite'
import { isEmpty } from 'lodash/lang'

import { getThemeContent } from '../../../api/admin/themeContent'
import useContent from '../../../utils/hooks/useContent'

import './styles.css'

const { Column, HeaderCell, Cell } = Table

const ImageCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} wordWrap="break-all" style={{ padding: 0 }}>
    <div
      style={{
        width: 40,
        height: 40,
        background: '#f5f5f5',
        borderRadius: 6,
        marginTop: 2,
        overflow: 'hidden',
        display: 'inline-block',
      }}
    >
      <img
        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        src={rowData[dataKey]}
        alt="Meditaiton image"
      />
    </div>
  </Cell>
)

const BodyCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props} style={{ padding: 0 }}>
      <p title={rowData[dataKey]} className="hideTwoRows">
        {rowData[dataKey]}
      </p>
    </Cell>
  )
}

const ModalTableContent = ({ show, onHide, themeId }) => {
  const [loading, setLoading] = useState(true)
  const [meditations, setMeditations] = useState([])
  const { redirectToContentByContentId } = useContent({ themeId })

  useEffect(() => {
    if (show && isEmpty(meditations)) {
      setLoading(true)
      getThemeContent(themeId)
        .then(({ data }) => {
          setMeditations(data)
        })
        .finally(() => setLoading(false))
    }
  }, [show])

  return (
    <Modal
      size="xl"
      centered
      show={show}
      onHide={onHide}
      dialogClassName="modalTableMeditationContent"
    >
      <Modal.Header closeButton>
        <Modal.Title>Meditations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          loading={loading}
          height={400}
          wordWrap="break-word"
          data={meditations}
          id="table"
          rowClassName="cursorPointer"
          onRowClick={({ externalId }) => redirectToContentByContentId(externalId)}
        >
          <Column verticalAlign="middle" width={70} align="center">
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="externalId" />
          </Column>
          <Column verticalAlign="middle" width={80} align="center">
            <HeaderCell>Image</HeaderCell>
            <ImageCell dataKey="imageIcon" />
          </Column>

          <Column verticalAlign="middle" width={160}>
            <HeaderCell>VO Artist</HeaderCell>
            <Cell dataKey="voiceArtist" />
          </Column>

          <Column verticalAlign="middle" width={230}>
            <HeaderCell>Title</HeaderCell>
            <Cell dataKey="subtitle" />
          </Column>

          <Column verticalAlign="middle" minWidth={400} flexGrow={1}>
            <HeaderCell>Body Player</HeaderCell>
            <BodyCell dataKey="body" />
          </Column>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default ModalTableContent
