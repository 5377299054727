import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'rsuite'

import { FIELD_CHANGE_PHONE } from '../../../utils/constantsForms'
import useUpdatePhone from '../../../utils/hooks/useUpdatePhone'
import PrimaryButton from '../../PrimaryButton'
import HookFormFieldsArray from '../../HookForm/HookFormFieldsArray'

import './styles.css'

const ModalChangePhone = ({ show, onHide }) => {
  const {
    formRef,
    timerTime,
    timeSeconds,
    isConfirmStep,
    submitButtonText,
    hideConfirmStep,
    handleUpdatePhone,
    handleSendConfirmCode,
  } = useUpdatePhone({ onUpdatedPhone: onHide })

  const Footer = () => {
    return (
      <>
        {isConfirmStep && (
          <p className="textResetCode mt-0">
            If you haven't received the code, click the button {timerTime} -{' '}
            <button
              onClick={() => handleSendConfirmCode(formRef.current.watch())}
              disabled={timeSeconds !== 0}
              type="button"
              className="resendCode"
            >
              Resend code
            </button>
          </p>
        )}
        <Modal.Footer className="footerChangePhoneNumber">
          <Button onClick={onHide}>Cancel</Button>
          <PrimaryButton customClass="buttonChangePhone defaultButton" text={submitButtonText} />
        </Modal.Footer>
      </>
    )
  }

  useEffect(() => {
    if (!show) {
      hideConfirmStep()
    }
  }, [show])

  return (
    <Modal size="md" dialogClassName="modalChangePhone" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change Phone Number</Modal.Title>
      </Modal.Header>
      <Modal.Body className="formChangePhone user">
        <HookFormFieldsArray
          ref={formRef}
          className="formChangePhoneNumber"
          onSubmit={handleUpdatePhone}
          globalName="changePhone"
          defaultFields={FIELD_CHANGE_PHONE}
          Footer={Footer}
        />
      </Modal.Body>
    </Modal>
  )
}

export default ModalChangePhone
