import { CheckmarkDoneCircle } from '../../SvgIcon'

const ActivateAccount = ({ eventTime }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <CheckmarkDoneCircle fill="#32CD32" size={23} />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">Account was activated</p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default ActivateAccount
