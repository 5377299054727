import { useMemo } from 'react'

import {
  SUBSCRIPTION_STATUSES_INFO,
  ALL_NON_RENEW_SUBSCRIPTION_STATUSES,
} from '../../../utils/constants'
import TimeFilled from '../../SvgIcon/components/TimeFilled'

import '../styles.css'

const SubscriptionStatus = ({
  style,
  id = '',
  status = '',
  onClick = () => {},
  customClass = '',
}) => {
  const { bgColor, borderColor, color, text } = useMemo(
    () => SUBSCRIPTION_STATUSES_INFO[status] ?? {},
    [status]
  )

  return (
    <div
      style={{ backgroundColor: bgColor, borderColor, color, ...style }}
      onClick={() => onClick(id)}
      className={`statusCode ${customClass}`}
    >
      {ALL_NON_RENEW_SUBSCRIPTION_STATUSES.includes(status) ? (
        <TimeFilled
          size={17}
          fill="rgb(104,115,133)"
          className="timeIconSubscription"
          title="Subscription will be expired"
        />
      ) : null}
      {text}
    </div>
  )
}

export default SubscriptionStatus
