import { useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'

import { INTEREST_FIELD } from '../../../../utils/constantsForms'
import HookForm from '../../../../components/HookForm'
import PrimaryButton from '../../../../components/PrimaryButton'
import Button from '../../../../components/Button'

const FormManageInterest = ({ buttonText, setShow, show, value, onSubmit }) => {
  const formRef = useRef(null)

  const Footer = () => {
    const disableSubmitButton =
      isEmpty(formRef.current?.watch('name')) || isEmpty(formRef.current?.dirtyFields)
    return (
      <div className="innerButtonsManage">
        <PrimaryButton
          customClass="buttonNewItemList"
          text={buttonText}
          disabled={disableSubmitButton}
        />
        <Button type="button" customClass="buttonNewItemList" onClick={() => setShow(false)}>
          Cancel
        </Button>
      </div>
    )
  }

  useEffect(() => {
    formRef.current?.reset(value)
  }, [show])

  if (!show) {
    return null
  }

  return (
    <HookForm
      ref={formRef}
      onSubmit={(data) => onSubmit(data, formRef)}
      className="formManageInterest"
      fields={INTEREST_FIELD}
      Footer={Footer}
    />
  )
}

export default FormManageInterest
