import { useCallback } from 'react'
import { Dropdown, Toggle } from 'rsuite'
import moment from 'moment-timezone'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { DATE_FORMAT_MDY } from '../../../utils/constants'
import { toggleGiftStatus } from '../../../api/giftCoupons'
import { ExternalLinkAlt, ThreeDotsVertical } from '../../SvgIcon'
import GiftImage from '../../GiftImage'
import IconButton from '../../IconButton'

const GiftPricingRow = ({ item, onClick, onClickEdit }) => {
  const { id, photoUrl, name, price, createdAt, active, purchasesCount } = item
  const queryClient = useQueryClient()

  const mutationLoadNewStatus = useMutation({
    mutationFn: toggleGiftStatus,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['allGiftsPricing'] }),
  })

  const navigateToGiftPurchase = useCallback(() => {
    window.open(`${process.env.REACT_APP_REDIRECT_URI}/contents/gift-coupon/${id}`, '_blank')
  }, [id])

  return (
    <tr onClick={onClick} className="rowItemGiftPricing">
      <td>
        <Toggle
          size="sm"
          checked={active}
          className="toggleStatus"
          onChange={() => mutationLoadNewStatus.mutate(id)}
          loading={mutationLoadNewStatus.isPending}
        />
      </td>
      <td>
        <IconButton
          disabledButton={!active}
          renderTooltipContent={() =>
            active ? (
              <p>Open form to purchase a gift</p>
            ) : (
              <p>Activate gift pricing to enable purchases</p>
            )
          }
          onClick={navigateToGiftPurchase}
          type="button"
        >
          <ExternalLinkAlt size={15} fill="var(--catalina-blue)" />
        </IconButton>
      </td>
      <td>
        <GiftImage alt={name} imgSrc={photoUrl} className="giftImgItem" />
      </td>
      <td className="">
        <p>{name}</p>
        <p className="giftPriceItem">${price}</p>
      </td>
      <td>100% off forever</td>
      <td>{purchasesCount}</td>
      <td>{moment(createdAt).format(DATE_FORMAT_MDY)}</td>
      <td>
        <Dropdown
          placement="bottomEnd"
          renderToggle={(props, ref) => (
            <ThreeDotsVertical
              size={36}
              style={{ transform: 'rotate(90deg)' }}
              className="threePointsButton"
              ref={ref}
              {...props}
            />
          )}
        >
          <Dropdown.Item onClick={() => onClickEdit(item)}>Edit gift</Dropdown.Item>
        </Dropdown>
      </td>
    </tr>
  )
}

export default GiftPricingRow
