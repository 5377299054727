import { useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { map } from 'lodash'

import { connectThemeCalendarSync, disconnectThemeCalendarSync } from '../../../../api/calendar'
import GoogleAuthButton from '../../../../components/GoogleAuthButton'

const Providers = ({ userData, themeId, selectedProviders = [] }) => {
  const ProvidersButtons = {
    google: GoogleAuthButton,
    outlook: () => null,
    apple: () => null,
  }

  const Provider = ({ provider, isConnected }) => {
    const [loadSync, setLoadSync] = useState(false)
    const [isSelected, setIsSelected] = useState(selectedProviders.includes(provider))
    const SocialButton = ProvidersButtons?.[provider]
    const data = { provider, id: themeId }

    const handleError = () => {
      return toast.error('An error occurred during sync your calendars')
    }

    const onCompleteAuth = (authProvider) => {
      setLoadSync(true)
      connectThemeCalendarSync(data)
        .then(() => setIsSelected(true))
        .then(() => toast.success('New events will appear in an hour'))
        .catch((e) => handleError(e, authProvider))
        .finally(() => setLoadSync(false))
    }

    const onDisconnect = () => {
      setLoadSync(true)
      return disconnectThemeCalendarSync(data)
        .then(() => toast.success('All events have been deleted'))
        .then(() => setIsSelected(false))
        .catch(handleError)
        .finally(() => setLoadSync(false))
    }

    return (
      <SocialButton
        title="Connect"
        loadSync={loadSync}
        isSelected={isSelected}
        isConnected={isConnected}
        onDisconnect={onDisconnect}
        onComplete={onCompleteAuth}
      />
    )
  }

  return (
    <div className="innerProviders">
      {map(userData?.tokenProviders, (isConnected, provider) => (
        <Provider key={provider} provider={provider} isConnected={isConnected} />
      ))}
    </div>
  )
}

export default connect(({ user }) => ({ userData: user.data }))(Providers)
