import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { PAYMENT_OPTIONS, SUBSCRIPTABLE_TYPES, SUBSCRIPTION_STATUSES } from '../../utils/constants'
import { Info } from '../SvgIcon'
import IconButton from '../IconButton'

import './styles.css'

const SubscriptionCard = ({
  price,
  status,
  themeId,
  paymentLink,
  onClickInfoIcon,
  name = '',
  subscriptableType,
  imgSrc = '',
  innerCardClassName = '',
}) => {
  const paymentRecurringType = useMemo(
    () => PAYMENT_OPTIONS[subscriptableType],
    [subscriptableType]
  )

  if (
    subscriptableType === SUBSCRIPTABLE_TYPES.NO_SUBSCRIPTION &&
    status === SUBSCRIPTION_STATUSES.ABANDONED_CART
  ) {
    return (
      <a className={`subscriptionCard ${innerCardClassName}`} href={paymentLink}>
        <img className="subscriptionCardImg" src={imgSrc} alt={name} />
        <div className="subscriptionInfo">
          <p className="subscriptionProgramName">{name}</p>
          <p className="subscriptionPriceInfo">
            <span className="periodPay text-decoration-underline">Finish registration</span>
          </p>
        </div>
        <p className="subscriptionPriceInfo mobile">
          <span className="periodPay text-decoration-underline">Finish registration</span>
        </p>
      </a>
    )
  }

  return (
    <Link
      id={`theme-${themeId}`}
      className={`subscriptionCard ${innerCardClassName}`}
      to={`/account-home/theme-${themeId}`}
    >
      <img className="subscriptionCardImg" src={imgSrc} alt={name} />
      <div className="subscriptionInfo">
        <p className="subscriptionProgramName">{name}</p>
        <p className="subscriptionPriceInfo">
          {status === SUBSCRIPTION_STATUSES.CANCELED ? (
            <span className="periodPay">Subscription cancelled</span>
          ) : (
            <>
              <span className="price">${price}</span>
              {subscriptableType === SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION && '/'}{' '}
              <span className="periodPay">{paymentRecurringType?.full}</span>
            </>
          )}
        </p>
        <p className="subscriptionPaymentDateInfo">Next pay 5 AUG</p>
      </div>
      <p className="subscriptionPriceInfo mobile">
        {status === SUBSCRIPTION_STATUSES.CANCELED ? (
          <span className="periodPay">Subscription cancelled</span>
        ) : (
          <>
            <span className="price">${price}</span>
            {subscriptableType === SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION && '/'}{' '}
            <span className="periodPay">{paymentRecurringType?.short}</span>
          </>
        )}
      </p>
      <IconButton
        buttonClassName="iconSubscriptionInfo rounded-circle animationPulse"
        onClick={onClickInfoIcon}
        id={`button-subscriptionInfo-${themeId}`}
        renderTooltipContent={() => <p>Subscription info</p>}
      >
        <Info fill="var(--swatch--cta-blue)" size={25} />
      </IconButton>
    </Link>
  )
}

export default SubscriptionCard
