import { Controller } from 'react-hook-form'

import { INPUT_TYPES } from '../../../utils/constants'
import CustomDropdownPicker from '../../CustomDropdownPicker'
import DropdownTimezone from '../../DropdownTimezone'
import CustomTimePicker from '../../CustomTimePicker'
import CustomInputFile from '../../CustomInputFile'
import CustomDropdown from '../../CustomDropdown'
import CustomInput from '../../CustomInput'
import CustomRadio from '../../CustomRadio'
import RangeInput from '../../RangeInput'
import InputMask from '../../InputMask'
import InputDate from '../../InputDate'
import InputTag from '../../InputTag'
import OtpInput from '../../OtpInput'
import CustomCheckbox from '../../CustomCheckbox'
import DateTimePicker from '../../DateTimePicker'
import DateTimeRangePicker from '../../DateTimeRangePicker'

const INPUT_COMPONENTS = {
  [INPUT_TYPES.OTP]: OtpInput,
  [INPUT_TYPES.TEXT]: CustomInput,
  [INPUT_TYPES.INPUT_TAG]: InputTag,
  [INPUT_TYPES.INPUT_MASK]: InputMask,
  [INPUT_TYPES.INPUT_RANGE]: RangeInput,
  [INPUT_TYPES.INPUT_DATE]: InputDate,
  [INPUT_TYPES.CHECKBOX]: CustomCheckbox,
  [INPUT_TYPES.RADIO_BUTTON]: CustomRadio,
  [INPUT_TYPES.INPUT_FILE]: CustomInputFile,
  [INPUT_TYPES.INPUT_TIME]: CustomTimePicker,
  [INPUT_TYPES.INPUT_DROPDOWN]: CustomDropdown,
  [INPUT_TYPES.INPUT_DATE_TIME]: DateTimePicker,
  [INPUT_TYPES.INPUT_SELECT_TIMEZONE]: DropdownTimezone,
  [INPUT_TYPES.INPUT_DATE_TIME_RANGE]: DateTimeRangePicker,
  [INPUT_TYPES.INPUT_DROPDOWN_PICKER]: CustomDropdownPicker,
}

const ControllerField = ({
  name,
  control,
  rules,
  defaultValue,
  type = INPUT_TYPES.TEXT,
  hideControllerField,
  ...rest
}) => {
  const Component = INPUT_COMPONENTS[type]

  if (hideControllerField) {
    return null
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <Component
          inputRef={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={error}
          {...rest}
        />
      )}
    />
  )
}

export default ControllerField
