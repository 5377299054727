import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEmpty, omitBy } from 'lodash'
import { Drawer, Button } from 'rsuite'

import { FIELD_USER_NOTES } from '../../../utils/constantsForms'
import { changeUserNotes } from '../../../api/admin/user'
import HookForm from '../../HookForm'

const ModalUserNotes = ({ title, show, onHide, userNotesValue }) => {
  const formRef = useRef(null)
  const { userId } = useParams()
  const queryClient = useQueryClient()

  const mutationChangeUserNotes = useMutation({
    mutationKey: ['changeUserNotes', userId],
    mutationFn: (data) => {
      const formattedData = omitBy(data, isEmpty)
      return changeUserNotes({ ...formattedData, userId })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userNotes', userId] })
      onHide()
    },
  })

  useEffect(() => {
    formRef.current?.reset({ note: userNotesValue })
  }, [show])

  return (
    <Drawer placement="right" open={show} onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>{title}</Drawer.Title>
        <Drawer.Actions>
          <Button onClick={onHide}>Cancel</Button>
          <Button
            appearance="primary"
            style={{ backgroundColor: 'var(--catalina-blue)' }}
            onClick={() => formRef.current.handleSubmit(mutationChangeUserNotes.mutate)()}
          >
            Save
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body className="p-4">
        <HookForm
          ref={formRef}
          fields={FIELD_USER_NOTES}
          onSubmit={mutationChangeUserNotes.mutate}
        />
      </Drawer.Body>
    </Drawer>
  )
}

export default ModalUserNotes
