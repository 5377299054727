import { connect } from 'react-redux'

import { Messenger } from '../../SvgIcon'

const IconMessenger = ({ numberUnreadMessages = 0, className, size }) => {
  return (
    <div className="innerIconMessenger">
      <Messenger className={className} size={size} />
      {numberUnreadMessages >= 1 && (
        <span className="infoPreviewChatNumberUnreadMessages numberUnreadMessages">
          {numberUnreadMessages}
        </span>
      )}
    </div>
  )
}

export default connect(({ user }) => ({ numberUnreadMessages: user.data.numberUnreadMessages }))(
  IconMessenger
)
