import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { routes } from '../../router/routes'
import { ADMIN_ROUTE_MENU, ROLE_GUEST } from '../../utils/constants'
import { clearSession } from '../../utils/auth'
import { setRoleUser } from '../../redux/actions/user'
import { switchAdminMenu } from '../../redux/actions/ui'
import { ArrowRight, Exit } from '../SvgIcon'
import NavigateLink from './components/NavigateLink'
import IconMessenger from './components/IconMessenger'

import './styles.css'

const AdminMenu = ({ openMenu }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logoutAdmin = () => {
    clearSession()
      .then(() => dispatch(setRoleUser(ROLE_GUEST)))
      .then(() => navigate(routes.adminLogin))
  }

  return (
    <div className={`navigationAdmin ${!openMenu ? 'closeMenuAdmin' : ''}`}>
      <div className="wrapperMenuAdmin">
        <div className="btnHideMenuAdmin" onClick={() => dispatch(switchAdminMenu())}>
          <div className="innerArrowBtn" style={openMenu ? { transform: 'rotate(-180deg)' } : {}}>
            <ArrowRight size={25} style={{ fill: '#FFFFFF' }} />
          </div>
        </div>

        <img className="logoMenuAdmin" src="/images/negativeLogo.png" alt="" />
        <div className="innerMenuAdmin">
          <NavigateLink route={routes.messaging} text="Messaging" Icon={IconMessenger} />
          {ADMIN_ROUTE_MENU.map((item, index) => (
            <NavigateLink {...item} key={index} />
          ))}
        </div>
        <div className="innerLogoutMenuAdmin">
          <div
            onClick={logoutAdmin}
            className={`logoutMenuAdmin ${!openMenu ? 'closeBtnLogout' : ''}`}
          >
            <div className="innerIconMenuAdmin">
              <Exit size={30} fill="var(--menu-link)" />
            </div>
            Logout
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(({ ui }) => ({
  openMenu: ui.openAdminMenu,
}))(AdminMenu)
