import { useMemo } from 'react'
import { Tooltip, Whisper } from 'rsuite'

import { THEME_STATUSES, THEME_STATUSES_SETTINGS } from '../../utils/constants'

import './styles.css'

const SmallThemeCard = ({ id, name, status, isPublished }) => {
  const statusSettings = useMemo(
    () => THEME_STATUSES_SETTINGS?.[!isPublished ? THEME_STATUSES.INACTIVE : status],
    [isPublished, status, id]
  )

  return (
    <Whisper
      placement="topStart"
      trigger={['hover', 'focus']}
      enterable
      speaker={
        <Tooltip>
          <p>
            Status: <b>{statusSettings?.label}</b>
          </p>
        </Tooltip>
      }
    >
      <div className="innerSmallThemeCard">
        <span
          style={{ backgroundColor: statusSettings?.mainColor }}
          className="themeStatusIndicator"
        />
        <p className="smallThemeCardName">{name}</p>
      </div>
    </Whisper>
  )
}

export default SmallThemeCard
