import { Link } from 'react-router-dom'

import { DoubleArrowLeft } from '../SvgIcon'
import './styles.css'

const BackButton = ({ route = '', state = {}, className = '' }) => {
  return (
    <Link state={state} to={route} className={`innerBtnBack ${className}`}>
      <DoubleArrowLeft size={20} />
      Back
    </Link>
  )
}

export default BackButton
