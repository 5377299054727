import { CreditCard } from '../../SvgIcon'

const EnteredBillingInfo = ({ eventTime }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <CreditCard fill="#dba520" size={20} />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">Credit information recorded</p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default EnteredBillingInfo
