import { Bell } from '../../SvgIcon'

const CreatedSubscription = ({ themeName, eventTime }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <Bell size={20} fill="var(--active-color-text)" />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">
          Subscribed to "<b className="firstLetterUppercase">{themeName}</b>"
        </p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default CreatedSubscription
