import { useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'

import { setShowModalSupport } from '../../../redux/actions/ui'
import { SupportAgent, Cross } from '../../SvgIcon'

import './styles.css'

const ModalSupport = ({ showModalSupport }) => {
  const dispatch = useDispatch()
  const onHideModal = useCallback(() => dispatch(setShowModalSupport(false)), [])

  return (
    <Modal
      size="lg"
      centered
      show={showModalSupport}
      onHide={onHideModal}
      contentClassName="innerModalSupport"
    >
      <SupportAgent size={100} fill="#8797ac" className="iconSupport" />
      <h2 className="titleModalSupport">We're here to help!</h2>
      <p className="textModalSupport">
        If you need assistance, please contact us at{' '}
        <a href="mailto:support@daydrop.me">support@daydrop.me</a>.
      </p>
      <Cross size={25} onClick={onHideModal} className="crossModalSupport" />
    </Modal>
  )
}

export default connect(({ ui }) => ({ showModalSupport: ui.showModalSupport }))(ModalSupport)
