import { useMemo } from 'react'
import { Table } from 'rsuite'

import { DISCOUNT_CODE_INFO } from '../../../../utils/constants'

const GiftPricingInfoCell = ({ rowData, ...props }) => {
  const { bgColor, borderColor, color, text } = useMemo(
    () => DISCOUNT_CODE_INFO[rowData.status] ?? {},
    [rowData.status]
  )

  return (
    <Table.Cell {...props}>
      <div>
        <p className="tableGiftAnalyticCouponName">
          <b>Gift name: </b>
          {rowData?.pricingName}
        </p>
        <p className="tableGiftAnalyticCouponInfo">
          <b>Coupon: </b>
          {rowData?.couponCode}{' '}
          <span>
            <div style={{ backgroundColor: bgColor, borderColor, color }} className="statusCode">
              {text}
            </div>
          </span>
        </p>
      </div>
    </Table.Cell>
  )
}

export default GiftPricingInfoCell
