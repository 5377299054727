import { useRef } from 'react'
import { Button, Drawer } from 'rsuite'

import { FIELDS_FILTER_ALL_USERS } from '../../utils/constantsForms'
import HookForm from '../HookForm'

const ModalFilter = ({
  show,
  onHide,
  onSubmit,
  onReset,
  defaultValues = {},
  formFields = FIELDS_FILTER_ALL_USERS,
}) => {
  const formRef = useRef(null)

  return (
    <Drawer placement="right" open={show} onClose={onHide}>
      <Drawer.Header closeButton>
        <Drawer.Title>Filter</Drawer.Title>
        <Drawer.Actions>
          <Button onClick={onReset}>Reset</Button>
          <Button
            appearance="primary"
            style={{ backgroundColor: 'var(--catalina-blue)' }}
            onClick={() => formRef.current.handleSubmit(onSubmit)()}
          >
            Save
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body className="p-4">
        <HookForm
          ref={formRef}
          onSubmit={onSubmit}
          className="d-flex flex-column gap-3"
          fields={formFields}
          defaultValues={defaultValues}
        />
      </Drawer.Body>
    </Drawer>
  )
}

export default ModalFilter
