import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { Table, Tooltip, Whisper } from 'rsuite'

import StarRating from '../../StarRating'

const RatingList = ({ ratings }) => {
  const sortedRatings = useMemo(
    () => ratings?.sort((ratingA, ratingB) => ratingA?.starsCount > ratingB?.starsCount),
    [ratings]
  )

  return (
    <div>
      {sortedRatings.map(
        ({ starsCount, totalCount }) =>
          starsCount !== 0 && (
            <div key={starsCount} className="d-flex align-items-center">
              <StarRating containerStyles="innerRatingListCell" countStars={starsCount} disabled />
              <p className="text-white fw-bold"> - {totalCount}</p>
            </div>
          )
      )}
    </div>
  )
}

const StarRatingCell = ({ rowData, dataKey, ratingListKey, ...props }) => {
  return (
    <Table.Cell {...props}>
      <Whisper
        placement="top"
        trigger="hover"
        disabled={isEmpty(rowData[ratingListKey])}
        speaker={
          <Tooltip>
            <RatingList ratings={rowData[ratingListKey]} />
          </Tooltip>
        }
      >
        <div>
          <StarRating disabled value={rowData[dataKey]} />
        </div>
      </Whisper>
    </Table.Cell>
  )
}

export default StarRatingCell
