import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { isEmpty, isNil } from 'lodash'

import { HISTORY_BACK, MESSAGE_TYPES, PIPELINE_TYPES } from '../../../utils/constants'
import { setLoadingAdminProjectData } from '../../../redux/actions/ui'
import { getMessageVariables } from '../../../api/admin/messages'
import useMessages from '../../../utils/hooks/useMessages'
import { ArrowLeft, ArrowRight } from '../../../components/SvgIcon'
import BackButton from '../../../components/BackButton'
import PrimaryButton from '../../../components/PrimaryButton'
import IconButton from '../../../components/IconButton'
import Button from '../../../components/Button'

import PreviewMessage from './components/PreviewMessage'
import {
  ControllerMessageInput,
  ControllerTags,
  ControllerDelay,
  ControllerContent,
  ControllerWaitingDays,
} from './components/FormFields'

import pipelineConfig from './components/pipelineConfig'

import './styles.css'

const Message = () => {
  const [searchParams] = useSearchParams()
  const { pipelineType = PIPELINE_TYPES.CONTENT, messageType = MESSAGE_TYPES.RELATIVE } =
    Object.fromEntries(searchParams)

  const [previewText, setPreviewText] = useState('')
  const [dateView, setDateView] = useState(messageType)
  const [messageConstants, setMessageConstants] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const hookForm = useForm({ reValidateMode: 'onSubmit' })
  const { resetField, reset, handleSubmit, getValues } = hookForm

  const { programId, '*': messageId, themeId } = useParams()
  const isEditableMode = useMemo(() => !isEmpty(messageId), [messageId])

  const {
    formFields,
    showSwitch,
    arrayValues,
    changeMessage,
    createMessage,
    getMessageInfo,
    getNextMessage,
    showAdditionalButtons,
  } = useMemo(() => pipelineConfig[pipelineType]?.[messageType] || {}, [pipelineType, messageType])
  const manageThemeMessage = useMemo(
    () => (!isEditableMode ? createMessage : changeMessage),
    [isEditableMode]
  )

  const { messageLimit, messagesCount, replaceVariables, formatMessageData } = useMessages(
    getValues('body') || '',
    messageConstants
  )

  const lastOrder = getValues('lastOrder')
  const messageOrder = getValues('order')

  const renderFormFields = {
    body: ControllerMessageInput,
    tagsIds: ControllerTags,
    contentsIds: ControllerContent,
    onboardingDelay: ControllerDelay,
    waitingDays: ControllerWaitingDays,
  }

  const onSwitchType = (value) => {
    setDateView(value)
    resetField('waitingDays', { defaultValue: '' })
  }

  const resetForm = () => {
    setPreviewText('')
    resetField('body', { defaultValue: '' })
    resetField('tagsIds', { defaultValue: [] })
    resetField('contentsIds', { defaultValue: [] })
  }

  const resetMessageForm = useCallback(
    ({ messageInfo, constants }) => {
      const { messageType, absoluteDate, waitingDays, body } = messageInfo
      const isAbsoluteMessageType = messageType === MESSAGE_TYPES.ABSOLUTE

      reset({
        ...messageInfo,
        waitingDays: isAbsoluteMessageType ? absoluteDate : waitingDays,
      })
      setPreviewText(replaceVariables(body, constants))
      setDateView(messageType)
    },
    [messageId]
  )

  const calculateNextOrderMessage = useCallback(
    (isNextMessage, currentOrder) => {
      return isNextMessage ? currentOrder + 1 : currentOrder - 1
    },
    [messageId]
  )

  const fetchNewMessage = useCallback(
    async (isNextMessage) => {
      try {
        const nextOrderMessage = calculateNextOrderMessage(isNextMessage, messageOrder)
        const {
          data: { messages },
        } = await getNextMessage({
          size: 1,
          id: themeId,
          messageType,
          type: pipelineType,
          page: nextOrderMessage,
        })

        if (!isEmpty(messages)) {
          const nextMessage = messages[0]
          navigate(
            {
              pathname: `/admin/message/${programId}/${themeId}/${nextMessage?.id}`,
              search: `?pipelineType=${pipelineType}&messageType=${messageType}`,
            },
            { replace: true }
          )
        }
      } catch {
        dispatch(setLoadingAdminProjectData(false))
      }
    },
    [
      dispatch,
      getValues,
      programId,
      themeId,
      messageConstants,
      navigate,
      resetMessageForm,
      messageType,
      pipelineType,
      messageOrder,
    ]
  )

  const onSubmit = async (data) => {
    const allParams = {
      dateView,
      messageId,
      themeId,
      messageType,
      pipelineType,
      ...data,
    }
    const formattedData = formatMessageData({ allParams, arrayValues })

    return await manageThemeMessage(formattedData)
  }

  useEffect(() => {
    const fetchMessageInfo = async () => {
      dispatch(setLoadingAdminProjectData(true))
      if (isNil(formFields)) {
        dispatch(setLoadingAdminProjectData(false))
        return navigate(HISTORY_BACK)
      }

      try {
        const repeatType = messageType === MESSAGE_TYPES.REPEAT && MESSAGE_TYPES.RELATIVE
        const { data: dataConstants } = await getMessageVariables({
          themeId,
          messageType: repeatType ? repeatType : messageType,
          pipelineType,
        })
        setMessageConstants(dataConstants)

        if (!isEmpty(messageId)) {
          const { data } = await getMessageInfo(messageId)
          const orderInfo = await getNextMessage({
            size: 1,
            id: themeId,
            messageType,
            type: pipelineType,
            page: data.order,
          })
          const { lastPage: contentLastPAge, pagination: { lastPage } = {} } = orderInfo ?? {}
          const lastOrder = contentLastPAge ?? lastPage

          resetMessageForm({ messageInfo: { ...data, lastOrder }, constants: dataConstants })
          setPreviewText(replaceVariables(data.body, dataConstants))
          setDateView(data.messageType)
        }
      } finally {
        dispatch(setLoadingAdminProjectData(false))
      }
    }

    fetchMessageInfo()
  }, [messageId])

  return (
    <div>
      <div className="innerNameTheme">
        <h1 className="titleAdminPage">Enter SMS Topic & Text</h1>
        <BackButton route={HISTORY_BACK} />
      </div>
      <form
        className="formPost"
        onSubmit={handleSubmit((data) => onSubmit(data).then(() => navigate(HISTORY_BACK)))}
      >
        {formFields?.map((key) => {
          const RenderField = renderFormFields[key]

          return (
            <RenderField
              key={key}
              name={key}
              dateView={dateView}
              messageId={messageId}
              themeId={themeId}
              showSwitch={showSwitch}
              onSwitchType={onSwitchType}
              changePreviewText={setPreviewText}
              messageConstants={messageConstants}
              {...hookForm}
            />
          )
        })}
        <div className="innerButtonsForm">
          <p className="numberMessages">
            SMS: <span style={messagesCount >= 4 ? { color: 'red' } : {}}>{messagesCount}</span>,
            chars left: {messageLimit}
          </p>
          <PrimaryButton customClass="submitSettings" text="Save" />
          {!isEditableMode && showAdditionalButtons ? (
            <Button
              type="button"
              customClass="nextButton"
              onClick={handleSubmit((data) => onSubmit(data).then(resetForm))}
            >
              Append Next Message
            </Button>
          ) : null}
          {isEditableMode && showAdditionalButtons ? (
            <div className="d-flex gap-3">
              <IconButton
                buttonClassName="nextButton"
                onClick={handleSubmit((data) => onSubmit(data).then(() => fetchNewMessage(false)))}
                placement="top"
                type="button"
                disabledButton={messageOrder <= 1}
                disabledTooltip={messageOrder <= 1}
                renderTooltipContent={() => <p>Modify previous message</p>}
                triggerTooltip="hover"
              >
                <ArrowLeft size={20} fill="var(--catalina-blue)" />
              </IconButton>
              <IconButton
                buttonClassName="nextButton"
                onClick={handleSubmit((data) => onSubmit(data).then(() => fetchNewMessage(true)))}
                placement="top"
                type="button"
                disabledButton={messageOrder >= lastOrder}
                disabledTooltip={messageOrder >= lastOrder}
                renderTooltipContent={() => <p>Modify next message</p>}
                triggerTooltip="hover"
              >
                <ArrowRight fill="var(--catalina-blue)" size={20} />
              </IconButton>
            </div>
          ) : null}
        </div>
      </form>
      <div className="innerAdditionalContent">
        <PreviewMessage message={previewText} />
      </div>
    </div>
  )
}

export default Message
