import { getGiftCouponInfo, getGiftCouponMessageId } from '../../api/giftCoupons'

const useGiftMessages = () => {
  const getGiftMessageInfo = async (giftCouponMessageId) => {
    try {
      const couponIdResponse = await getGiftCouponMessageId(giftCouponMessageId)
      const couponId = couponIdResponse.data
      const giftCouponInfoResponse = await getGiftCouponInfo(couponId)
      const { substitutedMessage, templateVariables } = giftCouponInfoResponse.data

      return {
        show: true,
        title: 'Gift Coupon',
        additionalText:
          'Thank you! Your email has been successfully sent.\n If needed, you can conveniently copy this text and share it via another service.',
        subject: 'Share gift coupon',
        shareMessage: substitutedMessage,
        shareLink: templateVariables.redeemLink,
      }
    } catch (error) {
      // Handle any errors that occur during the fetching process
      console.error('Error fetching gift coupon data:', error)
      return null
    }
  }

  return { getGiftMessageInfo }
}

export default useGiftMessages
