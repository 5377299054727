import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { toNumber } from 'lodash'

import { FillSetting, IconUsers } from '../../../../components/SvgIcon'
import IconButton from '../../../../components/IconButton'

const AdditionalButtons = () => {
  const navigation = useNavigate()
  const { programId, themeId } = useParams()

  const navigateToProgramUsers = () => {
    const searchParams = { filterData: JSON.stringify({ themeId: [toNumber(themeId)] }) }
    navigation({
      pathname: '/admin/users',
      search: '?' + createSearchParams(searchParams).toString(),
    })
  }

  return (
    <div className="innerAdditionalButtons">
      <IconButton
        buttonClassName="additionalButton defaultButton"
        onClick={() => navigation(`/admin/programs/${programId}/${themeId}/settings`)}
        renderTooltipContent={() => <p>Theme Settings</p>}
        placement="bottom"
      >
        <FillSetting size={20} fill="var(--catalina-blue)" />
      </IconButton>
      <IconButton
        buttonClassName="additionalButton defaultButton"
        onClick={navigateToProgramUsers}
        renderTooltipContent={() => <p>Theme Users</p>}
        placement="bottomEnd"
      >
        <IconUsers size={20} fill="var(--catalina-blue)" />
      </IconButton>
    </div>
  )
}

export default AdditionalButtons
