import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { ROLES, WEBSOCKET_CONNECTION_CHANNEL, WEBSOCKET_EVENTS } from '../utils/constants'
import { setAdminNumberUnreadMessages, setWebsocketConnected } from '../redux/actions/user'
import useWebSocket from '../utils/hooks/useWebSocket'
import {
  handleCreatedCoupon,
  handleUpdateSubscription,
} from '../utils/hooks/useWebSocket/userEvents'

export const WebsocketContext = createContext({
  userSocketConnected: false,
  getUserBaseSocket: () => null,
  handleWebSocketSubscription: () => {},
})

export const useWebsocketContext = () => useContext(WebsocketContext)

const SocketProvider = ({ children, userRole, userId }) => {
  const [userSocketConnected, setUserSocketConnected] = useState(false)
  const { subscribe, websocket } = useWebSocket()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const userBaseSocket = useRef(null)

  const initUserSocketConnection = async () => {
    const subscription = await subscribe(`user-profile:${userId}`)

    subscription.on(WEBSOCKET_EVENTS.CREATED_COUPON, (data) => handleCreatedCoupon(data)(dispatch))

    subscription.on(WEBSOCKET_EVENTS.UPDATED_SUBSCRIPTION, (data) =>
      handleUpdateSubscription(data)(queryClient)
    )

    subscription.on(WEBSOCKET_EVENTS.UPDATED_COUPON, () => {
      toast.success('Coupon was applied!')
    })

    subscription.on(WEBSOCKET_EVENTS.READY, () => setUserSocketConnected(true))
    subscription.on(WEBSOCKET_EVENTS.CLOSE, () => setUserSocketConnected(false))
    subscription.on(WEBSOCKET_EVENTS.LEAVE_ERROR, () => setUserSocketConnected(false))
    subscription.on(WEBSOCKET_EVENTS.ERROR, () => setUserSocketConnected(false))

    return subscription
  }

  const initAdminSocketConnection = async () => {
    const subscription = await subscribe(WEBSOCKET_CONNECTION_CHANNEL.ADMIN)

    subscription.on(WEBSOCKET_EVENTS.SYNC_UNREAD_NOTIFICATION_COUNT, (numberUnreadMessages) => {
      dispatch(setAdminNumberUnreadMessages(numberUnreadMessages))
    })

    return subscription
  }

  const handleWebSocketSubscription = async () => {
    switch (userRole) {
      case ROLES.ROLE_ADMIN: {
        userBaseSocket.current = await initAdminSocketConnection()
        break
      }
      case ROLES.ROLE_USER: {
        userBaseSocket.current = await initUserSocketConnection()
        break
      }
      default:
        break
    }

    userBaseSocket.current?.on('open', () => {
      dispatch(setWebsocketConnected(true))
    })
  }

  const resetWebSocket = async () => {
    if (!websocket || !userBaseSocket.current) return
    await userBaseSocket.current?.close()
    await websocket?.close()
  }

  const getUserBaseSocket = () => {
    return userBaseSocket.current
  }

  useEffect(() => {
    handleWebSocketSubscription()

    return () => userBaseSocket.current?.close()
  }, [])

  useEffect(() => {
    if (userRole === ROLES.ROLE_GUEST) {
      resetWebSocket()
    }
  }, [userRole])

  return (
    <WebsocketContext.Provider
      value={{ userSocketConnected, getUserBaseSocket, handleWebSocketSubscription }}
    >
      {children}
    </WebsocketContext.Provider>
  )
}

export default connect(({ user }) => ({ userRole: user.role, userId: user.data.id }))(
  SocketProvider
)
