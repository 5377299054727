import r from '../utils/request'

export const enableGoogleCalendar = (props) => {
  return r.post('/google/auth', props)
}

export const connectThemeCalendarSync = (params) => {
  return r.post(`/themes/${params.id}/social/calendar/connect`, params)
}

export const disconnectThemeCalendarSync = (params) => {
  return r.post(`/themes/${params.id}/social/calendar/disconnect`, params)
}
