import { forwardRef } from 'react'

import './styles.css'

const Button = (
  { children, title = '', onClick = () => {}, type = 'submit', customClass = '', disable = false },
  ref
) => {
  return (
    <button
      ref={ref}
      type={type}
      title={title}
      onClick={onClick}
      disabled={disable}
      className={`defaultButton ${customClass}`}
    >
      {children}
    </button>
  )
}

export default forwardRef(Button)
