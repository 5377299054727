import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import ModalError from './Modals/ModalError'
import ModalShare from './Modals/ModalShare'
import Spinner from './Spinner'

const LoadingContainer = ({ isLoadingApp, showSpinner }) => {
  return (
    <>
      <Spinner show={isLoadingApp || showSpinner} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnHover
        draggable
        theme="light"
      />
      <ModalError />
      <ModalShare />
    </>
  )
}

export default connect(({ ui }) => ({
  showSpinner: ui.loadingApp,
}))(LoadingContainer)
