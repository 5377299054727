import { useParams } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import { SETTINGS_PAGINATION, TABLE_ROW_TYPE, TITLE_SURVEY_ANSWERS } from '../../../utils/constants'
import useSurveyAnalytics from '../../../utils/hooks/useSurveyAnalytics'
import CustomTable from '../../../components/CustomTable'
import CustomDropdown from '../../../components/CustomDropdown'
import AnswerAnalytics from './components/AnswerAnalytics'

import './styles.css'

const SurveyAnalytics = () => {
  const { surveyId } = useParams()
  const {
    answers,
    questions,
    pageCount,
    userResponses,
    setSelectedPage,
    selectedQuestion,
    totalCountAnswers,
  } = useSurveyAnalytics(surveyId)

  return (
    <div>
      <h1 className="titleAdminMainInfo">Survey Analytics</h1>
      <p className="subtitleSurveyResults">Results</p>
      <div className="innerSurveyAnalytics">
        <CustomDropdown
          innerDropdown="innerDropdownSurveyQuestion"
          title="Select question"
          label="Show result for:"
          titleClass="dropzoneTitle"
          data={questions}
          value={selectedQuestion?.data?.name}
          onChange={selectedQuestion.set}
        />
        <AnswerAnalytics
          answers={answers}
          selectedQuestion={selectedQuestion.data}
          totalCountAnswers={totalCountAnswers}
        />
        <CustomTable
          cellData={userResponses}
          customWrapperClassName="mt-3 innerTableSurveyAnswers"
          tableHeader={TITLE_SURVEY_ANSWERS}
          customTableClass="tableSurveyAnswers"
          type={TABLE_ROW_TYPE.TABLE_ROW_USER_ANSWER}
        />
        {pageCount > 1 ? (
          <ReactPaginate
            {...SETTINGS_PAGINATION}
            containerClassName={`${SETTINGS_PAGINATION.containerClassName} ms-0 me-0 justify-content-center`}
            pageCount={pageCount}
            pageRangeDisplayed={2}
            onPageChange={({ selected }) => setSelectedPage(selected + 1)}
          />
        ) : null}
      </div>
    </div>
  )
}

export default SurveyAnalytics
