import { isEmpty, isNil, snakeCase, startCase } from 'lodash'

import { IconFilter } from '../../SvgIcon'

const InfoUtmParam = ({
  name,
  value,
  customClassName = '',
  showFilter = false,
  onClickIconFilter = () => {},
}) => {
  const nameUtmParam = startCase(name)

  const handleFilterClick = (e) => {
    e.stopPropagation()

    const keyFilter = snakeCase(nameUtmParam)
    onClickIconFilter({ [keyFilter]: value })
  }

  if (isNil(value) || isEmpty(value)) {
    return null
  }

  return (
    <p className={customClassName}>
      {nameUtmParam}: {value}
      {showFilter && (
        <IconFilter
          size={15}
          fill="#FFFFFF"
          onClick={handleFilterClick}
          className="iconFilterUtmParams"
        />
      )}
    </p>
  )
}

export default InfoUtmParam
