import { useMemo } from 'react'
import { Tooltip, Whisper } from 'rsuite'
import { isEmpty, isNil, map, omitBy } from 'lodash'

import { ArrowSortedDown } from '../SvgIcon'
import InfoUtmParam from './components/ItemUtmParam'

import './styles.css'

const UtmParams = ({ utmParams = {}, showIconFilterUtmParams = false, ...rest }) => {
  const filteredUtmParams = useMemo(() => omitBy(utmParams, isNil), [utmParams])

  if (isEmpty(filteredUtmParams)) {
    return <p className="buttonUtmParams d-block">Manual</p>
  }

  return (
    <Whisper
      placement="top"
      trigger="hover"
      enterable
      speaker={
        <Tooltip>
          {map(filteredUtmParams, (value, name) => (
            <InfoUtmParam
              key={name}
              name={name}
              value={value}
              showFilter={showIconFilterUtmParams}
              {...rest}
            />
          ))}
        </Tooltip>
      }
    >
      <button className="buttonUtmParams">
        <span>{filteredUtmParams?.utmSource ?? 'Manual'}</span>
        <ArrowSortedDown className="iconArrowUtmParams" size={20} />
      </button>
    </Whisper>
  )
}

export default UtmParams
