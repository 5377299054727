import { OPENING_VARIABLE_CHARS, CLOSING_VARIABLE_CHARS } from '../../constants'

const countRegexEntries = (variable, string) => {
  let match
  let count = 0
  const variableRegex = new RegExp(variable, 'g')

  // eslint-disable-next-line no-unused-vars
  while ((match = variableRegex.exec(string)) !== null) {
    count++
  }

  return count
}

export const checkClosingVariables = (string) => {
  const countOpeningVariable = countRegexEntries(OPENING_VARIABLE_CHARS, string)
  const countClosingVariable = countRegexEntries(CLOSING_VARIABLE_CHARS, string)

  return countOpeningVariable !== countClosingVariable
}
