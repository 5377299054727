import { useCallback, useMemo } from 'react'

import { DISCOUNT_TYPES } from '../../../../utils/constants'

const PreviewGiftPrice = ({ monthlyPrice, discountInfo }) => {
  const monthlyPriceNum = useMemo(
    () => parseFloat(monthlyPrice?.replace(/\s/g, '')) || 0,
    [monthlyPrice]
  )
  const discountNum = useMemo(() => parseFloat(discountInfo?.discountAmount), [discountInfo])
  const yearlyPrice = useMemo(() => monthlyPriceNum * 12, [monthlyPriceNum])

  const applyDiscount = useCallback(
    (price) => {
      if (discountInfo?.discountType === DISCOUNT_TYPES.PERCENT) {
        return Math.max(price * (1 - discountNum / 100), 0)
      } else if (discountInfo?.discountType === DISCOUNT_TYPES.AMOUNT) {
        return Math.max(price - discountNum, 0)
      }
      return price
    },
    [discountNum, discountInfo]
  )

  const discountedMonthlyPrice = useMemo(
    () => applyDiscount(monthlyPriceNum),
    [applyDiscount, monthlyPriceNum]
  )
  const discountedYearlyPrice = useMemo(
    () => applyDiscount(yearlyPrice),
    [applyDiscount, yearlyPrice]
  )

  return (
    <div className="managePreviewPricing">
      <div>
        <h5 className="mb-1">Price preview:</h5>
        <p className="labelPriceInfo">
          For month: <b>{monthlyPriceNum.toFixed(2)} USD</b>
        </p>
        <p className="labelPriceInfo">
          For year: <b>{yearlyPrice.toFixed(2)} USD</b>
        </p>
      </div>
      {discountNum > 0 ? (
        <div className="mt-3 pt-3 border-top">
          <h5 className="mb-1">Price with discount:</h5>
          <p className="labelPriceInfo">
            For month: <b>{discountedMonthlyPrice.toFixed(2)} USD</b>
          </p>
          <p className="labelPriceInfo">
            For year: <b>{discountedYearlyPrice.toFixed(2)} USD</b>
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default PreviewGiftPrice
