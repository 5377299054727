import { toast } from 'react-toastify'
import { Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { SETTINGS_MESSAGE_TAG_PICKER } from '../../../../../utils/constants'
import { getThemeContent } from '../../../../../api/admin/themeContent'
import CustomDropdownPicker from '../../../../../components/CustomDropdownPicker'

const ControllerContent = ({ name, themeId, control, messageId }) => {
  const getContents = async () => {
    try {
      const { data } = await getThemeContent(themeId)
      const formattedData = data.map((item) => ({
        ...item,
        name: `${item.externalId} | ${item.subtitle}`,
        isOccupied: !isEmpty(item.messageIds) && !item?.messageIds.includes(messageId),
      }))
      return { data: formattedData }
    } catch (error) {
      console.error('Error fetching meditation content:', error)
      return { data: [] }
    }
  }

  const handleContentChange = ({ value, data, onChange }) => {
    const idLastItem = value[value.length - 1]
    const newElem = data.find(({ id }) => idLastItem === id)

    if (newElem?.isOccupied) {
      toast(`This content belongs to a message with the ids=${newElem.messageIds}`, {
        type: 'warning',
      })
    }

    onChange(value)
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: false }}
      defaultValue={[]}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <CustomDropdownPicker
          value={value}
          error={error}
          onChange={(value, data) => handleContentChange({ value, data, onChange })}
          {...SETTINGS_MESSAGE_TAG_PICKER}
          placeholder="Choose content that will be delivered"
          getData={getContents}
        />
      )}
    />
  )
}

export default ControllerContent
