import { useQuery } from '@tanstack/react-query'
import { reduce, startCase, toNumber } from 'lodash'
import { Table } from 'rsuite'
import { Link } from 'react-router-dom'
import { Cell, Legend, Pie, PieChart } from 'recharts'

import { CHART_WORK_WEEKEND_COLORS } from '../../utils/constants'
import { routes } from '../../router/routes'
import { getWeekStatistics, getContentAnalytic } from '../../api/content'
import { StarRatingCell, TextTooltipCell } from '../Table'

const ContentRating = ({ filterData }) => {
  const { data: weekContentStatistic } = useQuery({
    queryKey: ['weekContentStatistic', filterData],
    queryFn: () => getWeekStatistics(filterData),
    select: ({ data }) => {
      return reduce(data, (acc, value, key) => [...acc, { name: startCase(key), value }], [])
    },
  })
  const { data: contentStatistic, isLoading: isLoadingContentStatistic } = useQuery({
    queryKey: ['contentAnalytic', filterData],
    queryFn: () =>
      getContentAnalytic({
        size: 5,
        page: 1,
        orderBy: 'popularity_score',
        orderDirection: 'desc',
        ...filterData,
      }),
    select: ({ data }) => {
      const formattedData = data.data.map((item) => {
        const ratingList = reduce(
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, ...item.ratings },
          (acc, totalCount, starsCount) => [
            ...acc,
            { starsCount: toNumber(starsCount), totalCount },
          ],
          []
        )
        return { ...item, ratingList }
      })

      return { ...data, data: formattedData }
    },
  })

  return (
    <div className="mt-5 mb-5 d-flex align-items-start">
      <PieChart width={350} height={380}>
        <Pie
          data={weekContentStatistic}
          cx={175}
          cy={175}
          innerRadius={100}
          outerRadius={130}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label
        >
          {weekContentStatistic?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={CHART_WORK_WEEKEND_COLORS[index % CHART_WORK_WEEKEND_COLORS.length]}
            />
          ))}
        </Pie>
        <Legend />
      </PieChart>
      <div className="flex-grow-1">
        <p className="subTitleAdmin">Top 5 contents</p>
        <Table
          loading={isLoadingContentStatistic}
          autoHeight
          wordWrap="break-word"
          data={contentStatistic?.data}
          id="table-top-contents"
        >
          <Table.Column verticalAlign="middle" width={70} align="center">
            <Table.HeaderCell className="headColumn">ID</Table.HeaderCell>
            <TextTooltipCell
              dataKey="externalId"
              tooltipList={[{ label: 'Title', key: 'subtitle' }]}
            />
          </Table.Column>
          <Table.Column verticalAlign="middle" flexGrow={1} minWidth={160}>
            <Table.HeaderCell className="headColumn">VO Artist</Table.HeaderCell>
            <Table.Cell dataKey="voiceArtist" />
          </Table.Column>
          <Table.Column align="center" verticalAlign="middle" width={140}>
            <Table.HeaderCell className="headColumn">Avg. rating</Table.HeaderCell>
            <StarRatingCell dataKey="averageRating" ratingListKey="ratingList" />
          </Table.Column>
          <Table.Column align="center" verticalAlign="middle" width={70}>
            <Table.HeaderCell className="headColumn">Liked</Table.HeaderCell>
            <Table.Cell dataKey="totalLikes" />
          </Table.Column>
          <Table.Column align="center" verticalAlign="middle" width={70}>
            <Table.HeaderCell className="headColumn">Finished</Table.HeaderCell>
            <Table.Cell dataKey="contentActivities.finishedContent" />
          </Table.Column>
        </Table>
        <Link to={routes.adminContentStatistics} className="viewMorePaginateLink">
          View more
        </Link>
      </div>
    </div>
  )
}

export default ContentRating
