import { useState } from 'react'
import Linkify from 'react-linkify'

const ShowMoreText = ({ text, maxChars }) => {
  const [showMore, setShowMore] = useState(false)

  if (!text) {
    return null
  }

  const displayText = showMore ? text : text.slice(0, maxChars)
  const shouldShowToggle = text.length > maxChars

  const handleShowMoreClick = () => {
    setShowMore(!showMore)
  }

  return (
    <Linkify>
      {displayText}
      {shouldShowToggle && (
        <span className="showMoreBtn" onClick={handleShowMoreClick}>
          {!showMore ? '...Show more' : ' Show less'}
        </span>
      )}
    </Linkify>
  )
}

export default ShowMoreText
