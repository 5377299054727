import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { camelCase, isNil } from 'lodash'

import {
  SUBSCRIPTION_STATUSES_INFO,
  SUBSCRIPTION_STATUSES_ORDER,
} from '../../../../utils/constants'
import { getUserCountBySubscriptionStatus } from '../../../../api/dashboard'
import SubscriptionStatusBadge from '../../../../components/SubscriptionStatusBadge'

const SubscriptionList = ({ filterData }) => {
  const { data: totalUserBySubscriptionStatus } = useQuery({
    queryKey: ['totalUserSubscriptionInfo', filterData?.themeId],
    queryFn: () => getUserCountBySubscriptionStatus({ themeId: filterData?.themeId }),
    placeholderData: keepPreviousData,
  })
  const { data: totalUserBySubscriptionStatusAtPeriod } = useQuery({
    queryKey: ['totalUserSubscriptionInfo', filterData],
    queryFn: () => getUserCountBySubscriptionStatus(filterData),
    placeholderData: keepPreviousData,
    enabled: !isNil(filterData?.dateCollectionRange),
  })

  return (
    <div className="mt-4">
      <p className="subTitleAdmin">Subscription statuses</p>
      <div className="innerSubscriptionStatusBadges">
        {SUBSCRIPTION_STATUSES_ORDER.map((status) => (
          <SubscriptionStatusBadge
            key={status}
            status={status}
            description={SUBSCRIPTION_STATUSES_INFO[status].adminDescription}
            totalAtPeriod={totalUserBySubscriptionStatusAtPeriod?.data?.[camelCase(status)]}
            total={totalUserBySubscriptionStatus?.data?.[camelCase(status)]}
          />
        ))}
      </div>
    </div>
  )
}

export default SubscriptionList
