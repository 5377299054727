import { useEffect, useMemo, useRef, useState } from 'react'

import { SIZE_PAGINATION_DIALOG_MESSAGES } from '../constants'
import { getDialogMessages, getOldestDateMessage } from '../../api/dialog'

const useChat = ({ userId }) => {
  const [messages, setMessages] = useState([])
  const [oldestMessage, setOldestMessage] = useState({})
  const [loadingNewMessages, setLoadingNewMessages] = useState(true)
  const [loadingOldMessages, setLoadingOldMessages] = useState(true)
  const [indexUnreadMessage, setIndexUnreadMessage] = useState(0)

  const lastPageOldMessageRef = useRef(0)
  const lastPageNewMessagesRef = useRef(0)
  const currentPageOldMessagesRef = useRef(1)
  const currentPageNewMessagesRef = useRef(1)

  const calculateIndexUnreadMessage = (messages, oldestMessage) => {
    const oldestMessageIndex = messages.findIndex(
      ({ createdAt }) => createdAt === oldestMessage.createdAt
    )

    const calculatedIndex = oldestMessageIndex < 5 ? oldestMessageIndex : oldestMessageIndex - 5
    return calculatedIndex < 0 ? messages.length - 1 : calculatedIndex
  }

  const firstItemIndex = useMemo(
    () => SIZE_PAGINATION_DIALOG_MESSAGES * lastPageOldMessageRef.current - messages.length,
    [messages]
  )

  const handleAddAdminMessage = (message) => {
    if (
      currentPageNewMessagesRef.current !== lastPageNewMessagesRef.current &&
      lastPageNewMessagesRef.current >= 1
    ) {
      setMessages([])
      setOldestMessage({ createdAt: new Date() })
    } else {
      setMessages((oldState) => [...oldState, message])
    }
  }

  const handleAddUserMessage = (message) => {
    if (
      currentPageNewMessagesRef.current === lastPageNewMessagesRef.current ||
      lastPageNewMessagesRef.current < 1
    ) {
      setMessages((oldState) => [...oldState, message])
    }
  }

  const fetchDialogMessages = async (isRead, currentPage, setLoading, lastPageRef) => {
    setLoading(true)

    try {
      const { data } = await getDialogMessages({
        userId,
        size: SIZE_PAGINATION_DIALOG_MESSAGES,
        page: currentPage,
        created_at: oldestMessage.createdAt,
        is_read: isRead,
      })

      setMessages((oldState) =>
        isRead ? [...data.data.reverse(), ...oldState] : [...oldState, ...data.data]
      )

      lastPageRef.current = data.lastPage
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const setDefaultPaginationSettings = () => {
    setMessages([])
    lastPageNewMessagesRef.current = 0
    lastPageOldMessageRef.current = 0
    currentPageNewMessagesRef.current = 1
    currentPageOldMessagesRef.current = 1
    setLoadingOldMessages(true)
    setLoadingNewMessages(true)
  }

  useEffect(() => {
    setDefaultPaginationSettings()
    getOldestDateMessage(userId).then(({ data }) => setOldestMessage(data))
  }, [userId])

  useEffect(() => {
    setIndexUnreadMessage(calculateIndexUnreadMessage(messages, oldestMessage))
  }, [messages, oldestMessage])

  return {
    messages,
    changeInfoMessages: setMessages,
    oldestMessage,
    setLoadingNewMessages,
    setLoadingOldMessages,
    currentPageOldMessagesRef,
    currentPageNewMessagesRef,
    lastPageOldMessageRef,
    lastPageNewMessagesRef,
    isLoading: loadingNewMessages && loadingOldMessages,
    firstItemIndex,
    indexUnreadMessage,
    fetchDialogMessages,
    handleAddUserMessage,
    handleAddAdminMessage,
  }
}
export default useChat
