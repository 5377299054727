import './styles.css'

const PrimaryButton = ({ text = '', customClass = '', Icon = null, iconClass = '', ...rest }) => {
  return (
    <button className={`primaryButton ${customClass}`} {...rest}>
      {Icon && <Icon className={iconClass} size={20} style={{ fill: '#FFFFFF' }} />}
      {text}
    </button>
  )
}

export default PrimaryButton
