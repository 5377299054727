import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash/lang'

import { routes } from '../../../router/routes'
import { ROLES } from '../../constants'
import { FORM_LOGIN_USER, OTP_FIELD } from '../../constantsForms'
import { confirmLogin, loginUser } from '../../../api/auth'
import { useWebsocketContext } from '../../../components/SocketProvider'
import { updateToken } from '../../auth'
import useTimer from '../useTimer'
import useWebSocket from '../useWebSocket'
import { setUser } from '../../../redux/actions/user'
import { camelize } from '../../helpers'

import { handleErrorLogin } from './handleErrorLogin'
import { verificationUserByPhone, verificationUserByToken } from './verificationUser'

const useUserLogin = () => {
  const [showConfirm, setShowConfirm] = useState(false)
  const formRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { role = ROLES.ROLE_GUEST } = useSelector(({ user }) => ({ role: user.role }))
  const { seconds, timer, onChangeTimerTime } = useTimer()
  const { connect } = useWebSocket()
  const { handleWebSocketSubscription } = useWebsocketContext()

  const [searchParams] = useSearchParams()
  const formattedSearchParams = camelize(Object.fromEntries(searchParams))

  const redirectUser = (redirectRoute) => {
    try {
      const urlObject = new URL(formattedSearchParams?.redirectUrl)
      const { pathname, search, hash } = urlObject
      const redirectRoute = pathname + search + hash
      navigate(redirectRoute, { replace: true })
    } catch (e) {
      navigate(redirectRoute)
    }
  }

  const handleSuccessSendConfirmCode = () => {
    onChangeTimerTime(60)
    setShowConfirm(true)
    formRef.current.update(0, { ...FORM_LOGIN_USER[0], disabled: true })
    formRef.current.update(1, {
      ...OTP_FIELD,
      onSuccess: () => formRef.current?.handleSubmit(handleConfirmLogin)(),
    })
    return 'Verification code was sent'
  }

  const handleRequestWithToast = {
    success: {
      render({ data }) {
        return handleSuccessSendConfirmCode(data?.data)
      },
    },
    pending: 'Sending code...',
    error: {
      render({ data }) {
        return handleErrorLogin(data?.response)
      },
    },
  }

  const handleSendConfirmCode = (data) => {
    loginUser(data, handleRequestWithToast).catch(() => {
      formRef.current?.setError('phone', {})
    })
  }

  const handleConfirmLogin = async (data) => {
    return await confirmLogin(data)
      .then(({ data: { token, user } }) => {
        dispatch(setUser(user))
        updateToken(token)
      })
      .then(connect)
      .then(handleWebSocketSubscription)
      .then(() => redirectUser(routes.userHome))
      .catch(() => formRef.current?.setError('code', {}))
  }

  const handleLogin = (data) => {
    if (!showConfirm) {
      return handleSendConfirmCode(data)
    }
    return handleConfirmLogin(data)
  }

  useEffect(() => {
    if (role === ROLES.ROLE_USER && !isNil(formattedSearchParams?.redirectUrl)) {
      return redirectUser()
    }

    verificationUserByToken({
      dispatch,
      navigate,
      ...formattedSearchParams,
    })

    verificationUserByPhone({
      onSuccess: (decodedPhone) => {
        formRef.current?.setValue('phone', decodedPhone)
        formRef?.current?.handleSubmit(handleLogin)()
      },
      ...formattedSearchParams,
    })
  }, [])

  return {
    formRef,
    handleLogin,
    timerTime: timer,
    timeSeconds: seconds,
    handleSendConfirmCode,
    isConfirmStep: showConfirm,
  }
}

export default useUserLogin
