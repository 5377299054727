import { useEffect, useReducer, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Virtuoso } from 'react-virtuoso'

import { routes } from '../../../router/routes'
import {
  SENT_BY,
  WEBSOCKET_CONNECTION_CHANNEL,
  WEBSOCKET_EVENTS,
  WEBSOCKET_STATES,
} from '../../../utils/constants'
import { FORM_FIELD_CHAT } from '../../../utils/constantsForms'
import { sendAdminMessage } from '../../../api/dialog'
import useChat from '../../../utils/hooks/useChat'
import useWebSocket from '../../../utils/hooks/useWebSocket'
import { Messenger } from '../../SvgIcon'
import ChatMessage from '../../ChatMessage'
import HookForm from '../../HookForm'

const Chat = ({ onHide, isBlocked }) => {
  const inputRef = useRef(null)
  const messagesListRef = useRef(null)
  const subscriptionChatRef = useRef(null)

  const navigate = useNavigate()
  const { userId } = useParams()
  const [, toggleRender] = useReducer((p) => !p, false)

  const { subscribeAdminChat } = useWebSocket()

  const {
    messages,
    isLoading,
    oldestMessage,
    setLoadingNewMessages,
    setLoadingOldMessages,

    lastPageOldMessageRef,
    lastPageNewMessagesRef,
    currentPageOldMessagesRef,
    currentPageNewMessagesRef,

    firstItemIndex,
    indexUnreadMessage,
    fetchDialogMessages,
    handleAddUserMessage,
    handleAddAdminMessage,
  } = useChat({ userId })

  const formRef = useRef(null)

  const onSendMessage = (data) => {
    inputRef.current.disabled = true

    sendAdminMessage({ users_ids: [userId], ...data })
      .then(() => {
        formRef.current.resetField(FORM_FIELD_CHAT.name, { defaultValue: '' })
      })
      .then(() => {
        if (
          lastPageNewMessagesRef.current === currentPageNewMessagesRef.current ||
          lastPageNewMessagesRef.current < 1
        ) {
          messagesListRef.current.scrollToIndex({
            index: messages.length - 1,
          })
        }
      })
      .finally(() => {
        inputRef.current.disabled = false
      })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      formRef.current.handleSubmit(onSendMessage)()
    }
  }

  const handleMessageAdd = (message) => {
    if (message.sentBy === SENT_BY.ADMIN) {
      return handleAddAdminMessage(message)
    }

    return handleAddUserMessage(message)
  }

  const subscribeToAdminChat = async () => {
    subscriptionChatRef.current = await subscribeAdminChat({
      channel: `${WEBSOCKET_CONNECTION_CHANNEL.ADMIN_CHAT}:${userId}`,
      handlerReceivedMessage: handleMessageAdd,
      onReady: () => (inputRef.current.disabled = isBlocked),
      handlerChangeTwilioStatus: (twilioStatus) => (inputRef.current.disabled = twilioStatus),
    })
  }

  const onReadMessage = (externalMessageId) => {
    subscriptionChatRef.current?.emit?.(WEBSOCKET_EVENTS.READ_MESSAGE, { externalMessageId })
  }

  useEffect(() => {
    if (!isEmpty(oldestMessage)) {
      fetchDialogMessages(
        true,
        currentPageOldMessagesRef.current,
        setLoadingOldMessages,
        lastPageOldMessageRef
      )
    }
  }, [currentPageOldMessagesRef.current, oldestMessage])

  useEffect(() => {
    if (!isEmpty(oldestMessage)) {
      fetchDialogMessages(
        false,
        currentPageNewMessagesRef.current,
        setLoadingNewMessages,
        lastPageNewMessagesRef
      )
    }
  }, [currentPageNewMessagesRef.current, oldestMessage])

  useEffect(() => {
    subscribeToAdminChat()
    return () => {
      if (subscriptionChatRef.current?.state === WEBSOCKET_STATES.OPEN) {
        subscriptionChatRef.current?.close()
      }
    }
  }, [userId])

  return (
    <div className="innerChatWindow">
      <header className="chatHeader">
        <Messenger
          fill="#FFFFFF"
          onClick={() => navigate(routes.messaging, { state: { selectedUser: userId } })}
          title="Go to chat"
          className="iconChat"
          size={25}
        />
        <p className="titleChatForm">Chat</p>
        <span onClick={onHide} className="btnCloseChat" />
      </header>
      <div className="listChatMessages">
        {!isLoading && (
          <Virtuoso
            data={messages}
            ref={messagesListRef}
            initialTopMostItemIndex={indexUnreadMessage}
            firstItemIndex={Math.max(0, firstItemIndex)}
            alignToBottom
            atTopThreshold={100}
            atBottomThreshold={100}
            itemContent={(_, rowData) => <ChatMessage {...rowData} onRead={onReadMessage} />}
            startReached={() => {
              if (lastPageOldMessageRef.current > currentPageOldMessagesRef.current) {
                currentPageOldMessagesRef.current += 1
                toggleRender()
              }
            }}
            endReached={() => {
              if (lastPageNewMessagesRef.current > currentPageNewMessagesRef.current) {
                currentPageNewMessagesRef.current += 1
                toggleRender()
              }
            }}
          />
        )}
      </div>
      <HookForm
        ref={formRef}
        className="chatForm"
        onSubmit={onSendMessage}
        fields={[{ ...FORM_FIELD_CHAT, onKeyDown: handleKeyDown, inputRef, disabled: true }]}
      />
    </div>
  )
}

export default Chat
