import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import userReducer from './reducers/user'
import uiReducer from './reducers/ui'
import referencesReducer from './reducers/references'

export const createStore = (user, references) => {
  const reducers = {
    ui: uiReducer(),
    user: userReducer(user),
    references: referencesReducer(references),
  }

  const rootReducer = combineReducers(reducers)
  return configureStore({ reducer: rootReducer })
}

export default createStore
