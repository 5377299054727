import ReactPaginate from 'react-paginate'

import { SETTINGS_PAGINATION } from '../../utils/constants'
import './styles.css'

const Pagination = ({
  total,
  show,
  lastPage,
  forcePage,
  onPageChange,
  innerClassName = '',
  ...props
}) => {
  if (!show) {
    return null
  }

  return (
    <div className={`d-flex align-items-center justify-content-between mt-3 ${innerClassName}`}>
      <p className="totalResult">{total} Result</p>
      <ReactPaginate
        {...SETTINGS_PAGINATION}
        pageCount={lastPage}
        marginPagesDisplayed={-1}
        pageRangeDisplayed={-1}
        forcePage={forcePage}
        containerClassName="d-flex align-items-stretch gap-2"
        onPageChange={onPageChange}
        {...props}
      />
    </div>
  )
}

export default Pagination
