import { useEffect, useRef } from 'react'
import { isString } from 'lodash'
import { Modal } from 'react-bootstrap'

import { VCF_FIELDS } from '../../../utils/constantsForms'
import { createVcfContact, getVcfContact } from '../../../api/admin/themes'
import { convertToFile, formatPhoneNumber } from '../../../utils/helpers'
import PrimaryButton from '../../PrimaryButton'
import HookForm from '../../HookForm'

import './styles.css'

const ModalContact = ({ show, onHide, themeId }) => {
  const formRef = useRef(null)

  const handleSubmit = (data) => {
    const image = isString(data.image) ? convertToFile(data.image, 'image.jpg') : data.image

    createVcfContact(themeId, { ...data, image })
      .then(() => formRef.current.reset(data))
      .then(onHide)
      .catch((error) => {
        const dataError = error?.response?.data
        Object.keys(dataError.data).map((field) => formRef.current?.setError(field, {}))
      })
  }

  const Footer = ({ formState }) => {
    return (
      <PrimaryButton
        text="Save Contact"
        disabled={!formState.isValid || !formState.isDirty}
        customClass="buttonSaveVcf"
      />
    )
  }

  useEffect(() => {
    if (show) {
      getVcfContact(themeId).then(({ data }) => {
        const fieldPhone = data?.phone ?? ''
        const formattedPhone = formatPhoneNumber(fieldPhone)

        formRef.current?.reset({
          ...data,
          phone: formattedPhone,
          image: `data:image/jpg;base64,${data.image}`,
        })
      })
    }
  }, [show])

  return (
    <Modal dialogClassName="dialogModalContact" centered show={show} onHide={onHide}>
      <HookForm
        onSubmit={handleSubmit}
        ref={formRef}
        className="formVcf"
        fields={VCF_FIELDS}
        Footer={Footer}
      />
    </Modal>
  )
}

export default ModalContact
