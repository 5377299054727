import { connect } from 'react-redux'

import Page404 from '../pages/Page404'

const RenderComponent = ({ Component, roles, userData, userRole, hasAccess }) => {
  const userHasPermission = roles.includes(userRole) && (hasAccess?.({ userData }) ?? true)

  return userHasPermission ? <Component /> : <Page404 />
}

export default connect(({ user }) => ({ userData: user.data, userRole: user.role }))(
  RenderComponent
)
