import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { Modal } from 'react-bootstrap'
import {
  CANCEL_DESCRIPTION_USER_TEXT,
  DATE_FORMAT_MDY,
  ROLES,
  SUBSCRIPTION_API_ACTIONS_INFO,
} from '../../../utils/constants'
import { FIELD_CANCEL_ACCOUNT } from '../../../utils/constantsForms'
import { manageThemeSubscription as manageThemeSubscriptionUser } from '../../../api/user'
import { manageThemeSubscription as manageThemeSubscriptionAdmin } from '../../../api/admin/user'
import { Cross, Bin } from '../../SvgIcon'
import PrimaryButton from '../../PrimaryButton'

import HookForm from '../../HookForm'
import './styles.css'

const ModalCancelSubscription = ({
  show = true,
  onHide,
  userRole,
  descriptionTexts = CANCEL_DESCRIPTION_USER_TEXT,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const themeId = searchParams.get('themeId')
  const themeName = searchParams.get('themeName')
  const subscriptionStatus = searchParams.get('status')
  const action = searchParams.get('action')
  const userId = searchParams.get('userId')

  const DescriptionTextComponent = useMemo(
    () => descriptionTexts[subscriptionStatus] || descriptionTexts.DEFAULT,
    [subscriptionStatus]
  )

  const cancelSubscription = useCallback(() => {
    const routeManageSubscription =
      userRole === ROLES.ROLE_ADMIN ? manageThemeSubscriptionAdmin : manageThemeSubscriptionUser

    return routeManageSubscription(
      { themeId, userId, action },
      { success: SUBSCRIPTION_API_ACTIONS_INFO[action].successMessages(themeName) }
    ).then(() => {
      queryClient.invalidateQueries({ queryKey: ['userSubscriptions'] })
      queryClient.invalidateQueries({ queryKey: ['themeDetails', themeId, userId] })
      onHide()
    })
  }, [themeId, action])

  return (
    <Modal
      size="md"
      centered
      show={show}
      onHide={onHide}
      onExited={() => setSearchParams({})}
      dialogClassName="modalDialogCancelSubscription"
      contentClassName="modalContentCancelSubscription"
    >
      <div className="d-flex justify-content-between align-items-center gap-2 w-100 border-bottom pb-3">
        <div className="d-flex align-items-center gap-2">
          <Bin className="iconBin" size={32} />
          <h2 className="titleModalConfirmCancelSubscription mw-100">Cancel subscription</h2>
        </div>
        <Cross size={23} onClick={onHide} className="cursorPointer" />
      </div>
      <div className="d-flex flex-column align-items-center w-100 mt-3">
        <h3 className="text-center mb-1">Are you sure?</h3>
        <p className="text-center mb-2">
          <DescriptionTextComponent
            cancelAt={moment(searchParams.get('cancelAt')).format(DATE_FORMAT_MDY)}
            action={action}
          />
        </p>
        <HookForm
          className="w-100"
          onSubmit={cancelSubscription}
          fields={FIELD_CANCEL_ACCOUNT}
          Footer={() => <PrimaryButton customClass="mt-4 mw-100 w-100" text="Cancel Now" />}
        />
      </div>
    </Modal>
  )
}

export default connect(({ user }) => ({ userRole: user.role }))(ModalCancelSubscription)
