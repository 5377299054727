import React, { useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { omitBy, isEmpty } from 'lodash'
import { useQuery } from '@tanstack/react-query'

import { FORM_CHANGE_USER_DATA } from '../../../../utils/constantsForms'
import { getUserInterests, setNewOffset } from '../../../../api/user'
import { setNewDataUser } from '../../../../redux/actions/user'
import HookForm from '../../../../components/HookForm'
import PrimaryButton from '../../../../components/PrimaryButton'

const UserInformationForm = ({ userData }) => {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const { data: { data: interestsData } = {}, isSuccess } = useQuery({
    queryKey: ['userInterests'],
    queryFn: getUserInterests,
  })

  const handleSubmitChangeUserInfo = (data) => {
    const { timezoneOffset, name } = data?.timezone ?? {}
    const filteredData = omitBy(
      {
        ...data,
        timezoneOffset,
        timezoneName: name,
        gender: data?.gender?.title,
        familyStatus: data?.familyStatus?.title,
      },
      (value) => isEmpty(value)
    )

    setNewOffset(filteredData, { success: 'User info saved' }).then(() => {
      dispatch(setNewDataUser(filteredData))
    })
  }

  const renderFooterFormUserInfo = ({ formState }) => {
    const isDisabled = isEmpty(formState?.dirtyFields)

    return (
      <PrimaryButton
        text="Save"
        disabled={isDisabled}
        customClass="submitButtonUserInfo itemFormUserInfo"
      />
    )
  }

  useEffect(() => {
    if (isSuccess) {
      formRef.current?.reset({
        ...userData,
        interests: interestsData?.map(({ id }) => id),
        timezone: {
          timezoneOffset: userData?.timezoneOffset,
          timezoneName: userData?.timezoneName,
        },
      })
    }
  }, [isSuccess, interestsData, userData])

  return (
    <>
      <HookForm
        className="formUserUpdate"
        ref={formRef}
        fields={FORM_CHANGE_USER_DATA}
        Footer={renderFooterFormUserInfo}
        onSubmit={handleSubmitChangeUserInfo}
        initialValues={{
          ...userData,
          interests: interestsData?.map(({ id }) => id),
          timezone: {
            timezoneOffset: userData?.timezoneOffset,
            timezoneName: userData?.timezoneName,
          },
        }}
      />
    </>
  )
}

export default connect(({ user }) => ({ userData: user.data }))(UserInformationForm)
