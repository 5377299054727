import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { Popover, Whisper } from 'rsuite'

import { getUserNote } from '../../../../api/admin/user'
import { Info } from '../../../../components/SvgIcon'
import ModalUserNotes from '../../../../components/Modals/ModalUserNotes'
import styles from '../User.module.css'

const NotesPopover = ({ userName }) => {
  const [showModalUserNote, setShowModalUserNote] = useState(false)
  const { userId } = useParams()

  const { data: userNotes } = useQuery({
    queryKey: ['userNotes', userId],
    queryFn: () => getUserNote(userId),
    select: ({ data }) => data,
  })

  return (
    <>
      <Whisper
        trigger="hover"
        placement="bottomStart"
        controlId="user-notes"
        enterable
        defaultOpen={!isEmpty(userNotes)}
        speaker={
          <Popover title="Note" className={styles.userNotesPopover}>
            <>
              {isEmpty(userNotes) ? (
                <span
                  onClick={() => setShowModalUserNote(true)}
                  className="text-decoration-underline cursorPointer d-block mt-1 text-center"
                >
                  Add user notes
                </span>
              ) : (
                <p className={styles.textNotes}>
                  {userNotes}
                  <span
                    onClick={() => setShowModalUserNote(true)}
                    className="text-decoration-underline cursorPointer d-block mt-1 text-end"
                  >
                    Edit
                  </span>
                </p>
              )}
            </>
          </Popover>
        }
      >
        <div className="d-flex align-items-center">
          <Info fill="var(--catalina-blue)" size={25} />
        </div>
      </Whisper>
      <ModalUserNotes
        title={`Notes regarding ${userName}`}
        userNotesValue={userNotes}
        show={showModalUserNote}
        onHide={() => setShowModalUserNote(false)}
      />
    </>
  )
}

export default NotesPopover
