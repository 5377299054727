const Gift = ({ size, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 76V40C5.8 40 3.91667 39.2167 2.35 37.65C0.783333 36.0833 0 34.2 0 32V24C0 21.8 0.783333 19.9167 2.35 18.35C3.91667 16.7833 5.8 16 8 16H20.8C20.4667 15.4 20.25 14.7667 20.15 14.1C20.05 13.4333 20 12.7333 20 12C20 8.66667 21.1667 5.83333 23.5 3.5C25.8333 1.16667 28.6667 0 32 0C33.5333 0 34.9667 0.283333 36.3 0.85C37.6333 1.41667 38.8667 2.2 40 3.2C41.1333 2.13333 42.3667 1.33333 43.7 0.8C45.0333 0.266667 46.4667 0 48 0C51.3333 0 54.1667 1.16667 56.5 3.5C58.8333 5.83333 60 8.66667 60 12C60 12.7333 59.9333 13.4167 59.8 14.05C59.6667 14.6833 59.4667 15.3333 59.2 16H72C74.2 16 76.0833 16.7833 77.65 18.35C79.2167 19.9167 80 21.8 80 24V32C80 34.2 79.2167 36.0833 77.65 37.65C76.0833 39.2167 74.2 40 72 40V76C72 78.2 71.2167 80.0833 69.65 81.65C68.0833 83.2167 66.2 84 64 84H16C13.8 84 11.9167 83.2167 10.35 81.65C8.78333 80.0833 8 78.2 8 76ZM48 8C46.8667 8 45.9167 8.38333 45.15 9.15C44.3833 9.91667 44 10.8667 44 12C44 13.1333 44.3833 14.0833 45.15 14.85C45.9167 15.6167 46.8667 16 48 16C49.1333 16 50.0833 15.6167 50.85 14.85C51.6167 14.0833 52 13.1333 52 12C52 10.8667 51.6167 9.91667 50.85 9.15C50.0833 8.38333 49.1333 8 48 8ZM28 12C28 13.1333 28.3833 14.0833 29.15 14.85C29.9167 15.6167 30.8667 16 32 16C33.1333 16 34.0833 15.6167 34.85 14.85C35.6167 14.0833 36 13.1333 36 12C36 10.8667 35.6167 9.91667 34.85 9.15C34.0833 8.38333 33.1333 8 32 8C30.8667 8 29.9167 8.38333 29.15 9.15C28.3833 9.91667 28 10.8667 28 12ZM8 24V32H36V24H8ZM36 76V40H16V76H36ZM44 76H64V40H44V76ZM72 32V24H44V32H72Z" />
    </svg>
  )
}

export default Gift
