const Contact = ({ size, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M68 0H12C9.8 0 8 1.8 8 4C8 6.2 9.8 8 12 8H68C70.2 8 72 6.2 72 4C72 1.8 70.2 0 68 0ZM12 96H68C70.2 96 72 94.2 72 92C72 89.8 70.2 88 68 88H12C9.8 88 8 89.8 8 92C8 94.2 9.8 96 12 96ZM72 16H8C3.6 16 0 19.6 0 24V72C0 76.4 3.6 80 8 80H72C76.4 80 80 76.4 80 72V24C80 19.6 76.4 16 72 16ZM40 27C44.96 27 49 31.04 49 36C49 40.96 44.96 45 40 45C35.04 45 31 40.96 31 36C31 31.04 35.04 27 40 27ZM60 68H20V62C20 55.32 33.32 52 40 52C46.68 52 60 55.32 60 62V68Z" />
    </svg>
  )
}

export default Contact
