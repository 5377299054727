import { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { camelCase, debounce, isEmpty, omitBy, snakeCase, uniq, xor } from 'lodash'
import ReactPaginate from 'react-paginate'
import { Table } from 'rsuite'
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment-timezone'

import { DATE_FORMAT_MDY, SETTINGS_PAGINATION, SIZE_PAGINATE_USERS } from '../../../utils/constants'
import { downloadExcelUsersTable, getAllUsers } from '../../../api/admin/user'
import { sendAdminMultipleMessage } from '../../../api/dialog'
import { isNullOrEmpty } from '../../../utils/helpers'
import { Cross, IconFilter, Messenger } from '../../../components/SvgIcon'
import Button from '../../../components/Button'
import ModalGroupMessageSender from '../../../components/Modals/ModalGroupMessageSender'
import CustomInput from '../../../components/CustomInput'
import ModalFilter from '../../../components/Modals/ModalFilter'
import IconButton from '../../../components/IconButton'
import UtmParams from '../../../components/UtmParams'
import CustomCheckbox from '../../../components/CustomCheckbox'
import NameCell from './components/NameCell'
import CheckBoxCell from './components/CheckBoxCell'

import './styles.css'

const Users = () => {
  const searchInputRef = useRef(null)
  const navigation = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const formattedSearchParams = JSON.parse(searchParams?.get('filterData')) ?? {}

  const [showModalFilter, setShowModalFilter] = useState(false)
  const [showModalGroupMessageSender, setShowModalGroupMessageSender] = useState(false)

  const [filterData, setFilterData] = useState(formattedSearchParams)
  const [orderInfo, setOrderInfo] = useState({})
  const [selectedPage, setSelectedPage] = useState(formattedSearchParams?.selectedPage ?? 1)
  const [selectedUsers, setSelectedUsers] = useState([])

  const appliedFilterCount = useMemo(
    () => Object.keys(omitBy(filterData, isEmpty)).length,
    [filterData]
  )

  const handleUpdateSelectedPage = useCallback(
    (page) => {
      const params = new URLSearchParams()
      params.append('selectedPage', page)
      setSearchParams({ filterData: JSON.stringify(filterData), selectedPage: page }, {})
      setSelectedPage(page)
    },
    [filterData]
  )

  const handleUpdateFilter = useCallback((data) => {
    setSelectedPage(1)
    setShowModalFilter(false)
    setFilterData((oldState) => {
      const newFilterData = { ...oldState, ...data }
      const filteredData = omitBy(newFilterData, isNullOrEmpty)

      setSearchParams({ filterData: JSON.stringify(filteredData), selectedPage: 1 })

      return filteredData
    })
  }, [])
  const onSearch = useCallback(
    debounce((e) => handleUpdateFilter({ search: e?.target?.value }), 500),
    []
  )
  const resetFilter = useCallback(() => {
    handleUpdateSelectedPage(1)
    setSearchParams({})
    setFilterData({ finishedContentRange: [], averageCompletionRange: [] })
    setOrderInfo({})
    searchInputRef.current.value = ''
    setShowModalFilter(false)
  }, [])

  const handleSelectUser = useCallback(
    (userIds, allSelected) => {
      setSelectedUsers((prevSelectedUsers) => {
        if (userIds.length > 1 && !allSelected) return uniq([...userIds, ...prevSelectedUsers])
        return xor(userIds, prevSelectedUsers)
      })
    },
    [selectedUsers]
  )

  const handleOrderColumn = useCallback((sortColumn, sortType) => {
    setOrderInfo({ orderBy: snakeCase(sortColumn), orderDirection: sortType })
  }, [])

  const {
    data: users,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['allUsers', filterData, orderInfo, selectedPage],
    queryFn: () =>
      getAllUsers({
        ...filterData,
        ...orderInfo,
        page: selectedPage,
        size: SIZE_PAGINATE_USERS,
      }),
    placeholderData: keepPreviousData,
    select: ({ data }) => {
      const formattedData = data.data.map((userInfo) => {
        const { firstName, lastName, createdAt, endSubscription } = userInfo
        const name = [firstName, lastName].join(' ')
        const formattedSubscriptionDate = moment.utc(createdAt).format(DATE_FORMAT_MDY)
        const averageCompletion =
          parseFloat(userInfo?.averageCompletion) > 0
            ? `${parseFloat(userInfo?.averageCompletion)}%`
            : '-'

        return {
          ...userInfo,
          name,
          averageCompletion,
          createdAt: formattedSubscriptionDate,
          endSubscription: moment.utc(endSubscription).format(DATE_FORMAT_MDY),
        }
      })

      return { ...data, data: formattedData }
    },
  })
  const mutationDownloadFile = useMutation({
    mutationKey: ['fileAllUsers'],
    mutationFn: () => downloadExcelUsersTable(filterData),
  })
  const allUsersSelected = useMemo(
    () =>
      users?.data?.every(
        ({ id, canSendMessage }) => selectedUsers.includes(id) || !canSendMessage
      ) && !isEmpty(users?.data),
    [users, selectedUsers]
  )

  return (
    <div>
      <div className="containerAdminMainInfo">
        <div className="d-flex align-items-center gap-3 justify-content-between">
          <h1 className="titleAdminMainInfo">Users</h1>
          <div className="d-flex align-items-center gap-3">
            {!isEmpty(users?.data) && (
              <Button
                customClass="sendMultipleMessage"
                onClick={() => {
                  mutationDownloadFile.mutate()
                }}
                disable={mutationDownloadFile.isPending}
              >
                {!mutationDownloadFile.isPending ? 'Export' : 'Loading...'}
              </Button>
            )}
            {!isEmpty(selectedUsers) && (
              <Button
                customClass="sendMultipleMessage"
                onClick={() => {
                  setShowModalGroupMessageSender(true)
                }}
                disable={isEmpty(selectedUsers)}
              >
                Send <Messenger size={15} fill="var(--catalina-blue)" />
              </Button>
            )}
          </div>
        </div>
        <div className="position-relative mb-4">
          <div className="innerDropDownFilter">
            <CustomInput
              inputRef={searchInputRef}
              defaultValue={filterData.search}
              onChange={onSearch}
              placeholder="Search by name, email, phone"
              innerClassName="innerUserSearchInput"
            />
            <div className="d-flex align-items-center gap-3">
              <IconButton
                buttonClassName="p-0 bg-transparent rounded-0 position-relative overflow-visible"
                renderTooltipContent={() => <p>Show Filter</p>}
                placement="bottomEnd"
                onClick={() => setShowModalFilter(true)}
              >
                <IconFilter size={20} />
                {appliedFilterCount > 0 && (
                  <span className="appliedFilterCount">{appliedFilterCount}</span>
                )}
              </IconButton>
              {appliedFilterCount > 0 || !isEmpty(orderInfo) ? (
                <IconButton
                  buttonClassName="p-0 bg-transparent rounded-0 position-relative"
                  renderTooltipContent={() => <p>Reset filter</p>}
                  placement="bottomEnd"
                  onClick={resetFilter}
                >
                  <Cross size={25} fill="var(--persian-red)" />
                </IconButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Table
        loading={isLoading || isFetching}
        data={users?.data}
        shouldUpdateScroll={false}
        autoHeight
        rowClassName="cursorPointer"
        className="containerAdminMainInfo"
        onSortColumn={handleOrderColumn}
        sortColumn={camelCase(orderInfo.orderBy)}
        sortType={orderInfo.orderDirection}
        onRowClick={({ id }) => navigation(id.toString())}
        id="table-users"
      >
        <Table.Column width={50} align="center">
          <Table.HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: '40px' }}>
              <CustomCheckbox
                className="me-0"
                inline
                value={allUsersSelected}
                onChange={() => {
                  const canSendMessageUserIds = users.data.reduce(
                    (acc, { id, canSendMessage }) => (canSendMessage ? [...acc, id] : acc),
                    []
                  )
                  handleSelectUser(canSendMessageUserIds, allUsersSelected)
                }}
              />
            </div>
          </Table.HeaderCell>
          <Table.Cell style={{ padding: 0 }}>
            {({ id, canSendMessage }) => (
              <CheckBoxCell
                isBlocked={!canSendMessage}
                onChange={() => handleSelectUser([id])}
                value={selectedUsers.includes(id)}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column sortable verticalAlign="middle" flexGrow={2} minWidth={170}>
          <Table.HeaderCell dataKey="name" className="headColumn">
            Name
          </Table.HeaderCell>
          <NameCell dataKey="name" />
        </Table.Column>
        <Table.Column verticalAlign="middle" align="center" width={170}>
          <Table.HeaderCell className="headColumn">Utm Source</Table.HeaderCell>
          <Table.Cell dataKey="utm">
            {({ utmMedium, utmSource, utmCampaign, utmTerm, utmContent }) => (
              <UtmParams
                utmParams={{ utmMedium, utmSource, utmCampaign, utmTerm, utmContent }}
                showIconFilterUtmParams={true}
                onClickIconFilter={(utmParam) =>
                  handleUpdateFilter({ utm: Object.values(utmParam) })
                }
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" align="center" width={170} sortable>
          <Table.HeaderCell dataKey="subscriptions" className="headColumn">
            Delivered contents
          </Table.HeaderCell>
          <Table.Cell dataKey="deliveredContent" />
        </Table.Column>
        <Table.Column verticalAlign="middle" align="center" width={170} sortable>
          <Table.HeaderCell dataKey="subscriptions" className="headColumn">
            Finish contents
          </Table.HeaderCell>
          <Table.Cell dataKey="finishedContent" />
        </Table.Column>
        <Table.Column verticalAlign="middle" align="center" width={170} sortable>
          <Table.HeaderCell dataKey="subscriptions" className="headColumn">
            Avg. completion
          </Table.HeaderCell>
          <Table.Cell dataKey="averageCompletion" />
        </Table.Column>
        <Table.Column sortable verticalAlign="middle" align="center" width={120}>
          <Table.HeaderCell dataKey="createdAt" className="headColumn">
            Joined at
          </Table.HeaderCell>
          <Table.Cell dataKey="createdAt" />
        </Table.Column>
        <Table.Column sortable verticalAlign="middle" align="center" width={140}>
          <Table.HeaderCell dataKey="endSubscription" className="headColumn">
            Expires at
          </Table.HeaderCell>
          <Table.Cell dataKey="endSubscription" />
        </Table.Column>
      </Table>
      <div className="d-flex align-items-center justify-content-between mt-3 containerAdminMainInfo">
        <p className="totalResult">{users?.total} Result</p>
        <ReactPaginate
          {...SETTINGS_PAGINATION}
          pageCount={users?.lastPage}
          marginPagesDisplayed={-1}
          pageRangeDisplayed={-1}
          forcePage={selectedPage - 1}
          containerClassName="d-flex align-items-stretch gap-2"
          onPageChange={({ selected }) => handleUpdateSelectedPage(selected + 1)}
        />
      </div>
      <ModalGroupMessageSender
        show={showModalGroupMessageSender}
        onHide={() => setShowModalGroupMessageSender(false)}
        onSend={(data) => sendAdminMultipleMessage({ usersIds: selectedUsers, ...data })}
      />
      <ModalFilter
        show={showModalFilter}
        onReset={resetFilter}
        defaultValues={filterData}
        onHide={() => setShowModalFilter(false)}
        onSubmit={handleUpdateFilter}
      />
    </div>
  )
}

export default Users
