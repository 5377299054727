import { URL_PHONE_PATTERN } from '../../constants'
import { setLoadingApp } from '../../../redux/actions/ui'
import { updateToken } from '../../auth'
import { getMe } from '../../../api/auth'
import { setUser } from '../../../redux/actions/user'
import { routes } from '../../../router/routes'

export const verificationUserByPhone = ({ onSuccess = () => {}, phone }) => {
  const phoneFormatted = `+${phone?.replace(/^\+?/, '').trim()}`

  if (URL_PHONE_PATTERN.test(phoneFormatted)) {
    onSuccess(phoneFormatted)
  }
}

export const verificationUserByToken = ({ navigate, dispatch, token }) => {
  if (token) {
    dispatch(setLoadingApp(true))
    updateToken(token)
    return getMe()
      .then(({ data }) => dispatch(setUser(data)))
      .then(() => navigate(routes.userHome))
      .finally(() => dispatch(setLoadingApp(false)))
  }
}
