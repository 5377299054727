import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment-timezone'
import { reduce } from 'lodash'

import { MONTH_YEAR_FORMAT, THEMES_COLORS } from '../../utils/constants'
import { getAdminCalendar } from '../../api/admin/themeCalendar'
import { getUserCalendarPlan } from '../../api/user'
import useTime from '../../utils/hooks/useTime'
import { ArrowLeft, ArrowRight, LoadingIcon } from '../SvgIcon'
import TableCalendar from './components/TableCalendar'
import ListMessages from './components/ListMessages'

import './styles.css'

const Calendar = ({
  userId = null,
  themeId = null,
  isAdmin = true,
  enableGetData = true,
  defaultMonthSwitch,
  innerClassName = '',
}) => {
  const [selectedDate, setSelectedDate] = useState({})
  const [showListMessages, setShowListMessages] = useState(false)
  const [currentMonth, setCurrentMonth] = useState({ date: moment() })
  const { getMessageTime } = useTime()

  const getInfoCalendarPlan = isAdmin ? getAdminCalendar : getUserCalendarPlan

  const { data: { data: calendarData } = {}, isLoading } = useQuery({
    queryKey: ['calendar', userId, themeId],
    queryFn: () => getInfoCalendarPlan({ userId, themeId }),
    enabled: enableGetData,
  })

  function transformMessagesInfo(inputObject) {
    const themeName = inputObject.relative?.[0]?.themeName
    const themeId = inputObject.relative?.[0]?.themeId

    return {
      themeId,
      themeName,
      color: THEMES_COLORS[themeId % THEMES_COLORS.length],
      ...inputObject,
    }
  }

  const formattedCalendarData = reduce(
    calendarData,
    (res, value) => [...res, transformMessagesInfo(value)],
    []
  )
  const allMessages = formattedCalendarData
    .flatMap((theme) => {
      const { skippedMeditations = [], relative = [], deliveredMessages = [] } = theme
      return [...skippedMeditations, ...relative, ...deliveredMessages]
    })
    .sort((a, b) => {
      const dateA = getMessageTime(a)
      const dateB = getMessageTime(b)
      return dateA - dateB
    })

  const nextMonth = useMemo(
    () => ({
      date: moment(currentMonth.date).add(1, 'M'),
    }),
    [currentMonth]
  )

  const onChangeVisibleDates = (method) => {
    const numberMonthSwitch = defaultMonthSwitch || window?.innerWidth > 991 ? 2 : 1

    setCurrentMonth((oldState) => {
      return { date: oldState.date?.[method](numberMonthSwitch, 'M') }
    })
  }

  return (
    <div className={`calendars ${innerClassName}`}>
      <div className="innerCalendars">
        <ListMessages
          allMessages={allMessages}
          isShow={showListMessages}
          selectedDate={selectedDate}
          onChangeSelectedDate={setSelectedDate}
          onHide={() => setShowListMessages(false)}
        />
        {[currentMonth, nextMonth].map((item, index) => (
          <TableCalendar
            key={index}
            isAdmin={isAdmin}
            currentMonth={item}
            allMessages={allMessages}
            onChangeSelectedDate={setSelectedDate}
            onShowMessageList={() => setShowListMessages(true)}
          />
        ))}
        <button
          disabled={
            currentMonth.date.format(MONTH_YEAR_FORMAT) <=
            moment(allMessages[0]?.deliveryDate).format(MONTH_YEAR_FORMAT)
          }
          onClick={() => onChangeVisibleDates('subtract')}
          className="arrowPrevMonth"
        >
          <ArrowLeft size={25} />
        </button>
        <button
          disabled={
            nextMonth.date.format(MONTH_YEAR_FORMAT) >
            moment(allMessages[allMessages.length - 1]?.deliveryDate).format(MONTH_YEAR_FORMAT)
          }
          onClick={() => onChangeVisibleDates('add')}
          className="arrowNextMonth"
        >
          <ArrowRight size={25} />
        </button>
      </div>
      {isLoading && (
        <div className="innerLoadingCalendar">
          <LoadingIcon className="loadingCalendarIcon" />
        </div>
      )}
    </div>
  )
}

export default Calendar
