import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'

import { DATE_FORMAT_WITH_TIME } from '../utils/constants'
import { getUserActivities } from '../api/admin/user'
import HistoryUserActivities from './HistoryUserActivities'

const TableUserActivity = () => {
  const { userId, themeId } = useParams()
  const [selectedPage, setSelectedPage] = useState(1)
  const [loadingNewActions, setLoadingNewActions] = useState(false)
  const [userActions, setUserActions] = useState({ data: [] })

  useEffect(() => {
    setLoadingNewActions(true)
    getUserActivities({ userId, themeId, size: 10, page: selectedPage })
      .then(({ data }) => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        const formattedActivities = data?.data?.map((item) => ({
          ...item,
          eventTime: moment
            .tz(item.createdAt, 'UTC')
            .tz(userTimezone)
            .format(DATE_FORMAT_WITH_TIME),
        }))
        setUserActions((oldState) => ({
          ...data,
          data: [...oldState.data, ...formattedActivities],
        }))
      })
      .finally(() => setLoadingNewActions(false))
  }, [selectedPage, userId])

  return (
    <HistoryUserActivities
      activities={userActions?.data}
      isLoading={loadingNewActions}
      onClickPaginateBtn={() => setSelectedPage((oldState) => oldState + 1)}
      showPaginationBtn={selectedPage < userActions?.lastPage}
    />
  )
}

export default TableUserActivity
