import { NavLink } from 'react-router-dom'

const NavigateLink = ({ route, text, Icon }) => {
  return (
    <NavLink
      className={({ isActive }) => `adminMenuLink ${isActive ? 'activeMenuAdmin' : ''}`}
      to={route}
    >
      <div className="innerIconMenuAdmin">
        <Icon className="iconAdminMenu" size={30} />
      </div>
      {text}
    </NavLink>
  )
}

export default NavigateLink
