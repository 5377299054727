import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { setShowModalCancelSubscription } from '../../redux/actions/ui'
import ModalSupport from '../Modals/ModalSupport'
import ModalCancelSubscription from '../Modals/ModalCancelSubscription'
import Header from './components/Header'
import Footer from './components/Footer'

import './styles.css'

const LayoutUser = ({
  children,
  innerClassName = '',
  showModalCancelSubscription,
  setShowModalCancelSubscription,
}) => {
  return (
    <div className={`userPages ${innerClassName}`}>
      <section className="wrapperProfile">
        <Header />
        <div className="flex-grow-1 containerUserLayout">{children ?? <Outlet />}</div>
        <Footer />
      </section>
      <ModalSupport />
      <ModalCancelSubscription
        show={showModalCancelSubscription}
        onHide={() => setShowModalCancelSubscription(false)}
      />
    </div>
  )
}

export default connect(
  ({ ui }) => ({ showModalCancelSubscription: ui.showModalCancelSubscription }),
  {
    setShowModalCancelSubscription,
  }
)(LayoutUser)
