export const routes = {
  home: '/',
  userLogin: '/login',
  adminLogin: '/admin1248',
  dashboard: '/admin/dashboard',
  users: '/admin/users',
  user: '/admin/users/:userId',
  userThemeAnalytic: '/admin/users/:userId/theme/:themeId',
  createTheme: '/admin/new-theme/:programId',
  themeSettings: '/admin/programs/:programId/:themeId/settings',
  message: '/admin/message/:programId/:themeId/*',
  payment: '/payment',
  tags: '/admin/tags',
  interests: '/admin/interests',
  feedback: '/admin/feedback',
  adminGiftCoupons: '/admin/gift-coupons',
  adminContentStatistics: '/admin/content-statistics',
  adminGiftCouponsAnalytics: '/admin/gift-coupons/analytics',
  messaging: '/admin/messaging',
  surveyAnalytics: '/admin/survey/:surveyId',
  programs: '/admin/programs',
  programThemes: '/admin/programs/:programId',
  theme: '/admin/programs/:programId/:themeId',

  userHome: '/account-home',
  accountInformation: '/account-home/details',
  userThemeDetails: '/account-home/theme-:themeId',
  accountSubscription: '/account-home/subscription',
  themeDetails: '/account-home/theme-:themeId',
  userAchievements: '/account-home/achievements',
  userChat: '/account-home/chats',
  shop: '/account-home/shop',
  userFeed: '/account-home/feed',
  userGifts: '/account-home/gifts',
  userThemes: '/account-home/themes',
}
