import moment from 'moment-timezone'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import './styles.css'

const CustomTimePicker = ({
  label,
  onChange,
  classNameLabel = '',
  customListClass = '',
  customClassName = '',
  innerCassName = '',
  value,
  ...props
}) => {
  const handleTimeChange = (value) => {
    onChange(value.format('HH:mm'))
  }

  return (
    <div className={innerCassName}>
      {label && <label className={`inputLabel ${classNameLabel}`}>{label}</label>}
      <div>
        <TimePicker
          inputReadOnly
          className={`selectTime ${customClassName}`}
          popupClassName={`listTime ${customListClass}`}
          showSecond={false}
          onChange={handleTimeChange}
          hideDisabledOptions
          value={moment(value, 'HH:mm')}
          minuteStep={5}
          use12Hours
          {...props}
        />
      </div>
    </div>
  )
}

export default CustomTimePicker
