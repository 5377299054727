import { SURVEY_QUESTION_TYPES } from '../../utils/constants'
import StarRating from '../StarRating'

const Answer = ({ questionType, answer, className = '' }) => {
  if (questionType === SURVEY_QUESTION_TYPES.RATING_SCALE) {
    return <StarRating disabled value={answer} />
  }

  return <p className={`questionAnswer ${className}`}>{answer}</p>
}

export default Answer
