import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { ROLE_GUEST } from '../../../../utils/constants'
import { routes } from '../../../../router/routes'
import { setRoleUser } from '../../../../redux/actions/user'
import { clearSession } from '../../../../utils/auth'
import { ShutDown, IconHome, MessageSquareEdit, Gift, BookContent } from '../../../SvgIcon'

import './styles.css'

const UserDropdownMenu = ({ showMenu, setShowMenu, profileInfoRef }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const menuRef = useRef(null)

  const handleClickOutside = (e) => {
    const isClickOutside =
      !menuRef.current?.contains(e.target) && !profileInfoRef.current.contains(e.target)

    if (isClickOutside) {
      setShowMenu(false)
    }
  }

  const logout = () => {
    clearSession()
      .then(() => dispatch(setRoleUser(ROLE_GUEST)))
      .then(() => navigate(routes.userLogin))
  }

  const navigateClick = (route) => {
    setShowMenu(false)
    navigate(route)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [showMenu])

  if (!showMenu) {
    return null
  }

  return (
    <div ref={menuRef} className="userDropdownMenu">
      <button onClick={() => navigateClick(routes.userHome)} className="buttonUserMenu">
        <IconHome fill="var(--swatch--cta-blue)" size={22} />
        <span>Home</span>
      </button>
      <button onClick={() => navigateClick(routes.accountInformation)} className="buttonUserMenu">
        <MessageSquareEdit fill="var(--swatch--cta-blue)" size={22} />
        <span>Account</span>
      </button>
      <button onClick={() => navigateClick(routes.userThemes)} className="buttonUserMenu md">
        <BookContent fill="var(--swatch--cta-blue)" size={22} />
        <span>Subscriptions</span>
      </button>
      <button onClick={() => navigateClick(routes.userGifts)} className="buttonUserMenu">
        <Gift fill="var(--swatch--cta-blue)" size={22} />
        <span>Gifts</span>
      </button>
      <button onClick={logout} className="buttonUserMenu">
        <ShutDown fill="var(--swatch--cta-blue)" size={22} />
        <span>Log out</span>
      </button>
    </div>
  )
}

export default UserDropdownMenu
