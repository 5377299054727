import { STRIPE_PRODUCTS_TYPES } from '../../../utils/constants'
import { CreditCardOff } from '../../SvgIcon'

const TITLES = {
  [STRIPE_PRODUCTS_TYPES.ONE_TIME_SUBSCRIPTION]: ({ themeName }) => (
    <>
      Failed payment for theme "<b className="firstLetterUppercase">{themeName}</b>"
    </>
  ),
  [STRIPE_PRODUCTS_TYPES.SUBSCRIPTION]: ({ themeName }) => (
    <>
      Failed subscription payment for theme "<b className="firstLetterUppercase">{themeName}</b>"
    </>
  ),
  [STRIPE_PRODUCTS_TYPES.GIFT_COUPON]: () => 'Failed purchase gift coupon',
  [STRIPE_PRODUCTS_TYPES.OTHER]: () => 'Failed payment',
}

const FailedPayment = ({ eventTime, extra, themeName }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <CreditCardOff size={23} fill="var(--persian-red)" />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">{TITLES[extra?.type]({ themeName })}</p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default FailedPayment
