import { useMemo, useRef } from 'react'

import { FORM_FIELD_CHAT } from '../../../../utils/constantsForms'
import { PaperPlane } from '../../../../components/SvgIcon'
import HookForm from '../../../../components/HookForm'

const FormSendMessage = ({ onSubmit, inputRef }) => {
  const formRef = useRef(null)
  const isMobileDevice = useMemo(
    () =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    []
  )

  const sendMessage = (data) => onSubmit(data, formRef)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      formRef.current.handleSubmit(sendMessage)()
    }
  }
  return (
    <HookForm
      ref={formRef}
      className="chatForm globalChatForm"
      onSubmit={sendMessage}
      Footer={() => (
        <button className="buttonSendMessage">
          <PaperPlane size={20} fill="var(--catalina-blue)" />
        </button>
      )}
      fields={[
        {
          ...FORM_FIELD_CHAT,
          onKeyDown: !isMobileDevice ? handleKeyDown : null,
          inputRef,
          disabled: false,
        },
      ]}
    />
  )
}

export default FormSendMessage
