import { Checkbox } from 'rsuite'

import './styles.css'

const CustomCheckbox = ({ name = '', className = '', label, error, value, onChange, ...rest }) => {
  return (
    <Checkbox
      {...rest}
      id={name}
      checked={value}
      className={`customCheckbox ${className} ${error ? '' : ''} `}
      onChange={() => onChange(!value)}
    >
      {label}
    </Checkbox>
  )
}

export default CustomCheckbox
