import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { omitBy, toNumber } from 'lodash'

import { FIELDS_FILTER_DASHBOARD } from '../../../utils/constantsForms'
import { THEMES_ID } from '../../../utils/constants'
import { isNullOrEmpty } from '../../../utils/helpers'
import { IconFilter } from '../../../components/SvgIcon'
import ModalFilter from '../../../components/Modals/ModalFilter'
import IconButton from '../../../components/IconButton'
import LineChart from '../../../components/DashboardCharts/LineChart'
import ChartThemeStatistics from '../../../components/ChartThemeStatistics'
import ContentRating from '../../../components/DashboardCharts/ContentRating'

import './styles.css'
import SubscriptionList from './components/SubscriptionList'

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [filterData, setFilterData] = useState({
    themeId: toNumber(THEMES_ID.meditation),
    ...JSON.parse(searchParams?.get('filterData')),
  })
  const appliedFilterCount = useMemo(() => Object.keys(filterData).length, [filterData])

  const handleUpdateFilter = useCallback((data) => {
    setShowModalFilter(false)
    setFilterData((oldState) => {
      const newFilterData = { ...oldState, ...data }
      const filteredData = omitBy(newFilterData, isNullOrEmpty)

      setSearchParams({ filterData: JSON.stringify(filteredData), selectedPage: 1 })

      return filteredData
    })
  }, [])
  const resetFilter = useCallback(() => {
    setFilterData({})
    setSearchParams({})
    setShowModalFilter(false)
  }, [])

  return (
    <div>
      <div className="innerDashboard">
        <div className="d-flex align-items-center gap-3 justify-content-between">
          <h1 className="titleFilterUser">Dashboard</h1>
          <IconButton
            buttonClassName="p-0 bg-transparent rounded-0 position-relative overflow-visible"
            renderTooltipContent={() => <p>Show Filter</p>}
            placement="bottomEnd"
            onClick={() => setShowModalFilter(true)}
          >
            <IconFilter size={20} />
            {appliedFilterCount > 0 && (
              <span className="appliedFilterCount">{appliedFilterCount}</span>
            )}
          </IconButton>
        </div>
        <LineChart filterData={filterData} />
        <SubscriptionList filterData={filterData} />
        <ContentRating filterData={filterData} />
        <ChartThemeStatistics filterData={filterData} />
      </div>
      <ModalFilter
        show={showModalFilter}
        onReset={resetFilter}
        defaultValues={filterData}
        formFields={FIELDS_FILTER_DASHBOARD}
        onHide={() => setShowModalFilter(false)}
        onSubmit={handleUpdateFilter}
      />
    </div>
  )
}

export default Dashboard
