import { camelCase, isEmpty, isEqual } from 'lodash'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { USER_HOME_VISIBLE_SUBSCRIPTION_STATUSES } from '../../../../utils/constants'
import { getSubscriptionStatusesCount } from '../../../../api/user'
import StatusTab from './StatusTab'

const SubscriptionStatusTabs = ({ filterData, onTabClick }) => {
  const { data: statusesCount } = useQuery({
    queryKey: ['statusesCount', filterData],
    queryFn: () => getSubscriptionStatusesCount(filterData),
    placeholderData: keepPreviousData,
  })

  if (Object?.keys(statusesCount?.data ?? {})?.length <= 1) {
    return
  }

  return (
    <div className="tabsList justify-content-start">
      {USER_HOME_VISIBLE_SUBSCRIPTION_STATUSES.map((item, index) => (
        <StatusTab
          key={index}
          {...item}
          onClick={onTabClick}
          isVisible={
            item.value.some((status) => statusesCount?.data[camelCase(status)]) ||
            isEmpty(item.value)
          }
          isActive={isEqual(item.value, filterData.status)}
        />
      ))}
    </div>
  )
}

export default SubscriptionStatusTabs
