import randomColor from 'randomcolor'

import { SETTINGS_RANDOM_COLOR } from '../utils/constants'
import { Tag } from '../pages/Admin/Tags/components/RowTable'
import { AutoRenew } from './SvgIcon'

const InputTag = ({
  value,
  onChange,
  error,
  placeholder = '',
  showPreview = true,
  setTagPreview = () => {},
}) => {
  const randomNewColor = () => {
    const color = randomColor(SETTINGS_RANDOM_COLOR)
    const newValue = { ...value, color }
    onChange(newValue)
    setTagPreview(newValue)
  }

  const changeInfo = (e, key) => {
    const newValue = { ...value, [key]: e.target.value }
    onChange(newValue)
    setTagPreview(newValue)
  }

  return (
    <div>
      {showPreview && <Tag className="previewTag" {...value} name={value.name || 'Preview'} />}
      <div className="innerTagInner">
        <div>
          <label className="labelInputTag">Name</label>
          <input
            type="text"
            className={`tagName ${error ? 'errorTagName' : ''}`}
            value={value.name}
            onChange={(e) => changeInfo(e, 'name')}
            placeholder={placeholder}
          />
        </div>
        <div>
          <label className="labelInputTag">Color</label>
          <div className="innerColorsChanges">
            <input
              type="color"
              value={value?.color}
              onChange={(e) => changeInfo(e, 'color')}
              className="tagColor"
            />
            <AutoRenew
              fill="#FFFFFF"
              size={33}
              onClick={randomNewColor}
              className="arrowRepeatTag"
              style={{ backgroundColor: value?.color }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputTag
