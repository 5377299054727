import { DAYS_COUNT } from '../../../../utils/constants'
import CustomDropdown from '../../../../components/CustomDropdown'

const SelectRelativeTime = ({ onChange, error, value }) => {
  return (
    <div className="innerCountDays">
      <p className="titleCountDays">Number of waiting days after the previous message:</p>
      <CustomDropdown
        error={error}
        onChange={(value) => onChange(value.title)}
        innerDropdown="timeSelectMessage"
        title="Days"
        value={value}
        data={DAYS_COUNT}
      />
    </div>
  )
}

export default SelectRelativeTime
