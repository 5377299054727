import {
  camelCase,
  isArray,
  isEmpty,
  isNil,
  isObject,
  isString,
  snakeCase,
  transform,
} from 'lodash'
import moment from 'moment-timezone'

import { DATE_FORMAT_RANGE_PICKER } from './constants'

export const camelize = (obj) => {
  return transform(obj, (acc, value, key, target) => {
    const isValidDateKey = moment(new Date(key)).isValid()
    const camelKey = isArray(target) || isValidDateKey ? key : camelCase(key)

    acc[camelKey] = isObject(value) ? camelize(value) : value
  })
}

export const snakeize = (obj) => {
  return transform(obj, (acc, value, key) => {
    const snakeKey = snakeCase(key)
    acc[snakeKey] = isObject(value) ? snakeize(value) : value
  })
}

export const formatPhoneNumber = (phoneNumberString) => {
  const match = phoneNumberString.match(/^\+1(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return ['+1 (', match[1], ') ', match[2], '-', match[3]].join('')
  }
  return phoneNumberString
}

export const checkDisabledDate = ({ date, minDate }) => {
  const checkValidDate = minDate ?? new Date()

  return date > moment() || date <= moment(checkValidDate).startOf('day')
}

export const generateDateRangePlaceholder = ({
  oldestEvent = moment(),
  format = DATE_FORMAT_RANGE_PICKER,
}) => {
  const checkValidDate = oldestEvent ?? new Date()

  return `${moment(checkValidDate).format(format)} ~ ${moment().format(format)}`
}

export const sortObjectByOrder = (obj, order) => {
  const sortedObj = {}

  order.forEach((key) => {
    if (Object.keys(obj).includes(key)) {
      sortedObj[key] = obj[key]
    }
  })

  Object.keys(obj).forEach((key) => {
    if (!Object.keys(sortedObj).includes(key)) {
      sortedObj[key] = obj[key]
    }
  })

  return sortedObj
}

export const convertToFile = (dataUrl, filename) => {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    decode = atob(arr[1]),
    n = decode.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = decode.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}
export const isNullOrEmpty = (value) =>
  isNil(value) || (isEmpty(value) && (isArray(value) || isString(value)))
