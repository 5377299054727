import { isEmpty, set } from 'lodash'
import { Tooltip, Whisper } from 'rsuite'

import { Info } from '../SvgIcon'
import CustomInput from '../CustomInput'

import './styles.css'

const RangeInput = ({
  value,
  inputSettings,
  label,
  firstInputSettings,
  secondInputSettings,
  showIconInfo,
  infoText,
  error,
  onChange,
}) => {
  return (
    <div>
      {!isEmpty(label) && (
        <div className="d-flex align-items-center gap-1 inputLabel">
          <label>{label}</label>
          {showIconInfo && (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={
                <Tooltip>
                  <p>{infoText}</p>
                </Tooltip>
              }
            >
              <span className="d-flex">
                <Info fill="var(--darkest-gray)" size={15} />
              </span>
            </Whisper>
          )}
        </div>
      )}
      <div className="innerRangeInput">
        <CustomInput
          {...inputSettings}
          {...firstInputSettings}
          value={value[0]}
          error={error}
          onChange={({ target }) => {
            const newValue = set(value, '[0]', target.value)
            onChange(newValue)
          }}
        />
        <span>-</span>
        <CustomInput
          {...inputSettings}
          {...secondInputSettings}
          value={value[1]}
          error={error}
          onChange={({ target }) => {
            const newValue = set(value, '[1]', target.value)
            onChange(newValue)
          }}
        />
      </div>
    </div>
  )
}

export default RangeInput
