const Flag = ({ size, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 65 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.33333 74C3.10556 74 2.07567 73.5821 1.24367 72.7464C0.414556 71.9135 0 70.8804 0 69.6471V4.35294C0 3.11961 0.414556 2.08506 1.24367 1.24929C2.07567 0.416432 3.10556 0 4.33333 0H35.425C36.4361 0 37.3389 0.32647 38.1333 0.979411C38.9278 1.63235 39.4333 2.46667 39.65 3.48235L40.7333 8.70588H60.6667C61.8944 8.70588 62.9229 9.12231 63.752 9.95518C64.584 10.7909 65 11.8255 65 13.0588V47.8824C65 49.1157 64.584 50.1488 63.752 50.9816C62.9229 51.8174 61.8944 52.2353 60.6667 52.2353H38.2417C37.2306 52.2353 36.3278 51.9088 35.5333 51.2559C34.7389 50.6029 34.2333 49.7686 34.0167 48.7529L32.9333 43.5294H8.66667V69.6471C8.66667 70.8804 8.25211 71.9135 7.423 72.7464C6.591 73.5821 5.56111 74 4.33333 74Z" />
    </svg>
  )
}

export default Flag
