import { Coupon } from '../../SvgIcon'

const AppliedPromotionCode = ({ extra, eventTime, themeName }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <Coupon size={20} fill="var(--catalina-blue)" />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">
          Promotion code <b>"{extra.code}"</b> applied to theme "
          <b className="firstLetterUppercase">{themeName}</b>"
        </p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default AppliedPromotionCode
