import { useMemo, useState } from 'react'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { isEmpty, isNil } from 'lodash'

import { getUserContents, toggleFavoriteContent } from '../../../../api/user'
import { LoadingIcon } from '../../../../components/SvgIcon'
import Content from '../../../../components/Content'
import Pagination from '../../../../components/Pagination'
import EmptyBlock from '../../../../components/EmptyBlock'

const FavoriteBlock = ({ isActiveBlock, themeId }) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const enableFetchRequest = useMemo(
    () => !isNil(themeId) && isActiveBlock,
    [isActiveBlock, themeId]
  )
  const queryClient = useQueryClient()

  const mutationToggleFavorite = useMutation({
    mutationFn: toggleFavoriteContent,
    onSuccess: (_, { id }) => {
      queryClient.setQueryData(['favoriteContents', themeId, selectedPage], (oldData) => {
        const listOfContents = oldData?.data?.data?.reduce((acc, item) => {
          if (item.id === id) {
            return [...acc, { ...item, isFavorite: !item.isFavorite }]
          }
          return [...acc, item]
        }, [])

        return { data: { ...oldData?.data, data: listOfContents } }
      })
    },
  })

  const { data: { data: favoriteContents } = {}, isFetching: isFetchingContent } = useQuery({
    queryKey: ['favoriteContents', themeId, selectedPage],
    queryFn: () =>
      getUserContents({
        themeId,
        size: 10,
        page: selectedPage,
        isFavorite: true,
      }),
    enabled: enableFetchRequest,
    placeholderData: keepPreviousData,
  })

  return (
    <section className={`tabBlock ${isActiveBlock ? 'visibleTabBlock' : ''}`}>
      <div className="position-relative">
        <div className={`contentList mt-0 ${isFetchingContent ? 'minHeightContentList' : ''}`}>
          {favoriteContents?.data?.map(
            ({ content, createdAt, isFavorite, id, contentLink }, index) => (
              <Content
                key={index}
                link={contentLink}
                image={content?.imageIcon}
                isFavorite={isFavorite}
                deliveryDate={createdAt}
                title={content?.subtitle}
                artist={content?.voiceArtist}
                onClickHeart={() => mutationToggleFavorite.mutate(id)}
              />
            )
          )}
        </div>
        <Pagination
          forcePage={selectedPage - 1}
          total={favoriteContents?.total}
          lastPage={favoriteContents?.lastPage}
          show={favoriteContents?.lastPage > 1}
          onPageChange={({ selected }) => setSelectedPage(selected + 1)}
        />
        {!isFetchingContent && isEmpty(favoriteContents?.data) ? (
          <EmptyBlock
            innerClassName="mt-3"
            title="Your Favorite List is Empty"
            imageSrc="/images/smallDaydropLogo.png"
            supTitle="Discover new content and add it to your favorites to see it here."
          />
        ) : null}
        {isFetchingContent && (
          <div className="innerLoader">
            <LoadingIcon size={60} className="loaderIcon" />
          </div>
        )}
      </div>
    </section>
  )
}

export default FavoriteBlock
