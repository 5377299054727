import { useQuery } from '@tanstack/react-query'
import moment from 'moment-timezone'
import { reduce } from 'lodash'
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  Area,
} from 'recharts'

import { DATE_FORMAT_MD, DATE_FORMAT_MDY } from '../../utils/constants'
import { getContentStatisticEachDates } from '../../api/content'

import './styles.css'

const ChartThemeStatistics = ({ filterData }) => {
  const { data: chartInfo } = useQuery({
    queryKey: ['contentStatistic', filterData],
    queryFn: () =>
      getContentStatisticEachDates({
        dateCollectionRange: [
          moment().subtract(1, 'w').format(DATE_FORMAT_MDY),
          moment().format(DATE_FORMAT_MDY),
        ],
        ...filterData,
      }),
    select: ({ data }) => {
      return reduce(
        data,
        (acc, value, date) => [...acc, { ...value, date: moment(date).format(DATE_FORMAT_MD) }],
        []
      )
    },
  })

  return (
    <div>
      <p className="subTitleAdmin">Content statistics</p>
      <ResponsiveContainer height={500} width="100%">
        <AreaChart
          width={500}
          height={400}
          data={chartInfo}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <Legend />
          <YAxis allowDecimals={false} tickCount={5} />
          <Tooltip />
          <Area
            type="monotone"
            stackId={1}
            dataKey="finishedContent"
            name="Finished"
            stroke="var(--user-status-complete-theme)"
            fill="var(--user-status-complete-theme)"
            fillOpacity={0.8}
          />
          <Area
            type="monotone"
            stackId={1}
            dataKey="startedContent"
            name="Started"
            stroke="var(--user-status-incomplete-theme)"
            fill="var(--user-status-incomplete-theme)"
            fillOpacity={0.8}
          />
          <Area
            type="monotone"
            stackId={1}
            dataKey="openedContent"
            name="Opened"
            stroke="var(--user-status-opened-theme)"
            fill="var(--user-status-opened-theme)"
            fillOpacity={0.8}
          />
          <Area
            type="monotone"
            dataKey="unopenedContent"
            name="Unopened"
            stackId={1}
            stroke="var(--user-status-unopened-theme)"
            fill="var(--user-status-unopened-theme)"
            fillOpacity={0.8}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ChartThemeStatistics
