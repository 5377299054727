import { Route, Routes } from 'react-router-dom'

import SocketProvider from '../components/SocketProvider'
import Page404 from '../pages/Page404'

import LoginAdmin from '../pages/Admin/Login'
import LoginUser from '../pages/User/Login'

import { ROLES } from '../utils/constants'
import LayoutUser from '../components/LayoutUser'
import LayoutAdmin from '../components/LayoutAdmin'
import { routes } from './routes'
import { adminPages, userPages } from './pages'
import RenderComponent from './RenderComponent'

const Router = ({ isLoadingApp }) => {
  if (isLoadingApp) {
    return null
  }

  return (
    <SocketProvider>
      <Routes>
        <Route path={routes.home} element={<LoginUser />} />
        <Route path={routes.userLogin} element={<LoginUser />} />
        <Route path={routes.adminLogin} element={<LoginAdmin />} />

        <Route
          element={
            <RenderComponent Component={LayoutUser} roles={[ROLES.ROLE_USER, ROLES.ROLE_TESTER]} />
          }
        >
          {userPages.map((item) => (
            <Route key={item.name} path={item.path} element={<item.Component />} />
          ))}
        </Route>

        <Route
          element={
            <RenderComponent
              Component={LayoutAdmin}
              roles={[ROLES.ROLE_ADMIN, ROLES.ROLE_TESTER]}
            />
          }
        >
          {adminPages.map((item) => (
            <Route key={item.name} path={item.path} element={<item.Component />} />
          ))}
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </SocketProvider>
  )
}

export default Router
