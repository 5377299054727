import { useEffect, useState } from 'react'
import { defaultTo, isNil, reduce } from 'lodash'

import { getUserInfoById } from '../../api/admin/user'

const useUserInfo = ({ userId, formRef = null }) => {
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    getUserInfoById(userId).then(({ data }) => {
      const mapUserData = reduce(data, (res, value, key) => ({
        ...res,
        [key]: defaultTo(value, ''),
      }))
      const { timezoneOffset, timezoneName } = mapUserData
      const { utmCampaign, utmMedium, utmSource } = mapUserData
      const userUtmParams = { utmSource, utmMedium, utmCampaign }

      const interests = mapUserData?.interests.map(({ id }) => id)
      const birthdate = mapUserData?.birthdate ?? ''
      setUserInfo({ ...mapUserData, userUtmParams })

      if (!isNil(formRef)) {
        formRef.current?.reset({
          ...mapUserData,
          interests,
          birthdate,
          timezone: {
            timezoneOffset: timezoneOffset,
            timezoneName: timezoneName,
          },
        })
      }
    })
  }, [userId])

  return { ...userInfo, setUserInfo }
}

export default useUserInfo
