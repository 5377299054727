import { Table } from 'rsuite'

const ProgramInfoCell = ({ rowData, ...props }) => {
  return (
    <Table.Cell {...props}>
      <div>
        <p className="tableProgramsProgramName">
          <b>Name: </b>
          {rowData?.name}
        </p>
        <span className="tableProgramsProgramInfo">
          <b>Total users: {rowData?.usersCount}</b>
        </span>
      </div>
    </Table.Cell>
  )
}

export default ProgramInfoCell
