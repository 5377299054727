import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { routes } from '../../router/routes'
import { ROLES } from '../../utils/constants'
import './style.css'

const Page404 = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const userRole = useSelector((state) => state.user.role)

  const isAdminPage = location.pathname.includes('/admin')
  const guestRouteHome = isAdminPage ? routes.adminLogin : routes.userLogin
  const customRedirectRoute = userRole === ROLES.ROLE_ADMIN ? routes.dashboard : routes.userHome
  const routeGoHome = userRole === ROLES.ROLE_GUEST ? guestRouteHome : customRedirectRoute

  const handleGoHome = () => {
    navigate(routeGoHome)
  }

  return (
    <div className="inner404">
      <p className="title404">404</p>
      <p className="supTitleNotFound">Ooops!...Page not found.</p>
      <button onClick={handleGoHome} className="buttonGoBack">
        Go Home
      </button>
    </div>
  )
}

export default Page404
