import { useCallback, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'

import {
  MESSAGE_TYPES,
  PIPELINE_TYPES,
  PIPELINE_TABLES_SETTINGS,
  DROPDOWN_PICKER_PIPELINES_DATA,
} from '../../../utils/constants'
import { getThemeDetails } from '../../../api/admin/themes'
import { ThList } from '../../../components/SvgIcon'
import ModalTableContent from '../../../components/Modals/ModalTableContent'
import CustomDropdownPicker from '../../../components/CustomDropdownPicker'
import Button from '../../../components/Button'
import BackButton from '../../../components/BackButton'
import Pipeline from './components/Pipeline'
import AdditionalButtons from './components/AdditionalButtons'

import './styles.css'

const Theme = () => {
  const { programId, themeId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultMessageTypes = JSON.parse(searchParams.get('messageTypes')) ?? []

  const [showModalMeditations, setShowModalMeditations] = useState(false)
  const [messageTypes, setMessageTypes] = useState(
    !isEmpty(defaultMessageTypes)
      ? defaultMessageTypes
      : [PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.RELATIVE]
  )
  const scrollToMessageTable = useCallback(({ value }) => {
    setTimeout(() => {
      const element = document.getElementById(`table-${value}`)
      if (!element) return
      window.scroll({
        top: element.offsetTop,
        behavior: 'smooth',
      })
    }, 500)
  }, [])

  const updateVisibleTables = useCallback((messageTypes) => {
    setMessageTypes(messageTypes)
    setSearchParams({ messageTypes: JSON.stringify(messageTypes) })
  }, [])

  const { data: theme } = useQuery({
    queryKey: ['theme', themeId],
    queryFn: () => getThemeDetails(themeId),
  })

  const pipelinesDropdownData = useMemo(
    () =>
      DROPDOWN_PICKER_PIPELINES_DATA.reduce(
        (acc, item) => (item.hasAccess(theme?.data) ? [...acc, item] : acc),
        []
      ),
    [theme]
  )

  return (
    <div>
      <div className="themeContent containerAdminMainInfo">
        <CustomDropdownPicker
          block
          label={`"${theme?.data?.name}" pipelines`}
          innerClassName="mw-100 w-100 innerDropdownPipelines"
          placeholder="Select type of pipeline to display"
          groupBy="globalType"
          onChange={updateVisibleTables}
          value={messageTypes}
          onSelect={(_, selectedValue) => scrollToMessageTable(selectedValue)}
          dataDropdown={pipelinesDropdownData}
          size="lg"
          labelKey="title"
          valueKey="value"
        />
        <div>
          {messageTypes.map((tableKey, index) => (
            <Pipeline key={index} {...PIPELINE_TABLES_SETTINGS[tableKey]} tableKey={tableKey} />
          ))}
        </div>
      </div>
      <AdditionalButtons programName={theme?.data?.name} />
      <Button
        title="Content list"
        customClass="buttonListMeditation"
        onClick={() => setShowModalMeditations(true)}
      >
        <ThList size={20} fill="var(--catalina-blue)" />
      </Button>
      <BackButton route={`/admin/programs/${programId}`} />

      <ModalTableContent
        themeId={themeId}
        show={showModalMeditations}
        onHide={() => setShowModalMeditations(false)}
      />
    </div>
  )
}

export default Theme
