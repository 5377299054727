import { AutoRenew } from '../../SvgIcon'
import { SubscriptionStatus } from '../../Statuses'

const SubscriptionUpdate = ({ themeName, extra, eventTime }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <AutoRenew size={22} fill="var(--active-color-text)" />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">
          Subscription status for "<b className="firstLetterUppercase">{themeName}</b>" has been
          updated from <SubscriptionStatus customClass="sm" status={extra?.oldStatus} /> to{' '}
          <SubscriptionStatus customClass="sm" status={extra?.newStatus} />
        </p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default SubscriptionUpdate
