import moment from 'moment-timezone'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import toNumber from 'lodash/toNumber'
import { useCallback } from 'react'

import { MONTH_COLORS } from '../constants'

const useChart = () => {
  const createDateRangeArray = useCallback((startDate, totalDays) => {
    return Array.from({ length: totalDays + 1 }, (_, index) => ({
      date: moment(startDate).add(index, 'days'),
      count: 0,
    }))
  }, [])

  const findDataCountForDate = useCallback((data, date) => {
    return data.find((item) => moment(item.date).isSame(date, 'day'))?.count ?? 0
  }, [])

  const populateCountsInDateRange = useCallback(
    (dateRangeArray, data) => {
      return dateRangeArray.map((item) => ({
        ...item,
        count: findDataCountForDate(data, item.date),
      }))
    },
    [findDataCountForDate]
  )

  const formatDataLineChart = useCallback(
    ({ data, dateRange }) => {
      const [startDate, endDate] = dateRange
      const totalDays = moment(endDate).diff(moment(startDate), 'days')
      const dateRangeArray = createDateRangeArray(startDate, totalDays)
      const populatedData = populateCountsInDateRange(dateRangeArray, data)

      const groupedData = groupBy(populatedData, (item) =>
        item.date.utcOffset(0, true).format('MMMM')
      )

      const dataLines = reduce(
        groupedData,
        (result, value, key) => {
          const getConnectingKey = (item) => {
            const lastMonth = result[result.length - 1]?.name
            return result.length ? { [lastMonth]: toNumber(item.count) } : {}
          }

          const lineData = value.map((item) => ({
            date: moment(item.date).format('DD'),
            value: item.count,
            [key]: toNumber(item.count),
            ...getConnectingKey(item),
          }))

          return [
            ...result,
            {
              color: MONTH_COLORS[key],
              name: key,
              data: lineData,
            },
          ]
        },
        []
      )

      const dataChart = reduce(dataLines, (result, item) => [...result, ...item.data], [])

      return { dataLines, dataChart }
    },
    [createDateRangeArray, populateCountsInDateRange]
  )

  const getLandingChartProps = useCallback(({ cx, cy, midAngle, outerRadius }) => {
    const radian = Math.PI / 180
    const sin = Math.sin(-radian * midAngle)
    const cos = Math.cos(-radian * midAngle)
    const extraTextCoords = cos >= 0 ? 1 : -1

    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + extraTextCoords * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return { textAnchor, ey, ex, sy, sx, mx, my, extraTextCoords }
  }, [])

  return { formatDataLineChart, getLandingChartProps }
}

export default useChart
