import r from '../utils/request'

export const createGiftCoupon = (params) => {
  return r.uploadWithToast('/admin/gift-coupon-pricings', params, 'post', {
    success: 'Created gift coupon',
  })
}

export const changeGiftCoupon = (params) => {
  return r.uploadWithToast(`/admin/gift-coupon-pricings/${params?.id}`, params, 'put', {
    success: `Gift "${params.name}" was changed"`,
  })
}

export const getAllPaginateGiftPricing = (params) => {
  return r.get('/admin/gift-coupon-pricings/paginate', params)
}

export const getAllGiftPricing = () => {
  return r.get('/admin/gift-coupon-pricings')
}

export const toggleGiftStatus = (giftId) => {
  return r.put(`/admin/gift-coupon-pricings/${giftId}/toggle-status`)
}

export const getAllAvailableGiftCoupons = () => {
  return r.get('/gift-coupon-pricings')
}

export const buyGiftCoupon = (params, toastMessages) => {
  return r.postWithToast(`/gift-coupons/${params.giftId}/buy`, params, {
    ...toastMessages,
    success: 'Purchase was successful',
  })
}

export const getGiftCouponMessageId = (giftCouponMessageId) => {
  return r.get(`/gift-coupon-messages/${giftCouponMessageId}`)
}

export const getGiftCouponInfo = (messageId) => {
  return r.get(`/gift-coupons/${messageId}/message`)
}

export const getCouponsStatistics = (params) => {
  return r.get('/admin/gift-coupons/statistics', params)
}

export const getPricingInfo = (giftPricingId) => {
  return r.get(`/admin/gift-coupon-pricings/${giftPricingId}`)
}

export const getAvailableStripeCoupons = () => {
  return r.get('/admin/stripe/gift-coupons/available-stripe-coupons')
}
