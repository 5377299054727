import { useMemo } from 'react'
import moment from 'moment-timezone'

import { DATE_FORMAT_MDY } from '../../utils/constants'
import { FillHeart, OutlineHeart } from '../SvgIcon'
import IconButton from '../IconButton'

import './styles.css'

const Content = ({ title, link, deliveryDate, artist, image, isFavorite, onClickHeart }) => {
  const HeartIcon = useMemo(() => (isFavorite ? FillHeart : OutlineHeart), [isFavorite])
  const tooltipHeartIconText = useMemo(
    () => (isFavorite ? 'Favorite content' : 'Add to favorite'),
    [isFavorite]
  )
  const formattedDate = useMemo(() => moment(deliveryDate).format(DATE_FORMAT_MDY), [deliveryDate])

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="content">
      <img src={image} alt={`${title} content image`} loading="lazy" className="contentImg" />
      <p className="contentTitle">
        {title} <span className="contentArtist mobile">{artist}</span>
      </p>
      <p className="contentArtist">{artist}</p>
      <p className="contentDeliveryDate">{formattedDate}</p>
      <div className="innerHeartColums">
        <IconButton
          buttonClassName="cursorPointer bg-transparent p-0"
          placement="auto"
          renderTooltipContent={() => <p>{tooltipHeartIconText}</p>}
        >
          <HeartIcon
            onClick={(e) => {
              e.preventDefault()
              onClickHeart({ isFavorite })
            }}
            fill="var(--swatch--cta-blue)"
            size={25}
          />
        </IconButton>
      </div>
    </a>
  )
}

export default Content
