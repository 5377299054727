import { Dropdown } from 'rsuite'
import Picker from '@emoji-mart/react'

import { EmojiSmile } from './SvgIcon'

const EmojiPicker = ({ emojiPickerSettings = {}, dropDownSettings = {} }) => {
  return (
    <Dropdown
      noCaret
      placement="bottomEnd"
      toggleClassName="emojiBtn"
      title={<EmojiSmile size={20} />}
      className="dropdownEmojiPicker"
      {...dropDownSettings}
    >
      <Picker
        set="apple"
        title="Pick an Emoji"
        theme="light"
        emoji="point_up"
        {...emojiPickerSettings}
      />
    </Dropdown>
  )
}

export default EmojiPicker
