import { Modal } from 'rsuite'

const ModalHubspotIntegration = ({ show, onHide, themeId }) => {
  return (
    <Modal open={show} onClose={onHide}>
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex align-items-center gap-2">
            <img
              src="/images/hubspotIcon.webp"
              alt="HubSpot Integration"
              width={25}
              height={25}
              className="rounded-1"
            />
            <b>Hubspot Integration</b>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-1">
          <b>Theme ID: {themeId}</b>
        </p>
        <p className="mb-1">
          Here's how to set up a registration form in HubSpot. <br /> To do this, you will need to
          follow the instructions as written below.
        </p>
        <p className="mt-3">
          <b>1.</b> On the landing page editing in HubSpot, click the button to add a new block and
          in the search box enter "Daydrop Form Module" and move it to where you want to see it on
          your site.
        </p>
        <img
          className="w-100 rounded-3 mt-1 fit-contain"
          height={250}
          alt="Step 1 Hubspot Integration"
          src="/images/hubspotIntegrationInstruction/step-1.png"
        />
        <p className="mt-3">
          <b>2.</b> When you add a registration form, you will have a configuration panel for this
          form on the left side, an example is shown in the picture below.
        </p>
        <img
          className="w-100 rounded-3 mt-1 fit-contain"
          height={250}
          alt="Step 1 Hubspot Integration"
          src="/images/hubspotIntegrationInstruction/step-2.png"
        />
        <p className="mt-2">
          In the settings panel, you can see many different settings for this form. Most fields have
          a brief explanation or a hint of what each one means. But I want to draw attention to the
          most important field without which this form will not work <b>"Theme ID"</b>. In this
          field you should add the theme id, thanks to it we will understand which program we will
          subscribe users to when they register. <br />
          <br />
          If you don't know where to find the value for the "Theme ID" field, at the top of this
          modal it says <b>Theme ID: {themeId}</b>
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default ModalHubspotIntegration
