import { useRef, useState } from 'react'
import { connect } from 'react-redux'

import { PHONE_PATTERN_REPLACE, REPLACEMENT_PHONE } from '../../../../utils/constants'
import { ArrowDown, IconProfile } from '../../../SvgIcon'
import UserDropdownMenu from '../UserDropdownMenu'

import './styles.css'

const ProfileInfo = ({ userData }) => {
  const [showMenu, setShowMenu] = useState(false)
  const profileInfoRef = useRef(null)
  const { firstName, lastName, phone } = userData

  return (
    <div className="profileInfo">
      <div
        ref={profileInfoRef}
        onClick={() => setShowMenu((oldState) => !oldState)}
        className="buttonProfileInfo"
      >
        <IconProfile size={35} className="profileIcon" />
        <div className="innerProfileInfo">
          <p className="profileName">
            {firstName} {lastName}
          </p>
          <p className="profileEmail">{phone.replace(PHONE_PATTERN_REPLACE, REPLACEMENT_PHONE)}</p>
        </div>
        <ArrowDown
          style={showMenu ? { transform: 'rotate(180deg)' } : {}}
          size={30}
          className="arrowHeaderDropdown"
        />
      </div>
      <UserDropdownMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        profileInfoRef={profileInfoRef}
      />
    </div>
  )
}

export default connect(({ user }) => ({ userData: user.data }))(ProfileInfo)
