import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { Table } from 'rsuite'

import SmallThemeCard from '../../../../components/SmallThemeCard'

const ThemesCell = ({ rowData, dataKey, ...props }) => {
  const themes = useMemo(() => rowData?.[dataKey], [rowData])
  const isEmptyThemes = useMemo(() => isEmpty(themes), [themes])

  return (
    <Table.Cell {...props} align={!isEmptyThemes ? 'left' : 'center'}>
      {!isEmptyThemes ? (
        <div className="innerListSmallThemeCard">
          {themes.map((item) => (
            <SmallThemeCard key={item?.id} {...item} />
          ))}
        </div>
      ) : (
        <p>Program doesn't have themes</p>
      )}
    </Table.Cell>
  )
}

export default ThemesCell
