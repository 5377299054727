import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment-timezone'
import { isNil } from 'lodash'
import { Drawer } from 'rsuite'

import { changeThemeDeliveryTime, manageThemeSubscription } from '../../../api/user'
import {
  ALL_NON_RENEW_SUBSCRIPTION_STATUSES,
  DATE_FORMAT_MDY,
  ALL_ACTIVE_SUBSCRIPTION_STATUSES,
  SUBSCRIPTABLE_TYPES,
  SUBSCRIPTION_API_ACTIONS,
  SUBSCRIPTION_STATUSES_INFO,
  SUBSCRIPTION_STATUSES,
  WEBSOCKET_EVENTS,
} from '../../../utils/constants'
import { camelize } from '../../../utils/helpers'
import { useWebsocketContext } from '../../SocketProvider'
import { SubscriptionStatus } from '../../Statuses'
import Price from '../../Price'
import PrimaryButton from '../../PrimaryButton'
import CustomTimePicker from '../../CustomTimePicker'
import DropdownAction from './components/DropdownAction'

import './styles.css'

const ModalSubscription = ({
  show,
  onHide,
  subscriptionInfo,
  removeSelectedSubscriptionInfo,
  changeVisibleInfo = (data) => data,
}) => {
  const [deliveryTime, setDeliveryTime] = useState('21:00')
  const queryClient = useQueryClient()
  const { getUserBaseSocket, userSocketConnected } = useWebsocketContext()

  const handleChangeDeliveryTime = useCallback(
    (newTime) => {
      setDeliveryTime(newTime)
      return changeThemeDeliveryTime({
        themeId: subscriptionInfo?.themeId,
        deliveryTime: newTime,
      })
    },
    [subscriptionInfo?.themeId]
  )

  const renewSubscription = useCallback(() => {
    manageThemeSubscription(
      {
        themeId: subscriptionInfo.themeId,
        action: SUBSCRIPTION_API_ACTIONS.REACTIVATE,
      },
      {
        success: `Your theme has been successfully renewed.`,
      }
    ).then(({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['userSubscriptions'] })
      changeVisibleInfo(data)
    })
  }, [subscriptionInfo])

  useEffect(() => {
    if (!isNil(subscriptionInfo?.deliveryTime)) {
      setDeliveryTime(subscriptionInfo?.deliveryTime)
    }
  }, [subscriptionInfo])

  useEffect(() => {
    if (userSocketConnected) {
      const userWebsocketSubscription = getUserBaseSocket()
      userWebsocketSubscription.on(WEBSOCKET_EVENTS.UPDATED_SUBSCRIPTION, (data) => {
        const { newStatus } = camelize(data)

        changeVisibleInfo({
          status: newStatus,
        })
      })
    }
  }, [userSocketConnected])

  return (
    <Drawer
      className="userModal subscriptionModal"
      placement="right"
      open={show}
      onClose={onHide}
      onExited={removeSelectedSubscriptionInfo}
    >
      <Drawer.Body className="ps-4 pe-4 d-flex flex-column">
        <div className="d-flex flex-column gap-3 align-items-center mt-3">
          <img
            alt={`Theme image ${subscriptionInfo?.name}`}
            src={subscriptionInfo?.image}
            className="subscriptionImage"
          />
          <p className="subscriptionProgramName text-center">{subscriptionInfo?.name}</p>
          {ALL_NON_RENEW_SUBSCRIPTION_STATUSES.includes(subscriptionInfo?.status) ||
          subscriptionInfo?.status === SUBSCRIPTION_STATUSES.PAUSED ? (
            <PrimaryButton
              customClass="renewSubscriptionBtn"
              onClick={renewSubscription}
              text="Renew"
            />
          ) : null}
        </div>
        <div className="mt-3 flex-grow-1">
          <div className="itemInfoSubscription">
            <p className="label">Status</p>
            <div className="value">
              <SubscriptionStatus
                {...SUBSCRIPTION_STATUSES_INFO?.[subscriptionInfo?.status]}
                status={subscriptionInfo?.status}
                cancelAtPeriodEnd={subscriptionInfo?.cancelAtPeriodEnd}
              />
            </div>
          </div>
          <div className="itemInfoSubscription">
            <p className="label">Price</p>
            <Price
              oldPrice={subscriptionInfo?.price?.old}
              newPrice={subscriptionInfo?.price?.new}
              classCurrentPrice="value"
            />
          </div>
          <div className="itemInfoSubscription">
            <p className="label">Delivery time</p>
            <CustomTimePicker
              value={deliveryTime}
              onChange={handleChangeDeliveryTime}
              customClassName="timePickerSubscription"
              customListClass="timeListPickerSubscription"
            />
          </div>
          {ALL_ACTIVE_SUBSCRIPTION_STATUSES.includes(subscriptionInfo?.status) &&
          SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION === subscriptionInfo?.subscriptableType ? (
            <div className="itemInfoSubscription">
              <p className="label">Next due</p>
              <p className="value">{moment('21 Dec 2025').format(DATE_FORMAT_MDY)}</p>
            </div>
          ) : null}
          <div className="itemInfoSubscription">
            <p className="label">Subscribed at</p>
            <p className="value">
              {moment(subscriptionInfo?.startSubscriptionAt).format(DATE_FORMAT_MDY)}
            </p>
          </div>
          {ALL_NON_RENEW_SUBSCRIPTION_STATUSES.includes(subscriptionInfo?.status) && (
            <div className="itemInfoSubscription">
              <p className="label">Ends at</p>
              <p className="value">
                {moment(subscriptionInfo?.details?.endSubscription).format(DATE_FORMAT_MDY)}
              </p>
            </div>
          )}
          {SUBSCRIPTABLE_TYPES.PAID_CONTENTS_SUBSCRIPTION ===
          subscriptionInfo?.subscriptableType ? (
            <div className="itemInfoSubscription">
              <p className="label">Remaining content</p>
              <p className="value">
                {subscriptionInfo?.details?.leftAmount}
                <span className="secondSmallText">/{subscriptionInfo?.details?.paidAmount}</span>
              </p>
            </div>
          ) : null}
        </div>
        <DropdownAction
          {...subscriptionInfo}
          hideModalSubscription={onHide}
          changeVisibleInfo={changeVisibleInfo}
        />
      </Drawer.Body>
    </Drawer>
  )
}

export default ModalSubscription
