import { useEffect, useState } from 'react'

const useTimer = () => {
  const [seconds, setSeconds] = useState(0)
  const timer = seconds === 0 ? null : `0:${seconds < 10 ? `0${seconds}` : seconds}`

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 1) {
        return setSeconds(seconds - 1)
      }
      clearInterval(myInterval)
      setSeconds(0)
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [seconds])

  return { seconds, timer, onChangeTimerTime: setSeconds }
}

export default useTimer
