import r from '../../utils/request'

export const getPrograms = (params) => {
  return r.get('/admin/programs', params)
}

export const getProgramInfo = (programId) => {
  return r.get(`/admin/programs/${programId}`)
}

export const createProgram = (params) => {
  return r.postWithToast('/admin/programs', params, {
    success: `Program "${params?.name}" created`,
  })
}

export const updateProgram = (params) => {
  return r.putWithToast(`/admin/programs/${params.id}`, params, {
    success: `Program "${params?.name}" updated`,
  })
}

export const deleteProgram = (params) => {
  return r.deleteWithToast(`/admin/programs/${params.id}`, params, {
    success: `Program "${params?.name}" deleted`,
  })
}
