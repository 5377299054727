import { useEffect } from 'react'
import { isNil } from 'lodash'

import {
  INPUT_TYPES,
  PRICE_TYPES,
  PRICE_TYPES_SETTINGS,
  THEME_SETTINGS_PRICE_TYPES,
} from '../../../../utils/constants'
import ControllerField from '../../../../components/HookForm/components/ControllerField'

const PricingModelInfo = ({ priceType, price, control, extensionDays, updatePriceType }) => {
  useEffect(() => {
    if (!isNil(priceType)) {
      updatePriceType({ priceType, price, extensionDays })
    }
  }, [priceType])

  if (isNil(priceType)) {
    return null
  }

  return (
    <div className="mt-1">
      <p className="themeSettingLabels">
        <b>{PRICE_TYPES_SETTINGS[priceType]?.label}</b> method
      </p>
      <ControllerField
        control={control}
        name="priceType"
        type={INPUT_TYPES.RADIO_BUTTON}
        defaultValue={PRICE_TYPES.RECURRING}
        buttons={THEME_SETTINGS_PRICE_TYPES}
        onChange={({ target }) => updatePriceType({ priceType: target.value })}
        innerClassName="mb-3"
        rules={{
          required: true,
        }}
      />
      <p>{PRICE_TYPES_SETTINGS[priceType]?.description}</p>
      <div className="d-flex align-items-end gap-3 justify-content-between mt-3">
        <div>
          <p className="themeSettingLabels">Total price</p>
          <p className="descriptionTimeSettings">
            Enter the total price for the One-Time Payment model. The user will make a single
            payment when registering or purchasing a product. This amount will be charged only once.
          </p>
        </div>
        <ControllerField
          name="priceAmount"
          control={control}
          rules={{
            required: true,
            validate: (value) => value > 0,
          }}
          defaultValue={price}
          type={INPUT_TYPES.INPUT_MASK}
          isNumberMask
          placeholder="0.00"
          innerClassName="innerPriceInput innerSettingsInput"
          defaultMaskOptions={{
            prefix: '',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ' ',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2,
            integerLimit: 7,
            allowNegative: false,
            allowLeadingZeroes: false,
          }}
        />
      </div>
      {PRICE_TYPES.ONE_TIME === priceType && (
        <div className="d-flex align-items-end gap-3 justify-content-between mt-3">
          <div>
            <p className="themeSettingLabels">Message count</p>
            <p className="descriptionTimeSettings">
              Enter the predicted number of messages to be sent. This number determines the theme's
              duration in days.
            </p>
          </div>
          <ControllerField
            name="priceExtensionDays"
            control={control}
            rules={{
              required: true,
              validate: (value) => value > 0,
            }}
            defaultValue={extensionDays}
            placeholder=""
            inputType="number"
            innerClassName="innerSettingsInput"
          />
        </div>
      )}
    </div>
  )
}

export default PricingModelInfo
