import { IconUsers } from '../../SvgIcon'

const Signup = ({ eventTime }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <IconUsers size={20} fill="#ff0200" />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">Account created</p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default Signup
