import { isEmpty } from 'lodash'

import './styles.css'

const EmptyBlock = ({ title, imageSrc, supTitle, innerClassName = '' }) => {
  return (
    <div className={`d-flex flex-column gap-2 align-items-center ms-2 me-2 ${innerClassName}`}>
      {!isEmpty(imageSrc) && <img src={imageSrc} className="imageEmptyBlock" alt={title} />}
      {!isEmpty(title) && <p className="titleEmptyBlock">{title}</p>}
      {!isEmpty(supTitle) && <p className="supTitleEmptyBlock">{supTitle}</p>}
    </div>
  )
}

export default EmptyBlock
