import { useMemo } from 'react'

import './styles.css'

const Price = ({
  oldPrice,
  newPrice,
  classOldPrice = '',
  innerClassName = '',
  classCurrentPrice = '',
}) => {
  const hasDiscount = useMemo(() => oldPrice !== newPrice, [oldPrice, newPrice])
  const formattedPrice = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(newPrice),
    [newPrice]
  )

  const formattedOldPrice = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(oldPrice),
    [oldPrice]
  )

  return (
    <div className={`${innerClassName} position-relative`}>
      <p className={`currentPrice ${classCurrentPrice}`}>
        {!isNaN(newPrice) ? formattedPrice : '-'}
      </p>
      {hasDiscount && !isNaN(newPrice) ? (
        <span className={`oldPrice ${classOldPrice}`}>{formattedOldPrice}</span>
      ) : null}
    </div>
  )
}

export default Price
