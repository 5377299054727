import { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import { camelCase, reduce } from 'lodash'

import { DEFAULT_VALUE_ONBOARDING_TEMPLATE } from '../../../utils/constants'
import {
  getGlobalOnboardingMessage,
  changeGlobalOnboardingMessage,
} from '../../../api/admin/messages'
import useMessages from '../../../utils/hooks/useMessages'
import PreviewMessage from '../../../pages/Admin/Message/components/PreviewMessage'
import ControllerMessageInput from '../../../pages/Admin/Message/components/FormFields/ControllerMessageInput'
import PrimaryButton from '../../PrimaryButton'
import Button from '../../Button'

import './styles.css'

const ModalOnboardingMessage = ({ show, onHide, onboardingVariables }) => {
  const [previewText, setPreviewText] = useState('')

  const { handleSubmit, control, reset, getValues, ...rest } = useForm()
  const messageValue = useMemo(() => getValues('body'), [])

  const messageVariables = reduce(
    onboardingVariables,
    (res, value) => {
      const key = camelCase(value)
      return { ...res, [key]: DEFAULT_VALUE_ONBOARDING_TEMPLATE[key] }
    },
    {}
  )

  const { replaceVariables } = useMessages(messageValue || '', messageVariables)

  const manageOnboardingMessage = (data) => {
    changeGlobalOnboardingMessage(data)
      .then(() => reset(data))
      .then(onHide)
  }

  useEffect(() => {
    if (show) {
      getGlobalOnboardingMessage().then(({ data = '' }) => {
        reset({ body: data })
        setPreviewText(replaceVariables(data, messageVariables))
      })
    }
  }, [show])

  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Onboarding message</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(manageOnboardingMessage)}>
        <Modal.Body>
          <ControllerMessageInput
            name="body"
            control={control}
            showMessageCounter
            changePreviewText={setPreviewText}
            required={false}
            messageConstants={messageVariables}
            {...rest}
          />
          <div className="innerMessageInfo">
            <PreviewMessage message={previewText} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end gap-3">
            <Button onClick={onHide}>Cancel</Button>
            <PrimaryButton text="Save" />
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default connect(({ references }) => ({
  onboardingVariables: references.generalOnboardingMessageTemplateVariables,
}))(ModalOnboardingMessage)
