import React, { forwardRef, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { isFunction } from 'lodash'

import ControllerField from './components/ControllerField'

const HookFormFieldsArray = (
  {
    className = '',
    onSubmit,
    Footer,
    defaultFields,
    focusField,
    globalName,
    globalFormClassName = '',
    appendComponent = () => {},
    ...rest
  },
  ref
) => {
  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    formState,
    setFocus,
    getValues,
    resetField,
  } = useForm({
    defaultValues: {
      [globalName]: defaultFields,
    },
  })
  const { fields, ...fieldArrayMethods } = useFieldArray({
    name: globalName,
    control,
  })

  useEffect(() => {
    if (focusField) {
      setFocus(focusField)
    }
  }, [setFocus, focusField])

  React.useImperativeHandle(ref, () => {
    return {
      getValues,
      watch: watch,
      reset: reset,
      setError: setError,
      dirtyFields: formState.dirtyFields,
      setValue: setValue,
      handleSubmit: handleSubmit,
      formState: formState,
      resetField: resetField,
      ...fieldArrayMethods,
    }
  })

  const submitHandler = handleSubmit(onSubmit)

  return (
    <form className={globalFormClassName} onSubmit={submitHandler} {...rest}>
      <div className={className}>
        {fields.map(({ name, rules, ...rest }) => (
          <ControllerField key={name} name={name} control={control} rules={rules} {...rest} />
        ))}
        {isFunction(Footer) ? (
          <Footer onSubmit={submitHandler} formState={formState} getValues={getValues} />
        ) : (
          Footer
        )}
      </div>
      {appendComponent({ watch })}
    </form>
  )
}

export default forwardRef(HookFormFieldsArray)
