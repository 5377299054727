import { useCallback, useEffect, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import moment from 'moment-timezone'
import { toNumber } from 'lodash'
import { Uploader } from 'rsuite'

import { HISTORY_BACK, INPUT_TYPES, PRICE_TYPES } from '../../../utils/constants'
import { routes } from '../../../router/routes'
import { getThemeDetails, updateThemeInfo } from '../../../api/admin/themes'
import { importThemeMessages } from '../../../api/admin/themeSettings'
import { getThemeContent } from '../../../api/admin/themeContent'
import {
  CloudUpload,
  LoadingIcon,
  Settings,
  Statistics,
  Contact,
  MessageAltDetail,
  Copy,
  Analytics,
} from '../../../components/SvgIcon'
import ModalDuplicateTheme from '../../../components/Modals/ModalDuplicateTheme'
import ModalHardcodeMessage from '../../../components/Modals/ModalHardcodeMessage'
import ModalHubspotIntegration from '../../../components/Modals/ModalHubspotIntegration'
import ControllerField from '../../../components/HookForm/components/ControllerField'
import BackButton from '../../../components/BackButton'
import IconButton from '../../../components/IconButton'
import ModalContact from '../../../components/Modals/ModalContact'
import PrimaryButton from '../../../components/PrimaryButton'
import ThemeTags from './components/ThemeTags'
import ActivateTheme from './components/ActivateTheme'
import PricingModelInfo from './components/PricingModelInfo'

import './styles.css'

const ThemeSettings = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [showModalDuplicate, setShowModalDuplicate] = useState(false)
  const [showModalContact, setShowModalContact] = useState(false)
  const [showHardcodeMessages, setShowHardcodeMessages] = useState(false)
  const [showModalHubspotIntegration, setShowModalHubspotIntegration] = useState(false)
  const [themeInfo, setThemeInfo] = useState({})
  const navigation = useNavigate()
  const { themeId } = useParams()
  const { control, reset, handleSubmit, setValue, formState, watch, unregister, register } =
    useForm()

  const uploadNewThemeMessages = useCallback(
    (filesArray) => {
      const file = filesArray[filesArray.length - 1].blobFile

      return importThemeMessages({ id: themeId, theme: file })
    },
    [themeId]
  )

  const { data: themeContents } = useQuery({
    queryKey: ['themeContent', themeId],
    queryFn: () => getThemeContent(themeId),
    select: ({ data }) => {
      return data.map(({ externalId, subtitle, ...rest }) => ({
        name: `${externalId} | ${subtitle}`,
        ...rest,
      }))
    },
  })

  const mutationUpdateTheme = useMutation({
    mutationKey: ['updateThemeInfo', themeId],
    mutationFn: (params) => {
      return updateThemeInfo({ themeId, ...params }, { success: 'Theme updated successfully' })
    },
    onSuccess: (_, newThemeData) => {
      reset(newThemeData)
      setThemeInfo((oldState) => ({
        ...oldState,
        activePrice: {
          ...oldState.activePrice,
          extensionDays: newThemeData.priceExtensionDays,
          type: newThemeData.priceType,
        },
      }))
    },
  })
  const navigateToThemeAnalytic = useCallback(() => {
    const searchParams = { filterData: JSON.stringify({ themeId: toNumber(themeId) }) }
    navigation({
      pathname: routes.dashboard,
      search: '?' + createSearchParams(searchParams).toString(),
    })
  }, [themeId])

  const updatePriceType = useCallback(({ priceType, price, extensionDays }) => {
    const action = priceType === PRICE_TYPES.RECURRING ? unregister : register

    setValue('priceType', priceType)
    action('priceAmount', { value: price })
    action('priceExtensionDays', { value: extensionDays })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getThemeDetails(themeId)
      .then(({ data }) => {
        setThemeInfo(data)
        reset({
          deliveryTime: moment(data.deliveryTime, 'HH:mm').format('HH:mm'),
          adminDeliveryTime: moment(data.adminDeliveryTime, 'HH:mm').format('HH:mm'),
          priceType: data.activePrice.type,
          emergencyContentId: data.emergencyContentId,
        })
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <div>
      <PrimaryButton
        text="Update"
        disabled={!formState.isDirty || mutationUpdateTheme.isPending}
        customClass="buttonSaveNewThemeSettings"
        onClick={handleSubmit(mutationUpdateTheme.mutate)}
      />
      <div className="innerTopLeftButtons">
        <BackButton route={HISTORY_BACK} />
        <IconButton
          disabledTooltip={isLoading}
          buttonClassName="p-0 bg-transparent"
          renderTooltipContent={() => <p>Duplicate</p>}
          placement="bottomEnd"
          id="button-modal-duplicate"
          onClick={() => setShowModalDuplicate(true)}
        >
          <Copy size={23} fill="var(--catalina-blue)" />
        </IconButton>
        <IconButton
          disabledTooltip={isLoading}
          buttonClassName="p-0 bg-transparent"
          renderTooltipContent={() => <p>Contact</p>}
          placement="bottomEnd"
          id="button-modal-contact"
          onClick={() => setShowModalContact(true)}
        >
          <Contact size={25} fill="var(--catalina-blue)" />
        </IconButton>

        <IconButton
          disabledTooltip={isLoading}
          buttonClassName="p-0 bg-transparent"
          renderTooltipContent={() => <p>Table of Hardcoded messages</p>}
          placement="bottomEnd"
          id="buttom-modal-constant-msg"
          onClick={() => setShowHardcodeMessages(true)}
        >
          <MessageAltDetail size={28} fill="var(--catalina-blue)" />
        </IconButton>
        <IconButton
          disabledTooltip={isLoading}
          buttonClassName="p-0 bg-transparent"
          renderTooltipContent={() => <p>Statistics</p>}
          placement="bottomEnd"
          id="buttom-modal-constant-msg"
          onClick={navigateToThemeAnalytic}
        >
          <Analytics size={28} color="var(--catalina-blue)" />
        </IconButton>
      </div>

      <div className="settingsContainer">
        <section className="settingsBlock pt-2 pb-2">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div className="d-flex align-items-center gap-3 flex-grow-1">
              <Settings className="iconSettings" size={45} />
              <h2 className="titleNameTheme">{themeInfo?.name}</h2>
            </div>
            <Statistics fill="var(--catalina-blue)" width={200} />
          </div>
          <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
            <button
              className="themeIntegrationBtn"
              onClick={() => setShowModalHubspotIntegration(true)}
            >
              <img src="/images/hubspotIcon.webp" alt="Hubspot Integration" />
              <span>HubSpot Integration</span>
            </button>
          </div>
        </section>

        <section className="settingsBlock mt-3">
          <ActivateTheme {...themeInfo} updateThemeInfo={setThemeInfo} />
          <PricingModelInfo
            control={control}
            priceType={watch('priceType')}
            price={themeInfo?.activePrice?.price}
            updatePriceType={updatePriceType}
            extensionDays={themeInfo?.activePrice?.extensionDays}
          />
        </section>
        <section className="settingsBlock mt-3">
          <div className="d-flex align-items-end gap-3 justify-content-between mb-4">
            <div>
              <p className="themeSettingLabels">Message content delivery time</p>
              <p className="descriptionTimeSettings">
                Specifies when content messages are sent, optimizing for time zones and user
                preferences
              </p>
            </div>
            <ControllerField
              type={INPUT_TYPES.INPUT_TIME}
              control={control}
              rules={{ required: true }}
              defaultValue="21:00"
              name="deliveryTime"
              customClassName="deliveryTimeThemeSettings"
              customListClass="listDeliveryTimeThemeSettings"
            />
          </div>
          <div className="d-flex align-items-end gap-3 justify-content-between mb-4">
            <div>
              <p className="themeSettingLabels">Admin message content delivery time</p>
              <p className="descriptionTimeSettings">
                Specifies when admin messages are sent, optimizing for time zones and user
                preferences
              </p>
            </div>
            <ControllerField
              type={INPUT_TYPES.INPUT_TIME}
              control={control}
              rules={{ required: true }}
              defaultValue="21:00"
              name="adminDeliveryTime"
              customClassName="deliveryTimeThemeSettings"
              customListClass="listDeliveryTimeThemeSettings"
            />
          </div>
          <div className="d-flex align-items-end gap-3 justify-content-between">
            <div>
              <p className="themeSettingLabels">Emergency content</p>
              <p className="descriptionTimeSettings">
                Specifies content that is sent when a user sends the command "favorite" and they
                don't have any content to select from
              </p>
            </div>
            <ControllerField
              name="emergencyContentId"
              type={INPUT_TYPES.INPUT_DROPDOWN}
              control={control}
              rules={{ required: false }}
              defaultValue=""
              title="Emergency content"
              titleKey="name"
              valueKey="id"
              innerDropdown="innerSettingsInput"
              data={themeContents}
            />
          </div>
        </section>
        <ThemeTags />
        <Uploader
          onChange={uploadNewThemeMessages}
          draggable
          action=""
          disabledFileItem
          accept=".xlsx, .xls"
          autoUpload={false}
          className="position-relative cursorPointer mt-5"
          multiple={false}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}
          >
            <CloudUpload size={35} style={{ marginRight: 10 }} />
            <span>Upload SMS content</span>
          </div>
        </Uploader>
      </div>
      <ModalContact
        themeId={themeId}
        show={showModalContact}
        onHide={() => setShowModalContact(false)}
      />
      <ModalHardcodeMessage
        show={showHardcodeMessages}
        onHide={() => setShowHardcodeMessages(false)}
      />
      <ModalDuplicateTheme
        show={showModalDuplicate}
        onHide={() => setShowModalDuplicate(false)}
        {...themeInfo}
      />
      {isLoading && (
        <div className="loadingThemeSettings">
          <LoadingIcon />
        </div>
      )}
      <ModalHubspotIntegration
        themeId={themeId}
        show={showModalHubspotIntegration}
        onHide={() => setShowModalHubspotIntegration(false)}
      />
    </div>
  )
}

export default ThemeSettings
