import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Dropdown } from 'rsuite'

import {
  SUBSCRIPTABLE_TYPES,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_API_ACTIONS,
  ALL_SUBSCRIPTABLE_TYPES_CAN_CANCELLED,
  ALL_SUBSCRIPTION_STATUSES_CAN_CANCELLED,
} from '../../../../utils/constants'
import { manageThemeSubscription } from '../../../../api/user'
import { setShowModalCancelSubscription } from '../../../../redux/actions/ui'
import { ThreeDotsVertical } from '../../../SvgIcon'

const DropdownAction = ({
  name,
  status,
  themeId,
  details,
  subscriptableType,
  changeVisibleInfo,
  hideModalSubscription,
}) => {
  const dispatch = useDispatch()
  const [, setSearchParams] = useSearchParams()

  const isPaidContentActive = useMemo(
    () =>
      subscriptableType === SUBSCRIPTABLE_TYPES.PAID_CONTENTS_SUBSCRIPTION &&
      status === SUBSCRIPTION_STATUSES.ACTIVE,
    [subscriptableType, status]
  )

  const canCancelSubscription = useMemo(
    () =>
      ALL_SUBSCRIPTION_STATUSES_CAN_CANCELLED.includes(status) &&
      ALL_SUBSCRIPTABLE_TYPES_CAN_CANCELLED.includes(subscriptableType),
    [status, subscriptableType]
  )

  const pausePaidContentsSubscription = useCallback(() => {
    manageThemeSubscription(
      { themeId, action: SUBSCRIPTION_API_ACTIONS.PAUSE },
      { success: `${name} was paused` }
    ).then(({ data }) => changeVisibleInfo(data))
  }, [themeId, name, changeVisibleInfo])

  const handleCancelSubscription = () => {
    setSearchParams({
      cancelAt: details?.endSubscription,
      themeId,
      themeName: name,
      status,
      action: SUBSCRIPTION_API_ACTIONS.CANCEL,
    })
    dispatch(setShowModalCancelSubscription(true))
    hideModalSubscription()
  }

  if (!isPaidContentActive && !canCancelSubscription) {
    return null
  }

  return (
    <Dropdown
      className="threeDotsSubscription"
      renderToggle={(props, ref) => (
        <ThreeDotsVertical size={20} fill="#575757" ref={ref} {...props} />
      )}
      placement="bottomEnd"
    >
      {canCancelSubscription && (
        <Dropdown.Item onClick={handleCancelSubscription}>Cancel subscription</Dropdown.Item>
      )}
      {isPaidContentActive && (
        <Dropdown.Item onClick={pausePaidContentsSubscription}>Pause subscription</Dropdown.Item>
      )}
    </Dropdown>
  )
}

export default DropdownAction
