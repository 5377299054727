const AchievementsCrown = ({ size, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 435 417"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M92.2137 358.8H342.214C344.714 355.7 347.214 352.5 349.814 349.2C374.614 316.4 387.914 285.3 388.314 259.3C388.614 237.3 380.114 218.5 362.214 201.9C349.114 189.8 334.414 183.9 317.314 183.9C302.614 183.9 286.114 188.3 268.414 196.9L267.714 197.2C248.914 206 233.214 210.3 218.014 210.7H217.114H216.214C201.114 210.3 185.314 206 166.514 197.2L165.914 196.9C148.214 188.3 131.714 183.9 117.014 183.9C99.9137 183.9 85.2137 189.8 72.1137 201.9C54.2137 218.5 45.7137 237.2 46.0137 259.3C46.4137 285.3 59.7137 316.4 84.5137 349.2C87.2137 352.5 89.7137 355.7 92.2137 358.8Z"
        fill="#C11F3F"
      />
      <path
        d="M362.214 201.9C349.114 189.8 334.414 183.9 317.314 183.9C310.514 183.9 303.414 184.8 295.914 186.7C305.214 189.5 313.714 194.6 321.614 201.9C339.514 218.5 348.014 237.2 347.714 259.3C347.314 285.3 334.014 316.4 309.214 349.2C306.714 352.5 304.114 355.8 301.614 358.8H342.214C344.714 355.7 347.214 352.5 349.814 349.2C374.614 316.4 387.914 285.3 388.314 259.3C388.614 237.3 380.014 218.5 362.214 201.9Z"
        fill="#B50F37"
      />
      <path
        d="M395.214 139.1C358.814 106 311.014 102.3 256.814 128.3L256.514 128.4C253.714 129.8 250.114 128.8 246.814 127.1C240.614 123.9 236.114 118.3 233.814 111.8L217.214 64L200.614 111.7C198.314 118.3 193.714 123.9 187.514 127.1C184.214 128.8 180.614 129.8 177.814 128.4L177.614 128.3C123.414 102.3 75.5137 106.1 39.2137 139.1C12.7137 163.2 -0.486311 192.3 0.0136886 225.4C0.813689 279.7 39.6137 332.4 68.3137 364.1H123.414C120.314 361.4 100.314 343.6 80.6137 318.4C53.7137 284.1 39.3137 251.7 38.9137 224.8C38.6137 203 47.2137 184.4 65.3137 167.9C78.4137 156 93.2137 150.2 110.514 150.2H110.614C114.814 150.2 119.114 150.7 123.614 151.5C165.514 159.5 195.414 196.7 195.414 239.4V364H238.914V239.4C238.914 196.7 268.814 159.5 310.714 151.5C315.114 150.7 319.514 150.2 323.714 150.2H323.814C341.114 150.2 355.914 156 369.014 167.9C387.114 184.4 395.814 203 395.414 224.8C395.014 251.8 380.614 284.1 353.714 318.4C334.014 343.6 314.014 361.4 310.914 364.1H366.014C394.714 332.5 433.414 279.8 434.314 225.4C434.914 192.3 421.714 163.2 395.214 139.1Z"
        fill="#F7AD00"
      />
      <path
        d="M395.214 139.1C369.614 115.8 338.314 107.1 303.214 113C322.114 116.5 339.414 125.2 354.714 139.1C374.014 156.6 386.214 176.8 391.214 199.2C394.214 207.2 395.614 215.7 395.514 224.8C395.414 234.6 393.414 245 389.614 256C378.714 299 348.814 338.4 325.614 364.1H366.214C394.914 332.5 433.614 279.8 434.514 225.4C434.914 192.3 421.714 163.2 395.214 139.1Z"
        fill="#E09603"
      />
      <path
        d="M372.614 416.4C269.414 396.2 165.014 396.2 61.8137 416.4C51.3137 418.5 41.0137 411.8 38.6137 401.3C37.6137 396.8 36.5137 392.3 35.5137 387.7C33.0137 376.8 40.0137 365.9 51.0137 363.8C161.414 342.2 273.014 342.2 383.414 363.8C394.414 366 401.414 376.8 398.914 387.7C397.914 392.2 396.814 396.7 395.814 401.3C393.414 411.8 383.114 418.4 372.614 416.4Z"
        fill="#FF3701"
      />
      <path
        d="M399.414 384.7C399.414 384.2 399.414 383.8 399.414 383.3C399.414 383.2 399.414 383.1 399.414 383C399.414 382.6 399.414 382.1 399.314 381.7C399.314 381.2 399.214 380.8 399.114 380.4C399.114 380.3 399.114 380.2 399.114 380.1C399.014 379.2 398.714 378.3 398.514 377.5C398.514 377.4 398.514 377.4 398.414 377.3C396.314 370.6 390.714 365.3 383.314 363.8C375.914 362.4 368.614 361 361.214 359.8L356.714 381.7C354.914 390.4 346.614 396.2 337.814 394.8C246.014 380.9 153.414 382.9 61.8137 400.8C51.3137 402.9 41.0137 396.2 38.6137 385.8C37.9137 382.6 37.1137 379.3 36.4137 376.1C34.9137 379.8 34.6137 384 35.6137 388.2C36.6137 392.4 37.5137 396.5 38.5137 400.7C40.6137 411.6 51.1137 418.6 61.9137 416.5C157.714 397.8 254.514 396.4 350.414 412.5C354.014 413.1 357.614 413.7 361.114 414.4C361.314 414.4 361.514 414.5 361.614 414.5C365.314 415.2 369.014 415.9 372.714 416.6C383.214 418.7 393.414 412 395.814 401.5C396.814 397 397.914 392.5 398.914 387.9C399.014 387.4 399.114 387 399.214 386.5C399.214 386.4 399.214 386.3 399.314 386.2C399.314 385.6 399.314 385.2 399.414 384.7Z"
        fill="#E52D03"
      />
      <path
        d="M252.614 27.1L227.614 40.2L240.714 15.2C244.314 8.3 239.314 0 231.514 0H202.714C194.914 0 189.914 8.3 193.514 15.2L206.614 40.2L181.614 27.1C174.714 23.5 166.414 28.5 166.414 36.3V65.1C166.414 72.9 174.714 77.9 181.614 74.3L206.614 61.2L193.514 86.2C189.914 93.1 194.914 101.4 202.714 101.4H231.514C239.314 101.4 244.314 93.1 240.714 86.2L227.614 61.2L252.614 74.3C259.514 77.9 267.814 72.9 267.814 65.1V36.3C267.814 28.5 259.514 23.5 252.614 27.1Z"
        fill="#AB0069"
      />
      <path
        d="M252.614 27.1L227.614 40.2L240.714 15.2C244.314 8.3 239.314 0 231.514 0H227.214V101.3H231.514C239.314 101.3 244.314 93 240.714 86.1L227.614 61.1L252.614 74.2C259.514 77.8 267.814 72.8 267.814 65V36.2C267.814 28.5 259.514 23.5 252.614 27.1Z"
        fill="#990267"
      />
      <path
        d="M217.214 183.9C229.64 183.9 239.714 173.826 239.714 161.4C239.714 148.974 229.64 138.9 217.214 138.9C204.787 138.9 194.714 148.974 194.714 161.4C194.714 173.826 204.787 183.9 217.214 183.9Z"
        fill="#E1EFFB"
      />
    </svg>
  )
}

export default AchievementsCrown
