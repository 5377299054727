import { isEmpty } from 'lodash'

import { IconUsers } from '../../../../../components/SvgIcon'
import './styles.css'

const PreviewMessage = ({ message }) => {
  return (
    !isEmpty(message) && (
      <div className="innerPreview">
        <IconUsers fill="rgba(92, 93, 107, 0.8)" size={30} style={{ minWidth: 40 }} />
        <p className="previewMessage">{message}</p>
      </div>
    )
  )
}

export default PreviewMessage
