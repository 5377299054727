const Chats = () => {
  return (
    <div className="innerComingSoon">
      <img className="w-25 m-auto d-block" src="/images/smallDaydropLogo.png" alt="logo" />
      <p className="comingSoonText">Coming Soon</p>
      <p className="comingSoonSupText">
        We are currently working on chat with your mindfulness coach{' '}
      </p>
    </div>
  )
}

export default Chats
