const WarningAmber = ({ size, ...props }) => {
  return (
    <svg
      strokeWidth="0"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2z"></path>
      <path d="M13 16h-2v2h2zM13 10h-2v5h2z"></path>
    </svg>
  )
}

export default WarningAmber
