import { useNavigate } from 'react-router'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

const ModalError = ({ errorData }) => {
  const navigate = useNavigate()
  const { show, errorStatus, errorText } = errorData

  return (
    <Modal show={show} fullscreen>
      <div className="inner404">
        <p className="title404">{errorStatus}</p>
        <p className="supTitleNotFound">{errorText}</p>
        <button onClick={() => navigate(0)} className="buttonGoBack">
          Refresh
        </button>
      </div>
    </Modal>
  )
}

export default connect(({ ui }) => ({ errorData: ui.modalErrorData }))(ModalError)
