import { Table } from 'rsuite'
import Linkify from 'react-linkify'

import useMessages from '../../../utils/hooks/useMessages'

const MessageCell = ({ rowData, dataKey, contentCountKey, hasContent, ...props }) => {
  const { messagesCount } = useMessages(rowData?.[dataKey])

  return (
    <Table.Cell {...props}>
      <div className="innerMessageCell">
        <Linkify>{rowData?.[dataKey]}</Linkify>
        <div className="additionalMessageInfo">
          {hasContent && rowData?.[contentCountKey] ? (
            <span title="Number of content" className="contentCount">
              {rowData?.[contentCountKey]}
            </span>
          ) : null}
          <span>{messagesCount} SMS</span>
        </div>
      </div>
    </Table.Cell>
  )
}

export default MessageCell
