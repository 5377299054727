import { isEmpty } from 'lodash'

import ActivityCard from './activities'
import './styles.css'

const HistoryUserActivities = ({
  activities = [],
  isLoading = false,
  onClickPaginateBtn,
  showPaginationBtn = true,
}) => {
  if (isEmpty(activities) && !isLoading) {
    return null
  }

  return (
    <div>
      <div className="listHistoryUserActivities">
        {activities.map((item, index) => (
          <ActivityCard key={index} {...item} />
        ))}
      </div>
      {showPaginationBtn && (
        <button
          disabled={isLoading}
          onClick={onClickPaginateBtn}
          className="btnPaginateHistoryUserActivities"
        >
          {!isLoading ? 'Show previous' : 'Loading...'}
        </button>
      )}
    </div>
  )
}

export default HistoryUserActivities
