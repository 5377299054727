import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty, orderBy, sumBy } from 'lodash'

import { SURVEY_QUESTION_TYPES } from '../constants'
import {
  getSurveyQuestions,
  getSurveyQuestionStatistics,
  getSurveyQuestionUserAnswers,
} from '../../api/survey'
import { setLoadingAdminProjectData } from '../../redux/actions/ui'

const useSurveyAnalytics = (surveyId) => {
  const [answers, setAnswers] = useState([])
  const [questions, setQuestions] = useState([])
  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [userResponses, setUserResponses] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalCountAnswers, setTotalCountAnswers] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLoadingAdminProjectData(true))

    getSurveyQuestions(surveyId)
      .then(({ data }) => {
        const formattedQuestions = data.map((question) => ({ ...question, title: question.name }))

        setQuestions(formattedQuestions)
        setSelectedQuestion(formattedQuestions[0])
      })
      .finally(() => dispatch(setLoadingAdminProjectData(false)))
  }, [])

  useEffect(() => {
    if (!isEmpty(selectedQuestion)) {
      setSelectedPage(1)
      getSurveyQuestionStatistics(selectedQuestion.id).then(({ data }) => {
        const totalSumAnswer = sumBy(data, 'count')
        const answersWithPercentage = data.map((answer) => ({
          ...answer,
          percent: Math.ceil((answer.count / totalSumAnswer) * 100) || 0,
        }))

        const isSelectedScale = selectedQuestion.type === SURVEY_QUESTION_TYPES.RATING_SCALE
        setAnswers(
          isSelectedScale ? answersWithPercentage : orderBy(answersWithPercentage, 'percent')
        )
        setTotalCountAnswers(totalSumAnswer)
      })
    }
  }, [selectedQuestion])

  useEffect(() => {
    if (!isEmpty(selectedQuestion)) {
      getSurveyQuestionUserAnswers({
        questionId: selectedQuestion.id,
        size: 5,
        page: selectedPage,
      }).then(({ data }) => {
        const userResponses = data.data.map((item, index) => ({
          ...item,
          sequenceNumber: (selectedPage - 1) * 5 + index + 1,
        }))

        setPageCount(data.lastPage)
        setUserResponses(userResponses)
      })
    }
  }, [selectedPage, selectedQuestion])

  return {
    answers,
    questions,
    pageCount,
    userResponses,
    setSelectedPage,
    totalCountAnswers,
    selectedQuestion: {
      data: selectedQuestion,
      set: setSelectedQuestion,
    },
  }
}

export default useSurveyAnalytics
