import { createAction } from 'redux-actions'

const SET_USER = 'SET_USER'
const SET_ROLE = 'SET_ROLE'
const SET_NEW_TIMEZONE = 'SET_NEW_TIMEZONE'
const SET_NEW_DATA_USER = 'SET_NEW_DATA_USER'
const SET_NEW_PHONE_NUMBER = 'SET_NEW_PHONE_NUMBER'
const SET_NEW_STATUS = 'SET_NEW_SUBSCRIPTION_STATUS'
const SET_WEBSOCKET_CONNECTED = 'SET_WEBSOCKET_CONNECTED'
const SET_NEW_ENABLED_PROVIDERS = 'SET_NEW_ENABLED_PROVIDERS'
const TOGGLE_BLOCKING_STATUS = 'TOGGLE_MANUAL_BLOCKING_STATUS'
const SET_ADMIN_NUMBER_UNREAD_MESSAGES = 'SET_ADMIN_NUMBER_UNREAD_MESSAGES'

const setUser = createAction(SET_USER)
const setRoleUser = createAction(SET_ROLE)
const setNewStatus = createAction(SET_NEW_STATUS)
const setNewTimezone = createAction(SET_NEW_TIMEZONE)
const setNewDataUser = createAction(SET_NEW_DATA_USER)
const setNewPhoneNumber = createAction(SET_NEW_PHONE_NUMBER)
const setWebsocketConnected = createAction(SET_WEBSOCKET_CONNECTED)
const setNewEnabledProviders = createAction(SET_NEW_ENABLED_PROVIDERS)
const toggleManualBlockingStatus = createAction(TOGGLE_BLOCKING_STATUS)
const setAdminNumberUnreadMessages = createAction(SET_ADMIN_NUMBER_UNREAD_MESSAGES)

export {
  setUser,
  setRoleUser,
  setNewStatus,
  setNewTimezone,
  setNewDataUser,
  setNewPhoneNumber,
  setWebsocketConnected,
  setNewEnabledProviders,
  toggleManualBlockingStatus,
  setAdminNumberUnreadMessages,
}
