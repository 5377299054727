import { useRef } from 'react'
import { Controller } from 'react-hook-form'
import { isEmpty, isNil } from 'lodash'

import { WARNING_MESSAGE_VALIDATE_VARIABLES } from '../../../../../utils/constants'
import useMessages from '../../../../../utils/hooks/useMessages'
import MessageInput from '../../../../../components/MessageInput'

const ControllerMessageInput = ({
  name,
  control,
  clearErrors,
  messageConstants,
  changePreviewText,
  required = true,
  formState: { dirtyFields },
  ...rest
}) => {
  const tooltipRef = useRef(null)
  const { checkClosingVariables } = useMessages()

  const validateMessageBody = (value) => {
    if (isEmpty(value.trim()) && required) {
      return false
    }

    if (checkClosingVariables(value) && !isNil(dirtyFields.body)) {
      tooltipRef.current.open()
      tooltipRef.current.close(4000)

      return WARNING_MESSAGE_VALIDATE_VARIABLES
    }

    return true
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required, validate: validateMessageBody }}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MessageInput
          value={value}
          error={error}
          onChange={(message) => {
            clearErrors(name)
            tooltipRef.current.close()
            onChange(message)
          }}
          messageConstants={messageConstants}
          onChangePreviewText={changePreviewText}
          tooltipRef={tooltipRef}
          {...rest}
        />
      )}
    />
  )
}

export default ControllerMessageInput
