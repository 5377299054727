import Answer from '../../Answer'

const UserAnswerRow = ({ item }) => {
  const { sequenceNumber, firstName, lastName, answer, type } = item

  return (
    <tr className="rowUserOrder">
      <td className="bodyTableRow">{sequenceNumber}</td>
      <td className="bodyTableRow">
        {firstName} {lastName}
      </td>

      <td className="bodyTableRow text-wrap text-start">
        <Answer questionType={type} answer={answer} />
      </td>
    </tr>
  )
}

export default UserAnswerRow
