import { useMemo } from 'react'
import { Table } from 'rsuite'
import moment from 'moment-timezone'

const DateCell = ({ rowData, dataKey, dateFormat, classNameDate = '', ...props }) => {
  const momentDate = useMemo(() => moment(rowData?.[dataKey]), [rowData])
  const checkDate = useMemo(
    () => (momentDate.isValid() ? momentDate.format(dateFormat) : '-'),
    [momentDate]
  )

  return (
    <Table.Cell {...props}>
      <p className={classNameDate}>{checkDate}</p>
    </Table.Cell>
  )
}

export default DateCell
