import { useMemo } from 'react'
import moment from 'moment-timezone'

import useTime from '../../../utils/hooks/useTime'
import CalendarDay from './CalendarDay'

const TableCalendar = ({
  isAdmin,
  allMessages,
  currentMonth,
  onShowMessageList,
  onChangeSelectedDate,
}) => {
  const { getWeekDays, weekdayShort } = useTime()

  const weekDays = useMemo(
    () => getWeekDays(currentMonth.date.month(), currentMonth.date.year()),
    [currentMonth]
  )

  const onClickByDate = (selectedDate) => {
    onChangeSelectedDate(moment(selectedDate))
    onShowMessageList()
  }

  return (
    <table className="calendar">
      <caption className="titleCalendar">{currentMonth.date.format('MMM YYYY')}</caption>
      <thead className="headerCalendar">
        <tr className="listHeaderCalendar">
          {weekdayShort.map((week, index) => (
            <th key={index} className="itemHeaderCalendar">
              {week}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bodyCalendar">
        {weekDays.map(({ days }, rowIndex) => (
          <tr key={rowIndex} className="listBodyCalendar">
            {days.map((date, cellIndex) => (
              <CalendarDay
                date={date}
                key={cellIndex}
                isAdmin={isAdmin}
                rowIndex={rowIndex}
                cellIndex={cellIndex}
                onClick={onClickByDate}
                currentMonth={currentMonth}
                allMessages={allMessages}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TableCalendar
