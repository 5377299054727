import { reduce } from 'lodash'
import { handleActions } from 'redux-actions'

import { setReferences } from '../actions/references'

const initialState = {
  sources: [],
  subscriptionStatuses: { active: 'active', canceled: 'canceled', paused: 'paused' },
}

const mapSourcesType = (sources) => {
  return reduce(sources, (res, value) => [...res, { title: value }], [])
}

const mapActionsStatus = (actions) => {
  return reduce(actions, (res, value) => ({ ...res, [value.action]: value.subscriptionStatus }), {})
}

const actions = {
  [setReferences]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
      sources: mapSourcesType(payload?.utmSources),
      actionStatus: mapActionsStatus(payload?.actionTypes),
    }
  },
}

const createReducer = (data) => {
  const references = data ?? {}

  return handleActions(actions, {
    ...initialState,
    ...references,
    sources: mapSourcesType(references?.utmSources),
    actionStatus: mapActionsStatus(references?.actionTypes),
  })
}
export default createReducer
