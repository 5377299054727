import { Drawer, Table } from 'rsuite'
import { useQuery } from '@tanstack/react-query'

import { getTwilioStaticMessages } from '../../api/admin/messages'

const ModalHardcodeMessage = ({ show, onHide }) => {
  const { data: staticMessages, isLoading } = useQuery({
    queryKey: ['twilioStaticMessages'],
    queryFn: getTwilioStaticMessages,
    enabled: show,
    select: (data) => {
      return Object.values(data)
    },
  })

  return (
    <Drawer placement="right" size="full" open={show} onClose={onHide}>
      <Drawer.Header>
        <Drawer.Title>Hardcode Messages</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="p-4">
        <Table
          loading={isLoading}
          wordWrap="break-word"
          data={staticMessages}
          autoHeight
          id="table"
          rowClassName="cursorPointer"
        >
          <Table.Column verticalAlign="middle" width={270}>
            <Table.HeaderCell className="headColumn">Name</Table.HeaderCell>
            <Table.Cell dataKey="name" />
          </Table.Column>

          <Table.Column verticalAlign="middle" width={300}>
            <Table.HeaderCell className="headColumn">Description</Table.HeaderCell>
            <Table.Cell dataKey="rules" />
          </Table.Column>

          <Table.Column verticalAlign="middle" minWidth={400} flexGrow={1}>
            <Table.HeaderCell className="headColumn">Body Player</Table.HeaderCell>
            <Table.Cell dataKey="message" style={{ whiteSpace: 'pre-line' }} />
          </Table.Column>
        </Table>
      </Drawer.Body>
    </Drawer>
  )
}

export default ModalHardcodeMessage
