import { camelCase, isNil } from 'lodash'

import {
  CLOSING_VARIABLE_CHARS,
  OPENING_VARIABLE_CHARS,
  DEFAULT_MESSAGE_CONSTANTS,
} from '../../constants'
import { checkClosingVariables } from './checkClosingVariables'

const useMessages = (themeMessage = '', constants = []) => {
  const replaceVariables = (message, constants = DEFAULT_MESSAGE_CONSTANTS) => {
    return message?.replace(
      new RegExp(`${OPENING_VARIABLE_CHARS}(.+?)${CLOSING_VARIABLE_CHARS}`, 'g'),
      (match, variable) =>
        constants[camelCase(variable)] ??
        `${OPENING_VARIABLE_CHARS}${variable}${CLOSING_VARIABLE_CHARS}`
    )
  }

  const message = replaceVariables(themeMessage, constants)
  const maxMessageLength = message.length > 160 ? 153 : 160
  const messagesCount = Math.ceil(message.length / maxMessageLength)

  const formatMessageData = ({ arrayValues, allParams }) => {
    return arrayValues.reduce((res, { key, value, regex }) => {
      if (isNil(allParams[value]) || !regex.test(allParams[value])) {
        return res
      }

      return { ...res, [key]: allParams[value] }
    }, {})
  }

  return {
    message,
    messagesCount,
    maxMessageLength,
    messageLimit: maxMessageLength - (message.length % maxMessageLength || maxMessageLength),
    formatMessageData,
    replaceVariables,
    checkClosingVariables,
  }
}

export default useMessages
