import { isNil } from 'lodash/lang'

import { generateLinkToContent } from '../../api/admin/themeContent'

const useContent = ({ themeId }) => {
  const redirectToContentByContentId = (contentId) => {
    if (isNil(contentId)) {
      return
    }

    generateLinkToContent({ externalContentId: contentId, id: themeId }).then(
      ({ data: urlToContent }) => {
        window.open(urlToContent, '_blank')
      }
    )
  }

  return { redirectToContentByContentId }
}

export default useContent
