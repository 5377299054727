import { useCallback, useMemo } from 'react'
import moment from 'moment-timezone'
import { isNil, reverse } from 'lodash'

import { DATE_FORMAT_RANGE_PICKER, ISO_DATE_FORMAT } from '../../../utils/constants'
import { ArrowLeft, ArrowRight, Cross } from '../../SvgIcon'
import Message from './Message'

const ListMessages = ({ isShow, onHide, allMessages, selectedDate, onChangeSelectedDate }) => {
  const currentDate = useMemo(
    () => selectedDate?.format?.(DATE_FORMAT_RANGE_PICKER),
    [selectedDate]
  )

  const messageList = allMessages?.filter((value) => {
    const formattedDate = selectedDate?.format?.(ISO_DATE_FORMAT)

    return formattedDate === value.deliveryDate
  }, [])

  const moveByDates = useCallback(
    (isNextDate = true) => {
      const messages = isNextDate ? allMessages : reverse(allMessages)
      const momentMethod = isNextDate ? 'isAfter' : 'isBefore'

      const nearestMessageInfo = messages?.find(({ deliveryDate }) => {
        return moment(deliveryDate)[momentMethod](selectedDate?.format?.(ISO_DATE_FORMAT))
      })

      if (!isNil(nearestMessageInfo)) {
        onChangeSelectedDate(moment(nearestMessageInfo?.deliveryDate))
      }
    },
    [selectedDate]
  )

  return (
    <div className={`innerListMessages ${isShow ? 'openListMessages' : ''}`}>
      <div className="listMessages">
        <div className="headerListMessages">
          <ArrowLeft size={25} className="arrowMoveOnMessages" onClick={() => moveByDates(false)} />
          <p className="titleListMessages">{currentDate}</p>
          <ArrowRight className="arrowMoveOnMessages" onClick={moveByDates} size={25} />
          <Cross onClick={onHide} size={25} className="crossListMessages" />
        </div>
        <div className="innerMessages">
          {messageList?.map((item, index) => (
            <Message key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListMessages
