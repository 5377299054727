import r from '../../utils/request'

export const getAllTags = () => {
  return r.get('/admin/tags')
}

export const createTag = (params) => {
  return r.postWithToast('/admin/tags', params, {
    success: 'Tag created successfully',
    error: 'This tag already exist',
  })
}

export const updateTag = (params) => {
  return r.putWithToast(`/admin/tags/${params.id}`, params, {
    success: 'Tag updated successfully',
    error: 'This tag already exist',
  })
}

export const deleteTag = (id) => {
  return r.deleteWithToast(
    `/admin/tags/${id}`,
    {},
    { success: 'Tag has been successfully deleted' }
  )
}
