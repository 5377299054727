import r from '../utils/request'

export const getWeekStatistics = (params) => {
  return r.get('/admin/dashboard/week/analytics', params)
}

export const getContentAnalytic = (params) => {
  return r.get('/admin/dashboard/content/analytics', params)
}

export const getContentStatisticEachDates = (params) => {
  return r.get('/admin/dashboard/content/diagram', params)
}
