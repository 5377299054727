import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TABLE_HEADER_TAGS } from '../../../utils/constants'
import { createTag, deleteTag, getAllTags } from '../../../api/admin/tags'
import { setLoadingAdminProjectData } from '../../../redux/actions/ui'
import ManageList from '../../../components/ManageList'
import FormManageTag from './components/FormManageTag'
import RowTable from './components/RowTable'

import './styles.css'

const Tags = () => {
  const [dataTags, setDataTags] = useState([])
  const dispatch = useDispatch()

  const removeTag = ({ id: idDeleteTag }, setShowModalDelete) => {
    deleteTag(idDeleteTag)
      .then(() => setDataTags((oldState) => oldState.filter(({ id }) => id !== idDeleteTag)))
      .then(() => setShowModalDelete(false))
  }

  const makeNewTag = ({ tagInfo }, formRef) => {
    createTag(tagInfo)
      .then(({ data }) => setDataTags((oldState) => [...oldState, data]))
      .then(() => formRef.current?.reset())
  }

  useEffect(() => {
    dispatch(setLoadingAdminProjectData(true))
    getAllTags()
      .then(({ data }) => setDataTags(data))
      .finally(() => dispatch(setLoadingAdminProjectData(false)))
  }, [])

  return (
    <ManageList
      title="Tags"
      Row={RowTable}
      data={dataTags}
      setData={setDataTags}
      removeItem={removeTag}
      createItem={makeNewTag}
      emptyText="No tags found"
      FormManage={FormManageTag}
      tableHeader={TABLE_HEADER_TAGS}
      textConfirmDelete="You want delete this tag?"
    />
  )
}

export default Tags
