import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  ENABLED_SENTRY,
  SENTRY_URL_DSN,
  APP_ENVIRONMENT,
  ERROR_CODE_BAD_REQUEST,
  ERROR_CODE_SERVER_ERROR,
} from './utils/constants'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient()

Sentry.init({
  enabled: ENABLED_SENTRY,
  environment: APP_ENVIRONMENT,
  dsn: SENTRY_URL_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  beforeSend: (event, { originalException }) => {
    const errorStatus = originalException?.response?.status

    if (errorStatus >= ERROR_CODE_BAD_REQUEST && errorStatus < ERROR_CODE_SERVER_ERROR) {
      return null
    }
    return event
  },
})

root.render(
  <Sentry.ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
)
