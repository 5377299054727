import { useEffect, useReducer, useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { isEmpty } from 'lodash'

import {
  SENT_BY,
  WEBSOCKET_EVENTS,
  REPLACEMENT_PHONE,
  PHONE_PATTERN_REPLACE,
  WEBSOCKET_CONNECTION_CHANNEL,
  WEBSOCKET_STATES,
} from '../../../../../utils/constants'
import { FORM_FIELD_CHAT } from '../../../../../utils/constantsForms'
import { sendAdminMessage } from '../../../../../api/dialog'
import useChat from '../../../../../utils/hooks/useChat'
import useUserInfo from '../../../../../utils/hooks/useUserInfo'
import useWebSocket from '../../../../../utils/hooks/useWebSocket'
import { DoubleArrowLeft, LoadingIcon } from '../../../../../components/SvgIcon'
import ChatMessage from '../../../../../components/ChatMessage'
import FormSendMessage from '../FormSendMessage'

import './styles.css'

const ChatWindow = ({ userId, onHide }) => {
  const inputRef = useRef(null)
  const messagesListRef = useRef(null)
  const [, toggleRender] = useReducer((p) => !p, false)
  const { subscribeAdminChat } = useWebSocket()
  const webSocketSubscriptionChat = useRef(null)

  const { firstName, lastName, phone, hasBlockedTwilio, setUserInfo } = useUserInfo({ userId })
  const userFullName = `${firstName || ''} ${lastName || ''}`
  const userPhone = phone?.replace(PHONE_PATTERN_REPLACE, REPLACEMENT_PHONE)

  const {
    messages,
    changeInfoMessages,
    isLoading,
    oldestMessage,
    setLoadingNewMessages,
    setLoadingOldMessages,

    lastPageOldMessageRef,
    lastPageNewMessagesRef,
    currentPageOldMessagesRef,
    currentPageNewMessagesRef,

    firstItemIndex,
    indexUnreadMessage,
    fetchDialogMessages,
    handleAddUserMessage,
    handleAddAdminMessage,
  } = useChat({ userId })

  const onSendMessage = (data, formRef) => {
    inputRef.current.disabled = true

    sendAdminMessage({ users_ids: [userId], ...data })
      .then(() => {
        formRef.current.resetField(FORM_FIELD_CHAT.name, { defaultValue: '' })
      })
      .then(() => {
        if (
          lastPageNewMessagesRef.current === currentPageNewMessagesRef.current ||
          lastPageNewMessagesRef.current < 1
        ) {
          messagesListRef.current.scrollToIndex({
            index: messages.length - 1,
          })
        }
      })
      .finally(() => {
        inputRef.current.disabled = false
      })
  }

  const handleMessageAdd = (message) => {
    if (message.sentBy === SENT_BY.ADMIN) {
      return handleAddAdminMessage(message)
    }

    return handleAddUserMessage(message)
  }

  const onReadMessage = (externalMessageId) => {
    webSocketSubscriptionChat.current?.emit?.(WEBSOCKET_EVENTS.READ_MESSAGE, { externalMessageId })
    changeInfoMessages((oldState) =>
      oldState.map((item) =>
        item.externalMessageId === externalMessageId ? { ...item, isRead: true } : item
      )
    )
  }

  const subscribeToAdminChat = async () => {
    webSocketSubscriptionChat.current = await subscribeAdminChat({
      channel: `${WEBSOCKET_CONNECTION_CHANNEL.ADMIN_CHAT}:${userId}`,
      handlerReceivedMessage: handleMessageAdd,
      handlerChangeTwilioStatus: (twilioStatus) => {
        setUserInfo((oldState) => ({ ...oldState, hasBlockedTwilio: twilioStatus }))
      },
    })
  }

  useEffect(() => {
    if (!isEmpty(oldestMessage)) {
      fetchDialogMessages(
        true,
        currentPageOldMessagesRef.current,
        setLoadingOldMessages,
        lastPageOldMessageRef
      )
    }
  }, [currentPageOldMessagesRef.current, oldestMessage])

  useEffect(() => {
    if (!isEmpty(oldestMessage)) {
      fetchDialogMessages(
        false,
        currentPageNewMessagesRef.current,
        setLoadingNewMessages,
        lastPageNewMessagesRef
      )
    }
  }, [currentPageNewMessagesRef.current, oldestMessage])

  useEffect(() => {
    subscribeToAdminChat()

    return () => {
      if (webSocketSubscriptionChat.current?.state === WEBSOCKET_STATES.OPEN) {
        webSocketSubscriptionChat.current.close()
      }
    }
  }, [userId])

  useEffect(() => {
    inputRef.current.disabled = hasBlockedTwilio
  }, [hasBlockedTwilio])

  return (
    <div className="innerChatTab">
      <div className="chatHeaderSidebar">
        <div className="innerPreviewChat">
          <button onClick={onHide} className="innerBtnBack position-relative top-0">
            <DoubleArrowLeft size={20} className="m-0" />
          </button>
          <p className="userIcon userPreviewMessage">{userFullName?.trim()[0]}</p>
          <div className="infoPreviewChat">
            <p className="infoPreviewChatUserName">{userFullName}</p>
            <p className="infoPreviewChatLastMessage">{userPhone}</p>
          </div>
        </div>
      </div>
      {hasBlockedTwilio && <p className="blockedMessage">You've been blocked by the user</p>}
      <div className="globalWidowChat">
        {!isLoading ? (
          <Virtuoso
            data={messages}
            ref={messagesListRef}
            initialTopMostItemIndex={indexUnreadMessage}
            firstItemIndex={Math.max(0, firstItemIndex)}
            alignToBottom
            atTopThreshold={100}
            atBottomThreshold={100}
            itemContent={(_, rowData) => (
              <ChatMessage {...rowData} onRead={onReadMessage} userName={userFullName} />
            )}
            startReached={() => {
              if (lastPageOldMessageRef.current > currentPageOldMessagesRef.current) {
                currentPageOldMessagesRef.current += 1
                toggleRender()
              }
            }}
            endReached={() => {
              if (lastPageNewMessagesRef.current > currentPageNewMessagesRef.current) {
                currentPageNewMessagesRef.current += 1
                toggleRender()
              }
            }}
          />
        ) : (
          <LoadingIcon className="loadingChatMessages" />
        )}
      </div>
      <FormSendMessage onSubmit={onSendMessage} inputRef={inputRef} />
    </div>
  )
}

export default ChatWindow
