import { SWITCH_ITEMS_CONTENT_MESSAGE } from '../../../../../utils/constants'

const SwitchMessageType = ({ onSwitch, activeElement = '', show = false }) => {
  if (!show) {
    return null
  }

  return (
    <h2 className="selectedType">
      {SWITCH_ITEMS_CONTENT_MESSAGE.map(({ title, value }, index) => (
        <>
          {index !== 0 && ' / '}
          <span onClick={() => onSwitch(value)} className={activeElement === value ? 'active' : ''}>
            {title} date
          </span>
        </>
      ))}
    </h2>
  )
}

export default SwitchMessageType
