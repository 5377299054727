import { Link } from 'react-router-dom'

import { routes } from '../../../../router/routes'
import ProfileInfo from '../ProfileInfo'

import './styles.css'

const Header = () => {
  return (
    <header className="header containerUserLayout">
      <Link to={routes.userHome}>
        <img className="logoHeader" src="/images/positiveLogo.png" alt="logo" />
      </Link>
      <ProfileInfo />
    </header>
  )
}

export default Header
