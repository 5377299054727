import { Tooltip, Whisper } from 'rsuite'

import { Block } from '../../../../components/SvgIcon'
import CustomCheckbox from '../../../../components/CustomCheckbox'

const CheckBoxCell = ({ isBlocked, onChange, value }) => {
  if (!isBlocked) {
    return (
      <div style={{ lineHeight: '46px' }}>
        <CustomCheckbox
          onClick={(e) => e.stopPropagation()}
          inline
          value={value}
          onChange={onChange}
          className="me-0"
        />
      </div>
    )
  }
  return (
    <div style={{ lineHeight: '46px' }}>
      <Whisper
        placement="top"
        trigger="hover"
        speaker={
          <Tooltip>
            <p>Message sending forbidden</p>
          </Tooltip>
        }
      >
        <div>
          <Block size={20} fill="var(--persian-red)" />
        </div>
      </Whisper>
    </div>
  )
}

export default CheckBoxCell
