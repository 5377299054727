import { useCallback, useMemo, useRef, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { camelCase, debounce, isEmpty, omitBy, reduce, snakeCase, toNumber } from 'lodash'
import { Table } from 'rsuite'
import ReactPaginate from 'react-paginate'

import { SETTINGS_PAGINATION } from '../../../utils/constants'
import { FIELDS_FILTER_DASHBOARD } from '../../../utils/constantsForms'
import { getContentAnalytic } from '../../../api/content'
import { isNullOrEmpty } from '../../../utils/helpers'
import { Cross, IconFilter } from '../../../components/SvgIcon'
import { StarRatingCell, TextTooltipCell } from '../../../components/Table'
import CustomInput from '../../../components/CustomInput'
import IconButton from '../../../components/IconButton'
import ModalFilter from '../../../components/Modals/ModalFilter'

import './styles.css'

const ContentStatistics = () => {
  const [filterData, setFilterData] = useState({})
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [orderInfo, setOrderInfo] = useState({ orderBy: 'average_rating', orderDirection: 'desc' })
  const [selectedPage, setSelectedPage] = useState(1)
  const inputSearchRef = useRef(null)
  const appliedFilterCount = useMemo(() => Object.keys(filterData).length, [filterData])

  const handleOrderColumn = useCallback((sortColumn, sortType) => {
    setOrderInfo({ orderBy: snakeCase(sortColumn), orderDirection: sortType })
  }, [])
  const resetFilter = useCallback(() => {
    setFilterData({})
    setOrderInfo({})
    inputSearchRef.current.value = ''
  }, [])

  const handleUpdateFilter = useCallback((data) => {
    setSelectedPage(1)
    setShowModalFilter(false)
    setFilterData((oldState) => {
      const newFilterData = { ...oldState, ...data }
      return omitBy(newFilterData, isNullOrEmpty)
    })
  }, [])

  const { data: contentStatistic, isFetching: isFetchingContentStatistic } = useQuery({
    queryKey: ['contentAnalytic', orderInfo, selectedPage, filterData],
    queryFn: () =>
      getContentAnalytic({ ...orderInfo, size: 20, page: selectedPage, ...filterData }),
    placeholderData: keepPreviousData,
    select: ({ data }) => {
      const formattedData = data.data.map((item) => {
        const ratingList = reduce(
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, ...item.ratings },
          (acc, totalCount, starsCount) => [
            ...acc,
            { starsCount: toNumber(starsCount), totalCount },
          ],
          []
        )

        const totalDeliveredContent = Object.values(item?.contentActivities).reduce(
          (sum, value) => sum + value,
          0
        )

        return { ...item, ratingList, totalDeliveredContent }
      })

      return { ...data, data: formattedData }
    },
  })

  return (
    <div>
      <div>
        <h1 className="titleFilterUser">Content statistic</h1>
        <div className="d-flex align-items-center gap-3 position-relative mb-4">
          <CustomInput
            inputRef={inputSearchRef}
            placeholder="Search by content id, title"
            innerClassName="innerUserSearchInput"
            onChange={debounce(({ target }) => handleUpdateFilter({ search: target.value }), 500)}
          />
          <div className="d-flex align-items-center gap-3">
            <IconButton
              buttonClassName="p-0 bg-transparent rounded-0 position-relative overflow-visible"
              renderTooltipContent={() => <p>Show Filter</p>}
              placement="bottomEnd"
              onClick={() => setShowModalFilter(true)}
            >
              <IconFilter size={20} />
              {appliedFilterCount > 0 && (
                <span className="appliedFilterCount">{appliedFilterCount}</span>
              )}
            </IconButton>
            {appliedFilterCount > 0 || !isEmpty(orderInfo) ? (
              <IconButton
                buttonClassName="p-0 bg-transparent rounded-0 position-relative"
                renderTooltipContent={() => <p>Reset filter</p>}
                placement="bottomEnd"
                onClick={resetFilter}
              >
                <Cross size={25} fill="var(--persian-red)" />
              </IconButton>
            ) : null}
          </div>
        </div>
      </div>
      <Table
        loading={isFetchingContentStatistic}
        autoHeight
        wordWrap="break-word"
        data={contentStatistic?.data}
        onSortColumn={handleOrderColumn}
        sortColumn={camelCase(orderInfo.orderBy)}
        sortType={orderInfo.orderDirection}
        id="table-contents-statistics"
      >
        <Table.Column sortable verticalAlign="middle" width={70} align="center">
          <Table.HeaderCell className="headColumn">ID</Table.HeaderCell>
          <TextTooltipCell
            dataKey="externalId"
            tooltipList={[{ label: 'Title', key: 'subtitle' }]}
          />
        </Table.Column>
        <Table.Column verticalAlign="middle" flexGrow={1} minWidth={160}>
          <Table.HeaderCell className="headColumn">VO Artist</Table.HeaderCell>
          <Table.Cell dataKey="voiceArtist" />
        </Table.Column>
        <Table.Column align="center" sortable verticalAlign="middle" width={140}>
          <Table.HeaderCell className="headColumn">Avg. rating</Table.HeaderCell>
          <StarRatingCell dataKey="averageRating" ratingListKey="ratingList" />
        </Table.Column>
        <Table.Column align="center" sortable verticalAlign="middle" width={85}>
          <Table.HeaderCell className="headColumn">Liked</Table.HeaderCell>
          <Table.Cell dataKey="totalLikes" />
        </Table.Column>
        <Table.Column align="center" sortable verticalAlign="middle" width={100}>
          <Table.HeaderCell className="headColumn">Delivered</Table.HeaderCell>
          <TextTooltipCell
            dataKey="totalDeliveredContent"
            tooltipList={[
              { label: 'Unopened', key: 'contentActivities.unopenedContent' },
              { label: 'Opened', key: 'contentActivities.openedContent' },
              { label: 'Incomplete', key: 'contentActivities.startedContent' },
              { label: 'Finished', key: 'contentActivities.finishedContent' },
            ]}
          />
        </Table.Column>
        <Table.Column align="center" sortable verticalAlign="middle" width={150}>
          <Table.HeaderCell className="headColumn">Avg. progress</Table.HeaderCell>
          <TextTooltipCell
            dataKey="averageProgress"
            appendComponent={() => <span>%</span>}
            tooltipList={[
              { label: '0%-20%', key: 'progresses.020' },
              { label: '20%-40%', key: 'progresses.2040' },
              { label: '40%-60%', key: 'progresses.4060' },
              { label: '60%-80%', key: 'progresses.6080' },
              { label: '80%-100%', key: 'progresses.80100' },
            ]}
          />
        </Table.Column>
      </Table>
      <div className="d-flex align-items-center justify-content-between mt-e">
        <p className="totalResult">{contentStatistic?.total} Result</p>
        <ReactPaginate
          {...SETTINGS_PAGINATION}
          pageCount={contentStatistic?.lastPage}
          marginPagesDisplayed={-1}
          pageRangeDisplayed={-1}
          forcePage={selectedPage - 1}
          containerClassName="d-flex align-items-stretch gap-2"
          onPageChange={({ selected }) => setSelectedPage(selected + 1)}
        />
      </div>
      <ModalFilter
        show={showModalFilter}
        onReset={resetFilter}
        defaultValues={filterData}
        onHide={() => setShowModalFilter(false)}
        onSubmit={handleUpdateFilter}
        formFields={FIELDS_FILTER_DASHBOARD}
      />
    </div>
  )
}

export default ContentStatistics
