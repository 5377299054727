import { useEffect, useState } from 'react'
import Otp from 'react18-input-otp'
import { isEmpty } from 'lodash'

import './styles.css'

const OtpInput = ({
  value,
  onChange,
  error,
  label = '',
  innerClassName = '',
  maxCountAutoSubmit = 0,
  onSuccess = () => {},
  numInputs = 6,
  ...rest
}) => {
  const [countAutoSubmit, setCountAutoSubmit] = useState(0)

  useEffect(() => {
    if (countAutoSubmit < maxCountAutoSubmit && value.length === numInputs) {
      onSuccess()
      setCountAutoSubmit((oldState) => oldState + 1)
    }
  }, [value])

  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController()

      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then(({ code }) => onChange(code))
        .catch(console.error)
    }
  }, [])

  return (
    <div className={innerClassName}>
      {!isEmpty(label) && <label className="inputLabel">{label}</label>}
      <Otp
        numInputs={numInputs}
        containerStyle="innerInputsOtp"
        autoComplete="one-time-code"
        hasErrored={error}
        inputMode="numeric"
        placeholder="000000"
        errorStyle="errorInputOtp"
        inputStyle="otpInput"
        value={value}
        shouldAutoFocus
        onChange={onChange}
        {...rest}
      />
    </div>
  )
}

export default OtpInput
