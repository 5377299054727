import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Table } from 'rsuite'
import { camelCase, debounce, isEmpty, omitBy, snakeCase } from 'lodash'
import ReactPaginate from 'react-paginate'

import { DATE_FORMAT_MDY, SETTINGS_PAGINATION } from '../../../utils/constants'
import { deleteProgram, getPrograms } from '../../../api/admin/programs'
import { isNullOrEmpty } from '../../../utils/helpers'
import { Cross, MessageSquareEdit, Plus } from '../../../components/SvgIcon'
import { ActionCell, DateCell } from '../../../components/Table'
import ModalOnboardingMessage from '../../../components/Modals/ModalOnboardingMessage'
import ModalManageProgram from '../../../components/Modals/ModalManageProgram'
import ModalConfirm from '../../../components/Modals/ModalConfirm'
import PrimaryButton from '../../../components/PrimaryButton'
import CustomInput from '../../../components/CustomInput'
import IconButton from '../../../components/IconButton'
import ProgramInfoCell from './components/ProgramInfoCell'
import ThemesCell from './components/ThemesCell'

import './styles.css'

const Programs = () => {
  const [showModalOnboarding, setShowModalOnboarding] = useState(false)
  const [showModalManageProgram, setShowModalManageProgram] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [selectedPage, setSelectedPage] = useState(1)
  const [editableProgramInfo, setEditableProgramInfo] = useState(null)
  const [orderInfo, setOrderInfo] = useState({})
  const queryClient = useQueryClient()
  const navigation = useNavigate()

  const handleSearch = useCallback(
    debounce((value) => {
      setSearchValue(value)
      setSelectedPage(1)
    }, 500),
    []
  )

  const handleOrderColumn = useCallback((sortColumn, sortType) => {
    setOrderInfo({ orderBy: snakeCase(sortColumn), orderDirection: sortType })
  }, [])

  const mutationProgramDelete = useMutation({
    mutationKey: ['programDelete'],
    mutationFn: deleteProgram,
    onSuccess: () =>
      queryClient
        .invalidateQueries({ queryKey: ['programs'] })
        .then(() => setShowModalDelete(false)),
  })

  const { data: programs, isFetching } = useQuery({
    queryKey: ['programs', searchValue, orderInfo, selectedPage],
    queryFn: () =>
      getPrograms(
        omitBy({ size: 10, page: selectedPage, search: searchValue, ...orderInfo }, isNullOrEmpty)
      ),
    placeholderData: keepPreviousData,
  })

  return (
    <div>
      <div className="containerAdminMainInfo">
        <h1 className="titleAdminMainInfo">Programs</h1>
        <div className="innerDropDownFilter mb-4">
          <CustomInput
            placeholder="Search by program name"
            innerClassName="innerUserSearchInput"
            onChange={(e) => handleSearch(e.target.value)}
          />
          {!isEmpty(orderInfo) ? (
            <IconButton
              buttonClassName="p-0 bg-transparent rounded-0 position-relative"
              renderTooltipContent={() => <p>Reset filter</p>}
              placement="bottomEnd"
              onClick={() => {
                setOrderInfo(0)
                setSelectedPage(1)
              }}
            >
              <Cross size={25} fill="var(--persian-red)" />
            </IconButton>
          ) : null}
        </div>
        <div>
          <Table
            loading={isFetching}
            wordWrap="break-word"
            data={programs?.data?.data}
            id="table-programs"
            autoHeight
            rowClassName="cursorPointer"
            onSortColumn={handleOrderColumn}
            sortColumn={camelCase(orderInfo.orderBy)}
            sortType={orderInfo.orderDirection}
            onRowClick={({ id }) => navigation(`${id}`)}
          >
            <Table.Column verticalAlign="middle" width={300}>
              <Table.HeaderCell className="headColumn">Info</Table.HeaderCell>
              <ProgramInfoCell />
            </Table.Column>
            <Table.Column verticalAlign="middle" minWidth={300} flexGrow={1}>
              <Table.HeaderCell className="headColumn">Themes</Table.HeaderCell>
              <ThemesCell dataKey="themes" />
            </Table.Column>
            <Table.Column verticalAlign="middle" align="center" width={140}>
              <Table.HeaderCell className="headColumn">Subscriptions</Table.HeaderCell>
              <Table.Cell dataKey="subscriptionsCount" />
            </Table.Column>
            <Table.Column verticalAlign="middle" sortable align="center" width={170}>
              <Table.HeaderCell className="headColumn">Created at</Table.HeaderCell>
              <DateCell dataKey="createdAt" dateFormat={DATE_FORMAT_MDY} />
            </Table.Column>
            <Table.Column verticalAlign="middle" width={50}>
              <Table.HeaderCell className="headColumn"></Table.HeaderCell>
              <ActionCell
                actions={[
                  {
                    label: 'Edit',
                    key: 'edit',
                    function: (data) => {
                      setEditableProgramInfo(data)
                      setShowModalManageProgram(true)
                    },
                  },
                  {
                    label: 'Delete',
                    key: 'delete',
                    function: setShowModalDelete,
                  },
                ]}
              />
            </Table.Column>
          </Table>
          <div className="d-flex align-items-center justify-content-between mt-3 containerAdminMainInfo">
            <p className="totalResult">{programs?.data?.total} Result</p>
            <ReactPaginate
              {...SETTINGS_PAGINATION}
              pageCount={programs?.data?.lastPage}
              marginPagesDisplayed={-1}
              pageRangeDisplayed={-1}
              forcePage={selectedPage - 1}
              containerClassName="d-flex align-items-stretch gap-2"
              onPageChange={({ selected }) => setSelectedPage(selected + 1)}
            />
          </div>
        </div>

        <div className="innerAdditionalButtons">
          <PrimaryButton
            Icon={MessageSquareEdit}
            text="Onboarding"
            onClick={() => setShowModalOnboarding(true)}
          />
          <PrimaryButton
            Icon={Plus}
            text="Add Program"
            onClick={() => setShowModalManageProgram(true)}
          />
        </div>
        <ModalOnboardingMessage
          show={showModalOnboarding}
          onHide={() => setShowModalOnboarding(false)}
        />
        <ModalConfirm
          textButton="Delete"
          show={showModalDelete}
          onHide={() => setShowModalDelete(false)}
          handleClickButton={(data) => mutationProgramDelete.mutate(data)}
          text='Are you sure you want to delete this program? This action is irreversible. Confirm by clicking "Delete" or cancel to go back.'
        />
        <ModalManageProgram
          show={showModalManageProgram}
          selectedProgramInfo={editableProgramInfo}
          onExited={() => setEditableProgramInfo(null)}
          onHide={() => setShowModalManageProgram(false)}
        />
      </div>
    </div>
  )
}

export default Programs
