import { Sort, SortAmountDownAlt, SortAmountUpAlt } from './SvgIcon'

const IconArrowSort = ({ isSorted, sortType }) => {
  if (isSorted) {
    const IconSort = sortType === 'asc' ? SortAmountDownAlt : SortAmountUpAlt

    return <IconSort style={{ marginLeft: 5 }} size={17} fill="var(--catalina-blue)" />
  }

  return <Sort style={{ marginLeft: 5 }} size={17} fill="#666666" />
}

export default IconArrowSort
