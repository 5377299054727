import moment from 'moment-timezone'
import { DateRangePicker } from 'rsuite'

import { DATA_DROPDOWN_FEEDBACK_TYPES, DATE_FORMAT } from '../../../utils/constants'
import { checkDisabledDate, generateDateRangePlaceholder } from '../../../utils/helpers'
import CustomDropdown from '../../../components/CustomDropdown'

const FeedbackFilter = ({ onChangeFilter, minDate, selectedFeedbackType }) => {
  const rangePlaceholder = generateDateRangePlaceholder({ oldestEvent: minDate })

  return (
    <div className="filterFeedback">
      <CustomDropdown
        label="Feedback types"
        title="All types"
        valueKey="type"
        value={selectedFeedbackType}
        data={DATA_DROPDOWN_FEEDBACK_TYPES}
        onChange={(type) => onChangeFilter({ type })}
        innerDropdown="userDropDown"
      />
      <DateRangePicker
        cleanable={false}
        placement="bottomEnd"
        format={'dd MMM yyyy'}
        className="dateRangePicker"
        placeholder={rangePlaceholder}
        selected={moment(minDate)['_d']}
        onChange={(data) => {
          onChangeFilter({
            from: moment(data[0]).format(DATE_FORMAT),
            to: moment(data[1]).format(DATE_FORMAT),
          })
        }}
        disabledDate={(day) => checkDisabledDate({ date: day, minDate })}
      />
    </div>
  )
}

export default FeedbackFilter
