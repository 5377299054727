import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ROLES } from '../../constants'
import { FORM_LOGIN_ADMIN, OTP_FIELD } from '../../constantsForms'
import { routes } from '../../../router/routes'
import { useWebsocketContext } from '../../../components/SocketProvider'
import { confirmAdminLogin, loginAdmin } from '../../../api/auth'
import { updateToken } from '../../auth'
import { setRoleUser } from '../../../redux/actions/user'
import useWebSocket from '../useWebSocket'

const useAdminLogin = () => {
  const [isFirstStep, setIsFirstStep] = useState(true)
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { connect } = useWebSocket()
  const { handleWebSocketSubscription } = useWebsocketContext()

  const authAdmin = async ({ data }) => {
    await updateToken(data.token)
    await connect()
    await handleWebSocketSubscription()
    dispatch(setRoleUser(ROLES.ROLE_ADMIN))
  }

  const resendCode = () => {
    const params = formRef.current?.getValues()

    return loginAdmin(params).catch(() => {
      formRef.current?.setError('password', {})
    })
  }

  const handleAdminLoginSendCode = (data) => {
    return loginAdmin(data)
      .then(() => {
        formRef.current.update(0, { ...FORM_LOGIN_ADMIN[0], innerClassName: 'hidden' })
        formRef.current.update(1, { ...FORM_LOGIN_ADMIN[1], innerClassName: 'hidden' })
        formRef.current.append({
          ...OTP_FIELD,
          onSuccess: () => formRef.current?.handleSubmit(handleConfirmLogin)(),
        })

        setIsFirstStep(false)
      })
      .catch(() => {
        formRef.current?.setError('password', {})
      })
  }

  const handleConfirmLogin = (data) => {
    return confirmAdminLogin(data)
      .then(authAdmin)
      .then(() => navigate(routes.dashboard))
      .catch(() => {
        formRef.current?.setError('code')
      })
  }

  const handleAdminLogin = (data) => {
    if (isFirstStep) {
      return handleAdminLoginSendCode(data)
    }
    return handleConfirmLogin(data)
  }

  return {
    formRef,
    handleAdminLogin,
    buttonText: isFirstStep ? 'Login' : 'Confirm',
    resendCode,
    isFirstStep,
  }
}

export default useAdminLogin
