import React from 'react'

import { ArrowDown } from '../SvgIcon'
import './style.css'

const DropdownInput = ({ title, titleClass, error, disabled = false }) => {
  const classIsDisabled = disabled ? 'disabledDropdown' : ''
  const classIsError = error ? 'errorDropdown' : ''

  return (
    <div className={`dropHeader ${classIsDisabled} ${classIsError}`}>
      <p title={title} className={`dropdownTitle ${titleClass}`}>
        {title}
      </p>
      <div className="innerArrow">
        <ArrowDown size={20} fill="var(--inner-dropdown)" />
      </div>
    </div>
  )
}

export default DropdownInput
