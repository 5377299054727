import { createAction } from 'redux-actions'

const SET_LOADING_APP = 'SET_LOADING_APP'
const SWITCH_ADMIN_MENU = 'SWITCH_ADMIN_MENU'
const HIDE_ADMIN_MENU = 'HIDE_ADMIN_MENU'
const LOADING_ADMIN_PROJECT_DATA = 'LOADING_APP'
const SET_MODAL_ERROR_DATA = 'SET_MODAL_ERROR_DATA'
const SET_MODAL_SHARE_CONFIG = 'SET_MODAL_SHARE_CONFIG'
const SET_SHOW_MODAL_SUPPORT = 'SET_SHOW_MODAL_SUPPORT'
const SET_SHOW_MODAL_CANCEL_SUBSCRIPTION = 'SET_SHOW_MODAL_CANCEL_SUBSCRIPTION'

const setLoadingApp = createAction(SET_LOADING_APP)
const hideAdminMenu = createAction(HIDE_ADMIN_MENU)
const switchAdminMenu = createAction(SWITCH_ADMIN_MENU)
const setModalErrorData = createAction(SET_MODAL_ERROR_DATA)
const setModalShareConfig = createAction(SET_MODAL_SHARE_CONFIG)
const setShowModalSupport = createAction(SET_SHOW_MODAL_SUPPORT)
const setLoadingAdminProjectData = createAction(LOADING_ADMIN_PROJECT_DATA)
const setShowModalCancelSubscription = createAction(SET_SHOW_MODAL_CANCEL_SUBSCRIPTION)

export {
  setLoadingApp,
  hideAdminMenu,
  switchAdminMenu,
  setModalErrorData,
  setModalShareConfig,
  setShowModalSupport,
  setLoadingAdminProjectData,
  setShowModalCancelSubscription,
}
