import {
  ERROR_CODE_NOT_FOUND,
  ERROR_CODE_SERVER_ERROR,
  ERROR_CODE_THROTTLING,
  ERROR_CODE_VALIDATION,
} from '../../constants'

export const handleErrorLogin = ({ status }) => {
  switch (status) {
    case ERROR_CODE_THROTTLING:
      return 'Too many requests!'
    case ERROR_CODE_NOT_FOUND:
      return 'User not found!'
    case ERROR_CODE_VALIDATION:
      return 'Invalid phone number'
    default:
      return status >= ERROR_CODE_SERVER_ERROR ? 'Server error!' : 'Unexpected error'
  }
}
