import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Toggle, Tooltip, Whisper } from 'rsuite'

import { PIPELINE_TYPES, PRICE_TYPES } from '../../../../../utils/constants'
import { getMessagesCount } from '../../../../../api/admin/messages'
import { publishTheme, unpublishTheme } from '../../../../../api/admin/themes'
import { WarningAmber } from '../../../../../components/SvgIcon'

const ActivateTheme = ({ isPublished, updateThemeInfo, activePrice }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { themeId } = useParams()

  const handlePublishTheme = useCallback(() => {
    setIsLoading(true)

    const params = { id: themeId }
    publishTheme(params, { success: 'Theme activated' })
      .then(() => updateThemeInfo((oldState) => ({ ...oldState, isPublished: true })))
      .finally(() => setIsLoading(false))
  }, [themeId])

  const handleUnpublishedTheme = useCallback(() => {
    setIsLoading(true)

    unpublishTheme(themeId)
      .then(() => updateThemeInfo((oldState) => ({ ...oldState, isPublished: false })))
      .finally(() => setIsLoading(false))
  }, [themeId])

  const { data: countContentMessages } = useQuery({
    queryKey: ['countContentMessages', themeId],
    queryFn: () => getMessagesCount({ themeId, pipelineType: PIPELINE_TYPES.CONTENT }),
    enabled: activePrice?.type === PRICE_TYPES.ONE_TIME,
  })

  return (
    <div className="d-flex align-items-center justify-content-between">
      <p className="themeSettingLabels mb-0">Theme Status</p>
      <div>
        {activePrice?.type === PRICE_TYPES.ONE_TIME &&
        countContentMessages?.data < activePrice?.extensionDays ? (
          <Whisper
            placement="top"
            controlId={themeId}
            trigger="hover"
            speaker={
              <Tooltip>
                When you created this <b>one-time plan</b>, you specified that it would contain{' '}
                <b>{activePrice?.extensionDays}</b> messages. Currently, the number of messages in
                the application is <b>{countContentMessages?.data}</b>, which is less than you
                specified.
              </Tooltip>
            }
          >
            <div className="d-inline-block me-2">
              <WarningAmber
                className="jumpShakingAnimation"
                fill="var(--catalina-blue)"
                size={30}
              />
            </div>
          </Whisper>
        ) : null}
        <Toggle
          onChange={!isPublished ? handlePublishTheme : handleUnpublishedTheme}
          size="lg"
          loading={isLoading}
          checked={isPublished}
          checkedChildren="Activated"
          unCheckedChildren="Deactivated"
        />
      </div>
    </div>
  )
}

export default ActivateTheme
