import { Item, Menu, useContextMenu } from 'react-contexify'
import 'react-contexify/ReactContexify.css'

import './styles.css'

const ContextMenu = ({ children, menuList = [], dataItem, showContextMenu = true }) => {
  const { show } = useContextMenu({ ...dataItem })

  function handleContextMenu(event) {
    show({
      event,
    })
  }

  return (
    <div onContextMenu={handleContextMenu}>
      {children}
      {showContextMenu && (
        <Menu className="contextMenu" id={dataItem.id}>
          {menuList.map(({ name, Icon, onClick }, index) => (
            <Item
              key={index}
              className="itemContextMenu"
              onClick={({ event }) => {
                event.stopPropagation()
                onClick(dataItem)
              }}
            >
              <Icon size={17} fill="var(--catalina-blue)" />
              {name}
            </Item>
          ))}
        </Menu>
      )}
    </div>
  )
}

export default ContextMenu
