import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const DragRow = ({ onDrop, rowData, children, acceptDragKey }) => {
  const ref = useRef(null)
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: acceptDragKey,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      onDrop?.(item.id, rowData?.id)
    },
  }))

  const [{ isDragging }, drag] = useDrag(() => ({
    type: acceptDragKey,
    item: { id: rowData?.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))
  const isActive = canDrop && isOver

  drag(drop(ref))

  const styles = {
    opacity: isDragging ? 0.5 : 1,
    width: '100%',
    height: '100%',
    borderTop: isActive ? '2px solid var(--catalina-blue)' : null,
  }

  return (
    <div ref={ref} style={styles}>
      {children}
    </div>
  )
}

export default DragRow
