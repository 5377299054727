import { Cell, Pie, PieChart } from 'recharts'
import { isEmpty } from 'lodash/lang'

import { ANSWERS_COLORS } from '../../../../utils/constants'
import UserVotePercentageBar from '../../../../components/UserVotePercentageBar'

const AnswerAnalytics = ({ answers, selectedQuestion, totalCountAnswers }) => {
  if (isEmpty(answers)) {
    return (
      <img alt="Empty date" src="/images/emptyDataIllustration.jpg" className="emptyDataImage" />
    )
  }

  return (
    <div className="d-flex align-items-start justify-content-between mt-4">
      <div className="d-flex flex-column gap-3 flex-grow-1 innerVotePercentageBar">
        {answers.map((item, index) => (
          <UserVotePercentageBar
            key={item.id}
            questionType={selectedQuestion?.type}
            answer={item.answer}
            percent={item.percent}
            color={ANSWERS_COLORS[index]}
            {...item}
          />
        ))}
      </div>
      {totalCountAnswers > 0 && (
        <PieChart
          width={300}
          height={300}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
          }}
        >
          <Pie
            data={answers}
            isAnimationActive={false}
            innerRadius={120}
            outerRadius={150}
            dataKey="percent"
          >
            {answers.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={ANSWERS_COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      )}
    </div>
  )
}

export default AnswerAnalytics
