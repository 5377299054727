const StatusTab = ({ label, value, isActive, onClick, isVisible }) => {
  if (!isVisible) {
    return
  }

  return (
    <button
      key={label}
      onClick={() => onClick({ status: value })}
      className={`tab ${isActive ? 'active' : ''}`}
    >
      {label}
    </button>
  )
}

export default StatusTab
