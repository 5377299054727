import { useMemo } from 'react'
import { Popover, Whisper, Table } from 'rsuite'
import { formatPhoneNumber } from '../../../../utils/helpers'

const NameCell = ({ rowData, dataKey, ...props }) => {
  const formattedPhone = useMemo(() => formatPhoneNumber(rowData.phone), [rowData.phone])
  const speaker = (
    <Popover title="Info:">
      <p>
        <b>Phone:</b> {formattedPhone}
      </p>
    </Popover>
  )

  return (
    <Table.Cell {...props}>
      <Whisper enterable placement="top" speaker={speaker}>
        <a className="text-decoration-underline">{rowData[dataKey]}</a>
      </Whisper>
    </Table.Cell>
  )
}

export default NameCell
