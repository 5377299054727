import moment from 'moment-timezone'
import { isEmpty } from 'lodash'

import { DATE_FORMAT_MDY } from '../../../../utils/constants'
import { Block, IconStar, Info } from '../../../../components/SvgIcon'

const PreviewChat = ({ onSelectChat, customClassName = '', dataInfo = {} }) => {
  const {
    isStared,
    lastName,
    firstName,
    firstUnreadSms,
    needsAttention,
    hasBlockedTwilio,
    unreadMessagesCount,
    createdAt: registrationDate,
  } = dataInfo

  const userFullName = `${firstName || ''} ${lastName || ''}`
  const { createdAt, message } = firstUnreadSms ?? { createdAt: registrationDate }

  const formattedDate = moment(createdAt).isSame(moment(), 'day')
    ? moment(createdAt).format('h:mm A')
    : moment(createdAt).format(DATE_FORMAT_MDY)
  const previewMessage = !isEmpty(message) ? message : 'User joined'

  return (
    <div onClick={() => onSelectChat(dataInfo)} className={`innerPreviewChat ${customClassName}`}>
      <p className="userIcon userPreviewMessage">
        {userFullName?.trim()[0]}
        <div className="iconsInfo">
          {!isEmpty(isStared) && <IconStar title="Starred" size={20} fill="var(--catalina-blue)" />}
          {needsAttention && <Info title="Attention" size={20} fill="var(--catalina-blue)" />}
        </div>
      </p>
      <div className="infoPreviewChat">
        <div className="infoPreviewChatTop">
          <p className="infoPreviewChatUserName">{userFullName}</p>
          <p className="infoPreviewChatDateMessage">{formattedDate}</p>
        </div>

        <div className="innerPreviewLastMessage">
          <p className="infoPreviewChatLastMessage">{previewMessage}</p>
          {unreadMessagesCount >= 1 && (
            <span className="infoPreviewChatNumberUnreadMessages">{unreadMessagesCount}</span>
          )}
        </div>
      </div>
      {hasBlockedTwilio ? (
        <Block
          className="iconBlockMessages"
          size={20}
          fill="var(--persian-red)"
          title="The user has forbidden to send messages to them"
        />
      ) : null}
    </div>
  )
}

export default PreviewChat
