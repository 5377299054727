import { useEffect, useRef, useState } from 'react'
import { delay } from 'bluebird'

import { updateTag } from '../../../../api/admin/tags'
import { Bin, Pencil } from '../../../../components/SvgIcon'
import FormManageTag from './FormManageTag'

export const Tag = ({ name, color, className = '' }) => {
  return (
    <p className={`tag ${className}`} style={{ backgroundColor: color }}>
      {name}
    </p>
  )
}

const RowTable = ({ item, setShowModalDelete, setDataTags }) => {
  const [showChangeForm, setShowChangeForm] = useState(false)
  const [tagPreview, setTagPreview] = useState(item)
  const pencilRef = useRef()

  const updateTagInfo = ({ tagInfo }) => {
    updateTag(tagInfo)
      .then(() => {
        setDataTags((oldState) => oldState.map((item) => (item.id === tagInfo.id ? tagInfo : item)))
      })
      .then(() => setShowChangeForm(false))
  }

  const handleClickOutside = (e) => {
    const handleClickClosedForm =
      !pencilRef.current?.contains(e.target) && e.target.id === 'pencilId'

    handleClickClosedForm && delay(200).then(() => setShowChangeForm(false))
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <>
      <tr>
        <td>
          <Tag {...item} color={tagPreview.color} name={tagPreview.name || 'Preview'} />
        </td>
        <td className="text-center">{item?.themesCount}</td>
        <td className="cursorPointer text-center">
          {!showChangeForm && (
            <Pencil
              size={20}
              onClick={() => {
                setTagPreview(item)
                setShowChangeForm(!showChangeForm)
              }}
              pencilRef={pencilRef}
            />
          )}
        </td>
        <td className="cursorPointer text-end">
          <Bin
            onClick={() => setShowModalDelete({ id: item.id })}
            size={20}
            fill="var(--persian-red)"
          />
        </td>
      </tr>
      <tr>
        <td colSpan="4">
          <FormManageTag
            show={showChangeForm}
            value={item}
            setShow={setShowChangeForm}
            className="formChangeTag"
            showPreview={false}
            setTagPreview={setTagPreview}
            onSubmit={updateTagInfo}
            buttonText="Change"
          />
        </td>
      </tr>
    </>
  )
}

export default RowTable
