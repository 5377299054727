import { Table } from 'rsuite'

const PurchaserCell = ({ rowData, emailKey, ...props }) => {
  return (
    <Table.Cell {...props}>
      <div>
        <p className="tableGiftAnalyticPurchaserName">
          <b>Name: </b>
          {rowData.purchaserName || '-'}
        </p>
        <p className="tableGiftAnalyticPurchaserInfo">
          <b>Email: </b>
          {rowData[emailKey] || '-'}
        </p>
      </div>
    </Table.Cell>
  )
}

export default PurchaserCell
