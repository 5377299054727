import { useState } from 'react'
import { Modal } from 'rsuite'

import { buyGiftCoupon } from '../../../api/giftCoupons'
import GiftCardPreview from '../../GiftCardPreview'
import PrimaryButton from '../../PrimaryButton'
import CustomCheckbox from '../../CustomCheckbox'

import styles from './ModalConfirmPurchaseGiftCoupon.module.css'

const ModalConfirmPurchaseGiftCoupon = ({ show, onHide, giftCardInfo, priceInfo }) => {
  const [useDefaultCard, setUseDefaultCard] = useState(false)

  const handleConfirmPurchaseGift = () => {
    buyGiftCoupon({ ...giftCardInfo, withDefault: useDefaultCard })
      .then(({ data }) => {
        if (data?.withRedirect) {
          window.location.href = data?.url
          return
        }
        onHide()
      })
      .finally(() => setUseDefaultCard(false))
  }

  return (
    <Modal
      size="sm"
      overflow
      className={styles.confirmPurchaseGiftCouponDialog}
      onClose={onHide}
      open={show}
    >
      <GiftCardPreview
        {...giftCardInfo}
        priceInfo={priceInfo}
        additionalInfoList={[{ label: 'Deliver at', value: giftCardInfo.deliverAt }]}
        prependComponent={() => (
          <div className="mt-3">
            <CustomCheckbox
              onChange={() => setUseDefaultCard((oldState) => !oldState)}
              label="Use default card"
            />
            <PrimaryButton
              text="Confirm purchase"
              customClass={`submitButton ${styles.confirmButton}`}
              onClick={handleConfirmPurchaseGift}
            >
              Confirm purchase
            </PrimaryButton>
          </div>
        )}
      />
    </Modal>
  )
}

export default ModalConfirmPurchaseGiftCoupon
