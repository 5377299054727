import { useCallback, useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { camelCase, isEmpty, isNil, omitBy, snakeCase } from 'lodash'
import { Table } from 'rsuite'
import ReactPaginate from 'react-paginate'

import { DATE_FORMAT_MDY, HISTORY_BACK, SETTINGS_PAGINATION } from '../../../utils/constants'
import { FIELDS_FILTER_GIFT_ANALYTIC } from '../../../utils/constantsForms'
import { getCouponsStatistics } from '../../../api/giftCoupons'
import { Cross, IconFilter } from '../../../components/SvgIcon'
import { DateCell } from '../../../components/Table'
import BackButton from '../../../components/BackButton'
import IconButton from '../../../components/IconButton'
import CustomInput from '../../../components/CustomInput'
import GiftImage from '../../../components/GiftImage'
import ModalFilter from '../../../components/Modals/ModalFilter'
import PurchaserCell from './components/PurchaserCell'
import GiftPricingInfoCell from './components/GiftPricingInfoCell'

import './styles.css'

const GiftPricingAnalytics = () => {
  const [filterData, setFilterData] = useState({})
  const [orderFilterData, setOrderFilterData] = useState({})
  const [selectedPage, setSelectedPage] = useState(1)
  const [showModalFilter, setShowModalFilter] = useState(false)
  const appliedFilterCount = useMemo(() => Object.keys(filterData).length, [filterData])

  const handleUpdateFilter = useCallback((data) => {
    setSelectedPage(1)
    setShowModalFilter(false)
    setFilterData((oldState) => {
      const newFilterData = { ...oldState, ...data }
      return omitBy(
        newFilterData,
        (value) => isNil(value) || (isEmpty(value) && typeof value !== 'boolean')
      )
    })
  }, [])
  const handleOrderColumn = useCallback((sortColumn, sortType) => {
    setOrderFilterData({ orderBy: snakeCase(sortColumn), orderDirection: sortType })
  }, [])
  const resetFilter = useCallback(() => {
    setFilterData({})
    setOrderFilterData({})
    setShowModalFilter(false)
  }, [])

  const { data: { data: giftCoupons } = {}, isLoading } = useQuery({
    queryKey: ['usersCouponsPricingStatistics', selectedPage, filterData, orderFilterData],
    queryFn: () =>
      getCouponsStatistics({ page: selectedPage, size: 20, ...filterData, ...orderFilterData }),
    placeholderData: keepPreviousData,
  })

  return (
    <div>
      <div className="innerDashboard">
        <BackButton route={HISTORY_BACK} />
        <h1 className="mb-4">Gift analytics</h1>
        <div className="innerDropDownFilter mb-4">
          <CustomInput
            onChange={(e) => handleUpdateFilter({ searchBy: e.target.value })}
            value={filterData.searchBy ?? ''}
            placeholder="Search by purchaser, recipient, or gift details..."
            innerClassName="innerUserSearchInput"
          />
          <div className="d-flex align-items-center gap-3">
            <IconButton
              buttonClassName="p-0 bg-transparent rounded-0 position-relative overflow-visible"
              renderTooltipContent={() => <p>Show Filter</p>}
              placement="bottomEnd"
              onClick={() => setShowModalFilter(true)}
            >
              <IconFilter size={20} />
              {appliedFilterCount > 0 && (
                <span className="appliedFilterCount">{appliedFilterCount}</span>
              )}
            </IconButton>
            {appliedFilterCount > 0 || !isEmpty(orderFilterData) ? (
              <IconButton
                buttonClassName="p-0 bg-transparent rounded-0 position-relative"
                renderTooltipContent={() => <p>Reset filter</p>}
                placement="bottomEnd"
                onClick={resetFilter}
              >
                <Cross size={25} fill="var(--persian-red)" />
              </IconButton>
            ) : null}
          </div>
        </div>
        <Table
          autoHeight
          wordWrap="break-word"
          id="tableGiftAnalytic"
          data={giftCoupons?.data}
          loading={isLoading}
          onSortColumn={handleOrderColumn}
          sortColumn={camelCase(orderFilterData.orderBy)}
          sortType={orderFilterData.orderDirection}
        >
          <Table.Column verticalAlign="center" width={60} align="center">
            <Table.HeaderCell className="headColumn">#</Table.HeaderCell>
            <Table.Cell>
              {({ pricingImage, redeemedAt }) => (
                <GiftImage alt={redeemedAt} imgSrc={pricingImage} className="giftImgItem" />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column verticalAlign="center" width={250} fullText align="left">
            <Table.HeaderCell className="headColumn">Gift info</Table.HeaderCell>
            <GiftPricingInfoCell />
          </Table.Column>
          <Table.Column verticalAlign="center" width={300} align="left">
            <Table.HeaderCell className="headColumn">Purchaser</Table.HeaderCell>
            <PurchaserCell emailKey="purchaserEmail" />
          </Table.Column>
          <Table.Column verticalAlign="center" minWidth={300} flexGrow={1} align="left">
            <Table.HeaderCell className="headColumn">Receiver</Table.HeaderCell>
            <PurchaserCell emailKey="receiverEmail" />
          </Table.Column>
          <Table.Column sortable verticalAlign="center" width={130} align="center">
            <Table.HeaderCell className="headColumn">Bought at</Table.HeaderCell>
            <DateCell dataKey="purchasedAt" dateFormat={DATE_FORMAT_MDY} />
          </Table.Column>
          <Table.Column sortable verticalAlign="center" width={130} align="center">
            <Table.HeaderCell className="headColumn">Activated at</Table.HeaderCell>
            <DateCell dataKey="redeemedAt" dateFormat={DATE_FORMAT_MDY} />
          </Table.Column>
        </Table>

        <div className="d-flex align-items-center justify-content-between mt-3">
          <p className="totalResult">{giftCoupons?.total} Result</p>
          <ReactPaginate
            {...SETTINGS_PAGINATION}
            pageCount={giftCoupons?.lastPage}
            marginPagesDisplayed={-1}
            pageRangeDisplayed={-1}
            containerClassName="d-flex align-items-stretch gap-2"
            onPageChange={({ selected }) => setSelectedPage(selected)}
          />
        </div>
      </div>
      <ModalFilter
        show={showModalFilter}
        onReset={resetFilter}
        defaultValues={filterData}
        onSubmit={handleUpdateFilter}
        formFields={FIELDS_FILTER_GIFT_ANALYTIC}
        onHide={() => setShowModalFilter(false)}
      />
    </div>
  )
}

export default GiftPricingAnalytics
