import { isNil } from 'lodash'

import { USER_RECENT_ACTIVITIES_TYPES } from '../../../utils/constants'
import Signup from './Signup'
import LoggedIn from './LoggedIn'
import ExpiredPromotionCode from './ExpiredPromotionCode'
import ActivateAccount from './ActivateAccount'
import AppliedGiftCoupon from './AppliedGiftCoupon'
import EnteredBillingInfo from './EnteredBillingInfo'
import FailedPayment from './FailedPayment'
import AppliedPromotionCode from './AppliedPromotionCode'
import SuccessfulPayment from './SuccessfulPayment'
import CreatedSubscription from './CreatedSubscription'
import SubscriptionUpdate from './SubscriptionUpdate'
import CancelledAccount from './CancelledAccount'
import SubscribingStarted from './SubscribingStarted'

const ACTIVITY_COMPONENT = {
  [USER_RECENT_ACTIVITIES_TYPES.SIGNUP]: Signup,
  [USER_RECENT_ACTIVITIES_TYPES.LOGGED_IN]: LoggedIn,
  [USER_RECENT_ACTIVITIES_TYPES.SUBSCRIPTION_CREATED]: CreatedSubscription,
  [USER_RECENT_ACTIVITIES_TYPES.ENTERED_BILLING_INFO]: EnteredBillingInfo,
  [USER_RECENT_ACTIVITIES_TYPES.APPLIED_PROMOTION_COUPON]: AppliedPromotionCode,
  [USER_RECENT_ACTIVITIES_TYPES.ACTIVATE_ACCOUNT]: ActivateAccount,
  [USER_RECENT_ACTIVITIES_TYPES.EXPIRED_PROMOTION_CODE]: ExpiredPromotionCode,
  [USER_RECENT_ACTIVITIES_TYPES.ACCOUNT_CANCELLED]: CancelledAccount,
  [USER_RECENT_ACTIVITIES_TYPES.STARTED_SUBSCRIBING]: SubscribingStarted,
  [USER_RECENT_ACTIVITIES_TYPES.APPLIED_GIFT_COUPON]: AppliedGiftCoupon,
  [USER_RECENT_ACTIVITIES_TYPES.SUCCESSFUL_PAYMENT]: SuccessfulPayment,
  [USER_RECENT_ACTIVITIES_TYPES.FAILED_PAYMENT]: FailedPayment,
  [USER_RECENT_ACTIVITIES_TYPES.SUBSCRIPTION_STATUS_UPDATED]: SubscriptionUpdate,
}

const ActivityCard = ({ type = null, ...restActivityProps }) => {
  const Component = ACTIVITY_COMPONENT[type]

  if (isNil(type) || isNil(Component)) return null

  return <Component {...restActivityProps} />
}

export default ActivityCard
