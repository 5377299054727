import { SURVEY_QUESTION_TYPES } from '../../utils/constants'
import StarRating from '../StarRating'

import './styles.css'

const UserVotePercentageBar = ({ percent, answer, color, questionType }) => {
  const renderAnswer = () => {
    if (questionType === SURVEY_QUESTION_TYPES.RATING_SCALE) {
      return (
        <StarRating containerStyles="questionAnswer" countStars={answer} disabled value={answer} />
      )
    }

    return <p className="questionAnswer">{answer}</p>
  }

  return (
    <div className="innerUserVotePercentageBar">
      <div
        style={{ width: `${percent}%`, backgroundColor: color }}
        className="answerProgressFill"
      />
      <div className="d-flex align-items-center gap-3 ps-3">
        <p className="percentVoteAnswer">{percent}%</p>
        {renderAnswer()}
      </div>
    </div>
  )
}

export default UserVotePercentageBar
