import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'

import './styles.css'

const TooltipContentHistory = ({ active, payload }) => {
  const { fill, deliveryDate, labelStatus, externalId, contentValue } = useMemo(
    () => payload[0]?.payload ?? {},
    [payload]
  )

  if (!active || isEmpty(contentValue)) {
    return null
  }
  const formattedDate = moment(deliveryDate).format('ll')

  return (
    <div className="customTooltipContentHistory">
      <p className="mb-2">{formattedDate}</p>
      <p className="title">
        {externalId} | {contentValue.name}
      </p>
      <span className="statusContentHistory">
        Status:{' '}
        <span className="contentStatus" style={{ background: fill }}>
          {labelStatus}
        </span>
      </span>
    </div>
  )
}

export default TooltipContentHistory
