import { useCallback, useMemo } from 'react'
import { isEmpty, isNil } from 'lodash'
import { DateRangePicker } from 'rsuite'
import moment from 'moment-timezone'

import './styles.css'

const DateTimeRangePicker = ({ value, inputRef, onChange, label, pickerProps, valueFormat }) => {
  const formattedPickerValue = useMemo(() => value.map((date) => moment(date).toDate()), [value])

  const onSelectRange = useCallback(
    (dates) => {
      const value = isNil(dates) ? [] : dates.map((date) => moment(date).format(valueFormat))

      onChange(value)
    },
    [value]
  )

  return (
    <div>
      {!isEmpty(label) && <label className="inputLabel">{label}</label>}
      <DateRangePicker
        value={formattedPickerValue}
        ref={inputRef}
        className="dateTimeRangePicker"
        onChange={onSelectRange}
        {...pickerProps}
        shouldDisableDate={pickerProps?.shouldDisableDate?.(DateRangePicker)}
      />
    </div>
  )
}

export default DateTimeRangePicker
