import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Tooltip, Whisper } from 'rsuite'
import { connect, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import Copy from '../../SvgIcon/components/Copy'
import { setModalShareConfig } from '../../../redux/actions/ui'
import useGiftMessages from '../../../utils/hooks/useGiftMessages'
import { camelize } from '../../../utils/helpers'

import './styles.css'

const ModalShare = ({ modalShareConfig }) => {
  const { show, title, additionalText, shareMessage = '' } = modalShareConfig

  const dispatch = useDispatch()
  const { getGiftMessageInfo } = useGiftMessages()
  const [searchParams, setSearchParams] = useSearchParams()
  const { giftCouponMessageId } = camelize(Object.fromEntries(searchParams))

  const showGiftSuccessModal = async () => {
    if (giftCouponMessageId) {
      const modalShareData = await getGiftMessageInfo(giftCouponMessageId)
      dispatch(setModalShareConfig(modalShareData))
    }
  }

  useEffect(() => {
    showGiftSuccessModal()
  }, [giftCouponMessageId])

  return (
    <Modal
      className="modalShare"
      centered
      show={show}
      onHide={() => {
        setSearchParams({})
        dispatch(setModalShareConfig({ show: false }))
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modalShareAdditionalText">{additionalText}</p>
        <div className="previewShareMessage">
          <Whisper
            delayClose={1000}
            speaker={<Tooltip>Copied</Tooltip>}
            trigger="click"
            placement="top"
          >
            <button
              className="buttonCopyShareText"
              onClick={() => navigator?.clipboard?.writeText(shareMessage)}
            >
              <Copy size={20} fill="var(--catalina-blue)" />
            </button>
          </Whisper>
          {shareMessage}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default connect(({ ui }) => ({
  modalShareConfig: ui.modalShareConfig,
}))(ModalShare)
