import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { INPUT_TYPES, PRICE_TYPES, THEME_SETTINGS_PRICE_TYPES } from '../../../utils/constants'
import { createTheme } from '../../../api/admin/themes'
import PrimaryButton from '../../../components/PrimaryButton'
import ControllerField from '../../../components/HookForm/components/ControllerField'

import styles from './CreateTheme.module.css'

const CreateTheme = () => {
  const { programId } = useParams()
  const navigation = useNavigate()
  const { control, handleSubmit, watch, unregister, register, setValue } = useForm({
    defaultValues: { priceType: PRICE_TYPES.RECURRING },
  })

  const mutationCreateTheme = useMutation({
    mutationKey: ['createProgramTheme', programId],
    mutationFn: (params) => createTheme({ ...params, programId }),
    onSuccess: ({ data }) => navigation(`/admin/programs/${programId}/${data?.id}`),
  })
  const handlePriceTypeChange = useCallback(
    ({ target: { value } }) => {
      setValue('priceType', value)

      if (value === PRICE_TYPES.RECURRING) {
        unregister('priceExtensionDays')
      } else {
        register('priceExtensionDays')
      }
    },
    [register, unregister, setValue]
  )

  return (
    <div>
      <div className="innerDashboard">
        <h1 className="titleFilterUser mb-3">New Theme</h1>
        <form onSubmit={handleSubmit(mutationCreateTheme.mutate)}>
          <div className={styles.innerFormSection}>
            <ControllerField
              control={control}
              name="name"
              innerClassName="inputVcf mb-4 mt-2"
              defaultValue=""
              placeholder="Theme name"
              rules={{
                required: true,
              }}
            />
            <div className="d-flex align-items-end gap-3 justify-content-between mb-4">
              <div>
                <p className="themeSettingLabels">Message content delivery time</p>
                <p className="descriptionTimeSettings">
                  Specifies when content messages are sent, optimizing for time zones and user
                  preferences
                </p>
              </div>
              <ControllerField
                name="deliveryTime"
                control={control}
                type={INPUT_TYPES.INPUT_TIME}
                rules={{ required: true }}
                defaultValue="21:00"
                customClassName="deliveryTimeThemeSettings"
                customListClass="listDeliveryTimeThemeSettings"
              />
            </div>
            <div className="d-flex align-items-end justify-content-between gap-3">
              <div>
                <p className="themeSettingLabels">Admin message content delivery time</p>
                <p className="descriptionTimeSettings">
                  Specifies when admin messages are sent, optimizing for time zones and user
                  preferences
                </p>
              </div>
              <ControllerField
                name="adminDeliveryTime"
                control={control}
                type={INPUT_TYPES.INPUT_TIME}
                rules={{ required: true }}
                defaultValue="21:00"
                customClassName="deliveryTimeThemeSettings"
                customListClass="listDeliveryTimeThemeSettings"
              />
            </div>
          </div>
          <div className={styles.innerFormSection}>
            <p className={`${styles.titleFormSection} mb-2`}>Pricing Info</p>
            <p className="mb-4">
              In this section, you can choose a payment plan for users or products. There are two
              types of plans: <b>One-Time Payment</b>, in which the user makes a one-time payment at
              the time of registration or purchase, and <b>Recurring Payment</b>, which involves
              automatic billing on a regular basis (monthly or annually).
            </p>
            <ControllerField
              control={control}
              name="priceType"
              type={INPUT_TYPES.RADIO_BUTTON}
              onChange={handlePriceTypeChange}
              defaultValue={PRICE_TYPES.RECURRING}
              buttons={THEME_SETTINGS_PRICE_TYPES}
            />
            <div className="d-flex align-items-end gap-3 mt-3">
              <ControllerField
                name="priceAmount"
                control={control}
                rules={{ required: true, validate: (value) => value > 0 }}
                defaultValue=""
                type={INPUT_TYPES.INPUT_MASK}
                isNumberMask
                placeholder="0.00"
                innerClassName="innerPriceInput"
                label="Total price"
                showIconInfo
                infoText='The subscription price is entered in this field. If you choose "One-Time Payment", the specified amount will be charged only once after successful registration. If you choose "Recurring Payment", the specified amount will be automatically charged monthly or annually, depending on the plan you choose.'
                defaultMaskOptions={{
                  prefix: '',
                  suffix: '',
                  includeThousandsSeparator: true,
                  thousandsSeparatorSymbol: ' ',
                  allowDecimal: true,
                  decimalSymbol: '.',
                  decimalLimit: 2,
                  integerLimit: 7,
                  allowNegative: false,
                  allowLeadingZeroes: false,
                }}
              />
              <ControllerField
                name="priceExtensionDays"
                control={control}
                rules={{ required: true, validate: (value) => value > 0 }}
                defaultValue=""
                placeholder=""
                label="Message count"
                inputType="number"
                showIconInfo
                hideControllerField={watch('priceType') === PRICE_TYPES.RECURRING}
                infoText="In this field, you can enter the predicted number of messages that will be sent in the program. This number determines the number of days during which the user will be able to use the program. Enter the expected number of messages to determine the duration of access."
              />
            </div>
          </div>

          <PrimaryButton
            disabled={mutationCreateTheme.isPending}
            text="Save info"
            customClass={styles.submitButtonCreateTheme}
          />
        </form>
      </div>
    </div>
  )
}

export default CreateTheme
