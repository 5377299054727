import * as Sentry from '@sentry/react'

import { routes } from '../router/routes'
import { setModalErrorData } from '../redux/actions/ui'

import {
  ERROR_EXPIRED_TOKEN,
  ERROR_INVALID_TOKEN,
  INFO_NETWORK_ERROR,
  ERROR_NETWORK,
  IS_STAGING,
} from './constants'
import { clearSession } from './auth'

const errorHandler = async (e, navigate, dispatch) => {
  const { data, status = 0 } = e?.response ?? {}
  const errorStatus = parseInt(status)

  if (e.message === ERROR_NETWORK && data === undefined) {
    dispatch(setModalErrorData(INFO_NETWORK_ERROR))
    throw e
  }

  if (errorStatus >= 500) {
    Sentry.captureException(e)
    dispatch(
      setModalErrorData({
        errorStatus,
        errorText: IS_STAGING ? data : 'Server error',
        show: true,
      })
    )
    console.error(`\u001b[31m\n\nServer Error: ${e.message} \n ${JSON.stringify(data, null, 2)}`)
    throw e
  }

  if (errorStatus === 401 && (data === ERROR_EXPIRED_TOKEN || data === ERROR_INVALID_TOKEN)) {
    const baseRoutes = [routes.adminLogin, routes.home, routes.userLogin]
    await clearSession()

    const currentPath = window.location.pathname

    if (!baseRoutes.includes(currentPath)) {
      const isAdminPage = currentPath.includes('/admin')
      const redirectRoute = isAdminPage ? routes.adminLogin : routes.userLogin
      navigate(redirectRoute)
    }
    throw e
  }
  throw e
}

export default errorHandler
