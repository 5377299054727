import { useMemo } from 'react'

import { STRIPE_PRODUCTS_TYPES } from '../../../utils/constants'
import { CurrencyDollar } from '../../SvgIcon'

const TITLES = {
  [STRIPE_PRODUCTS_TYPES.ONE_TIME_SUBSCRIPTION]: ({ themeName }) => (
    <>
      Successfully purchased theme "<b className="firstLetterUppercase">{themeName}</b>"
    </>
  ),
  [STRIPE_PRODUCTS_TYPES.SUBSCRIPTION]: ({ themeName }) => (
    <>
      Successful subscription payment for theme "<b className="firstLetterUppercase">{themeName}</b>
      "
    </>
  ),
  [STRIPE_PRODUCTS_TYPES.GIFT_COUPON]: () => 'Gift Coupon Purchased',
  [STRIPE_PRODUCTS_TYPES.OTHER]: () => 'Successful purchase',
}

const SuccessfulPayment = ({ eventTime, extra, themeName }) => {
  const formattedPrice = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: extra?.totalPaid % 1 === 0 ? 0 : 2,
      }).format(extra?.totalPaid),
    [extra?.totalPaid]
  )

  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <CurrencyDollar size={23} fill="var(--catalina-blue)" />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">
          {TITLES[extra?.type]({ themeName })}. Price: <b>{formattedPrice}</b>
        </p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default SuccessfulPayment
