import { CouponExpired } from '../../SvgIcon'

const ExpiredPromotionCode = ({ eventTime, extra }) => {
  return (
    <div className="d-flex gap-4">
      <div className="innerIconUserActivities">
        <CouponExpired size={22} color="var(--persian-red)" />
      </div>
      <div className="contentUserActivities">
        <p className="activitiesTitle">
          Coupon {extra?.code && <b>"{extra?.code}"</b>} discount has expired
        </p>
        <p className="activitiesTime">{eventTime}</p>
      </div>
    </div>
  )
}

export default ExpiredPromotionCode
