import { useAutoAnimate } from '@formkit/auto-animate/react'
import { isEmpty } from 'lodash'

import IconArrowSort from '../../../components/IconArrowSort'
import UserFeedback from './UserFeedback'

const FeedbackList = ({ dataRender, filterData, onOrder }) => {
  const [parent] = useAutoAnimate()

  if (isEmpty(dataRender)) {
    return (
      <img
        className="emptyDataImage"
        alt="empty Data Illustration"
        src="/images/emptyDataIllustration.jpg"
      />
    )
  }

  return (
    <>
      <p onClick={() => onOrder('date')} className="sortFeedbackByDate">
        Sort by Date{' '}
        <IconArrowSort
          isSorted={filterData.order_by === 'date'}
          sortType={filterData?.order_direction}
        />
      </p>
      <div className="innerFeedbacks" ref={parent}>
        {dataRender.map((item) => (
          <UserFeedback key={`${item.createdAt}-${item.userId}`} {...item} />
        ))}
      </div>
    </>
  )
}

export default FeedbackList
