import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Dropdown } from 'react-bootstrap'

import useTime from '../utils/hooks/useTime'

import CustomToggle from './CustomToggle'
import DropdownInput from './DropdownInput'

const DropdownTimezone = ({
  id,
  value,
  error,
  onChange,
  label = '',
  titleClass = '',
  disabled = false,
  innerDropdown = '',
  customDropdownMenu = '',
  title = 'Select timezone',
}) => {
  const [searchValue, setSearchValue] = useState('')
  const { allTimezones, getTimezoneInfo } = useTime()

  const timezoneName = getTimezoneInfo(value.timezoneName)?.title ?? title
  const filteredTimezone = allTimezones?.filter(
    ({ title }) => !searchValue || title.toLowerCase().includes(searchValue.toLowerCase())
  )

  const [titleValue, setTitleValue] = useState({
    timezoneName,
    selected: false,
  })

  useEffect(() => {
    if (!titleValue.selected) {
      setTitleValue({ timezoneName, selected: false })
    }
  }, [value])

  return (
    <div className={`innerDropdown ${innerDropdown}`}>
      {!isEmpty(label) && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
      <Dropdown id={id}>
        <Dropdown.Toggle disabled={disabled} as={CustomToggle}>
          <DropdownInput
            error={error}
            disabled={disabled}
            titleClass={titleClass}
            title={titleValue?.timezoneName}
            selectedValue={titleValue?.selected}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`customDropdownMenu ${customDropdownMenu}`}>
          <input
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search..."
            className="inputSearchDropdown"
          />
          {filteredTimezone?.map((item, index) => (
            <Dropdown.Item
              key={index}
              className='className="itemMenu"'
              onClick={() => {
                onChange({ timezoneName: item.title, ...item })
                setTitleValue({ timezoneName: item.title, selected: true })
              }}
            >
              {item.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default DropdownTimezone
