import { Controller } from 'react-hook-form'

import SwitchMessageType from '../SwitchMessageType'
import { MESSAGE_TYPES } from '../../../../../utils/constants'
import SelectAbsolutTime from '../SelectAbsolutTime'
import SelectRelativeTime from '../SelectRelativeTime'

const ControllerWaitingDays = ({ name, control, dateView, showSwitch, onSwitchType }) => {
  const SwitchItems = {
    [MESSAGE_TYPES.ABSOLUTE]: SelectAbsolutTime,
    [MESSAGE_TYPES.DELAYED]: SelectRelativeTime,
    [MESSAGE_TYPES.RELATIVE]: SelectRelativeTime,
  }
  const InputSelectDate = SwitchItems[dateView]

  return (
    <>
      <SwitchMessageType show={showSwitch} onSwitch={onSwitchType} activeElement={dateView} />
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputSelectDate onChange={onChange} value={value} error={error} />
        )}
      />
    </>
  )
}

export default ControllerWaitingDays
