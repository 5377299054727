import { find, isEmpty, reduce } from 'lodash'
import { useQuery } from '@tanstack/react-query'

import { ACHIEVEMENTS_INFO, ACHIEVEMENTS_TITLE_TYPES, THEMES_ID } from '../../../utils/constants'
import { getUserAchievements } from '../../../api/user'

import './styles.css'

const Achievements = ({ themeId = THEMES_ID.meditation }) => {
  const { data: { data: userAchievements } = [] } = useQuery({
    queryKey: ['userThemeAchievements', themeId],
    queryFn: () => getUserAchievements({ themeId: themeId }),
  })

  const formattedDataAchievements = () => {
    return reduce(
      ACHIEVEMENTS_INFO,
      (res, value, key) => {
        const achievementsByTypes = reduce(
          value,
          (res, value, key) => {
            const completedAchieve = find(userAchievements, { achievement: key })

            return [
              ...res,
              {
                ...value,
                isCompleted: !isEmpty(completedAchieve),
                count: completedAchieve?.counter,
              },
            ]
          },
          []
        ).sort((a, b) => b.isCompleted - a.isCompleted)
        return [...res, { title: ACHIEVEMENTS_TITLE_TYPES[key], data: achievementsByTypes }]
      },
      []
    )
  }
  const achievements = formattedDataAchievements()

  const Achieve = ({ achievement }) => {
    return (
      <div className={`achieve ${achievement?.isCompleted && 'achievementCompeted'}`}>
        <img className="achieveIcon" src={achievement?.imageSrc} alt="achievement" />
        {achievement?.count > 1 && (
          <div className="achievementsCounter">X {achievement?.count}</div>
        )}
        <p className="achieveName">{achievement?.title}</p>
      </div>
    )
  }

  return (
    <>
      <h1 className="titleProfileHead">Achievements</h1>
      {achievements.map((achieve) => (
        <div key={achieve?.title}>
          {!isEmpty(achieve?.title) && <p className="titleAchievementsType">{achieve?.title}</p>}
          <div className="innerAchievements">
            {achieve?.data.map((achieveInfo) => (
              <Achieve key={achieveInfo.title} achievement={achieveInfo} />
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

export default Achievements
