import { useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import moment from 'moment-timezone'
import { map, isEmpty, isNil } from 'lodash'
import { Bar, BarChart, Brush, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts'

import {
  CONTENT_STATUSES,
  USER_CONTENT_HISTORY_STATUSES,
  LEGEND_LIST_USER_HISTORY_CONTENT,
} from '../../utils/constants'
import { getContentStatisticsDates } from '../../api/admin/user'
import { getAllUserSubscriptions } from '../../api/admin/user'
import useContent from '../../utils/hooks/useContent'
import TooltipContentHistory from '../TooltipContentHistory'
import CustomDropdown from '../CustomDropdown'

const ContentHistory = ({ userId }) => {
  const [selectedThemeId, setSelectedThemeId] = useState(null)
  const { redirectToContentByContentId } = useContent({ themeId: selectedThemeId })

  const mapContentEntry = ([deliveryDate, value]) => {
    const externalId = Object.keys(value)[0]
    const content = value[externalId]

    const formattedDate = (msgStatus) =>
      content?.[msgStatus] ? moment(content?.[msgStatus]).utcOffset(0) : null

    const deliveredContent = formattedDate(CONTENT_STATUSES.DELIVERED_CONTENT)
    const nextDayAfterDeliveredContent = deliveredContent?.clone().add(1, 'day')
    const startedContent = formattedDate(CONTENT_STATUSES.STARTED_CONTENT)
    const skippedContent = formattedDate(CONTENT_STATUSES.SKIPPED_CONTENT)
    const finishedContent = formattedDate(CONTENT_STATUSES.FINISHED_CONTENT)

    let desiredKey = CONTENT_STATUSES.DELIVERED_CONTENT

    if (!isNil(skippedContent)) desiredKey = CONTENT_STATUSES.SKIPPED_CONTENT

    if (
      (!deliveredContent && startedContent) ||
      startedContent?.isBetween(deliveredContent, nextDayAfterDeliveredContent)
    )
      desiredKey = CONTENT_STATUSES.STARTED_CONTENT

    if (
      (!deliveredContent && finishedContent) ||
      finishedContent?.isBetween(deliveredContent, nextDayAfterDeliveredContent)
    )
      desiredKey = CONTENT_STATUSES.FINISHED_CONTENT

    return {
      externalId,
      deliveryDate,
      [desiredKey]: 1,
      contentValue: content,
      name: moment(deliveryDate).format('ddd, D MMM'),
      ...USER_CONTENT_HISTORY_STATUSES[desiredKey],
    }
  }

  const { data: userThemes, isLoading: isLoadingUserThemes } = useQuery({
    queryKey: ['userThemes', userId],
    queryFn: () => getAllUserSubscriptions({ userId, withPagination: false }),
    placeholderData: keepPreviousData,
    select: ({ data }) => data,
  })

  const { data: contentHistory } = useQuery({
    queryKey: ['userContentHistory', userId, selectedThemeId],
    queryFn: () =>
      getContentStatisticsDates({
        userId,
        themeId: selectedThemeId ?? userThemes?.[0]?.themeId,
      }),
    select: ({ data }) => {
      if (isEmpty(data)) return []
      return Object.entries(data)
        .map(mapContentEntry)
        .sort((a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate))
    },
    placeholderData: keepPreviousData,
    enabled: !isLoadingUserThemes,
  })

  return (
    <>
      <CustomDropdown
        title="Themes"
        value={selectedThemeId ?? userThemes?.[0]?.themeId}
        data={userThemes}
        valueKey="themeId"
        titleKey="themeName"
        innerDropdown="mw-100"
        customDropdownMenu="w-100"
        onChange={setSelectedThemeId}
      />
      {!isEmpty(contentHistory) ? (
        <ResponsiveContainer height={342} width="100%">
          <BarChart
            data={contentHistory}
            margin={{
              top: 20,
              right: 60,
              left: 50,
            }}
          >
            <XAxis dataKey="name" />
            <Tooltip content={<TooltipContentHistory />} />
            <Legend iconType="square" payload={LEGEND_LIST_USER_HISTORY_CONTENT} />
            {map(USER_CONTENT_HISTORY_STATUSES, (value, key) => (
              <Bar
                onClick={({ externalId }) => redirectToContentByContentId(externalId)}
                key={key}
                dataKey={key}
                maxBarSize={100}
                className="cursorPointer"
                stackId="themeStatistics"
                {...value}
              />
            ))}
            <Brush
              dataKey="name"
              height={30}
              startIndex={contentHistory.length < 20 ? 0 : contentHistory.length - 20}
              stroke="#8884d8"
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <img
          alt="Empty date"
          height={342}
          src="/images/emptyDataIllustration.jpg"
          className="m-auto d-block"
        />
      )}
    </>
  )
}

export default ContentHistory
