import { isEmpty } from 'lodash/lang'

import { TABLE_ROW_TYPE } from '../../utils/constants'
import IconArrowSort from '../IconArrowSort'
import UserAnswerRow from './component/UserAnswerRow'
import GiftPricingRow from './component/GiftPricingRow'

import './styles.css'

const TABLE_ROW = {
  [TABLE_ROW_TYPE.TABLE_ROW_USER_ANSWER]: UserAnswerRow,
  [TABLE_ROW_TYPE.TABLE_ROW_GIFT_PRICING]: GiftPricingRow,
}

const CustomTable = ({
  titleTable,
  tableHeader = [],
  cellData = [],
  sortType = '',
  sortColumn = '',
  customTableClass = '',
  customTitleClassName = '',
  customWrapperClassName = '',
  type = TABLE_ROW_TYPE.TABLE_ROW_USER_ANSWER,
  noDataMessage = 'No items to display',
  ...rest
}) => {
  const RowComponent = TABLE_ROW[type]

  return (
    <div className={customWrapperClassName}>
      {!isEmpty(titleTable) && (
        <h2 className={`titleTable ${customTitleClassName}`}>{titleTable}</h2>
      )}
      <table className={`customTable ${customTableClass}`}>
        <thead className="innerHeadTable">
          <tr>
            {tableHeader.map(
              (
                { title, accessor, className = '', iconSort = false, onClickSort = () => {} },
                index
              ) => (
                <td
                  key={index}
                  onClick={() => onClickSort(accessor)}
                  className={`headColumn ${className} ${iconSort ? 'cursorPointer' : ''}`}
                >
                  {title}
                  {iconSort && (
                    <IconArrowSort isSorted={accessor === sortColumn} sortType={sortType} />
                  )}
                </td>
              )
            )}
          </tr>
        </thead>
        {!isEmpty(cellData) && (
          <tbody>
            {cellData.map((item, index) => (
              <RowComponent
                item={item}
                key={index}
                itemIndex={index}
                headerData={tableHeader}
                {...rest}
              />
            ))}
          </tbody>
        )}
      </table>
      {isEmpty(cellData) && <p className="emptyTable">{noDataMessage}</p>}
    </div>
  )
}

export default CustomTable
