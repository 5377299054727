import { useState } from 'react'

import './styles.css'

const StarRating = ({
  value = 0,
  onChange,
  countStars = 5,
  containerStyles = '',
  disabled = false,
}) => {
  const [hover, setHover] = useState(0)

  return (
    <div className={`starRating ${containerStyles}`}>
      {[...Array(countStars)].map((star, index) => {
        const starRating = index + 1
        const additionalClassName = starRating <= (value || hover) ? 'on' : 'off'

        return (
          <button
            type="button"
            key={index}
            disabled={value !== 0 || disabled}
            className={`buttonStar ${additionalClassName}`}
            onClick={() => onChange(starRating)}
            onMouseEnter={() => setHover(starRating)}
            onMouseLeave={() => setHover(value)}
          >
            <span>&#9733;</span>
          </button>
        )
      })}
    </div>
  )
}

export default StarRating
