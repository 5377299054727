import { isEmpty } from 'lodash'
import Mask from 'react-text-mask'
import { Tooltip, Whisper } from 'rsuite'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { PHONE_MASK } from '../utils/constants'
import { Info } from './SvgIcon'

const InputMask = ({
  inputRef,
  innerClassName = '',
  label,
  isNumberMask = false,
  defaultMaskOptions,
  mask = PHONE_MASK,
  inputType,
  error,
  showIconInfo = false,
  infoText = '',
  ...rest
}) => {
  const generateNumberMask = createNumberMask(defaultMaskOptions)

  return (
    <div className={innerClassName}>
      {!isEmpty(label) && (
        <div className="d-flex align-items-center gap-1 inputLabel">
          <label>{label}</label>
          {showIconInfo && (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={
                <Tooltip>
                  <p>{infoText}</p>
                </Tooltip>
              }
            >
              <span className="d-flex">
                <Info fill="var(--darkest-gray)" size={15} />
              </span>
            </Whisper>
          )}
        </div>
      )}
      <Mask
        type={inputType}
        ref={inputRef}
        guide={false}
        className={`form-control inputStyle ${error ? 'errorInputText' : ''}`}
        mask={isNumberMask ? generateNumberMask : mask}
        {...rest}
      />
    </div>
  )
}

export default InputMask
