import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TABLE_HEADER_INTEREST } from '../../../utils/constants'
import { createInterest, deleteInterest, getAllInterests } from '../../../api/interests'
import { setLoadingAdminProjectData } from '../../../redux/actions/ui'
import ManageList from '../../../components/ManageList'
import FormManageInterest from './components/FormManageInterest'
import RowTable from './components/RowTable'

import './styles.css'

const Interests = () => {
  const [dataInterest, setDataInterest] = useState([])
  const dispatch = useDispatch()

  const removeInterest = ({ id: idDeleteTag }, setShowModalDelete) => {
    deleteInterest(idDeleteTag)
      .then(() => setDataInterest((oldState) => oldState.filter(({ id }) => id !== idDeleteTag)))
      .then(() => setShowModalDelete(false))
  }

  const makeNewInterest = (data, formRef) => {
    createInterest(data)
      .then(({ data }) => setDataInterest((oldState) => [...oldState, data]))
      .then(() => formRef.current?.reset())
  }

  useEffect(() => {
    dispatch(setLoadingAdminProjectData(true))
    getAllInterests()
      .then(({ data }) => setDataInterest(data))
      .finally(() => dispatch(setLoadingAdminProjectData(false)))
  }, [])

  return (
    <div>
      <ManageList
        title="Interests"
        Row={RowTable}
        data={dataInterest}
        setData={setDataInterest}
        removeItem={removeInterest}
        createItem={makeNewInterest}
        emptyText="No interests found"
        FormManage={FormManageInterest}
        tableHeader={TABLE_HEADER_INTEREST}
        textConfirmDelete="You want delete this interest?"
      />
    </div>
  )
}
export default Interests
