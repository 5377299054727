import { useCallback, useMemo } from 'react'
import { camelCase, map } from 'lodash'
import moment from 'moment-timezone'
import { Tooltip, Whisper } from 'rsuite'

import { CONTENT_STATUSES_DETAILS, DATE_FORMAT_MDY } from '../../../utils/constants'

const ContentStatus = ({ status, contentActivities, hideTooltip }) => {
  const { label, fill } = useMemo(() => CONTENT_STATUSES_DETAILS[camelCase(status)] ?? {}, [status])

  const renderTooltip = useCallback(() => {
    return (
      <div>
        {map(contentActivities, (date, status) => {
          const formattedDate = moment(date).format(DATE_FORMAT_MDY)

          return (
            <p>
              <b>{CONTENT_STATUSES_DETAILS[status]?.label}: </b>
              {formattedDate}
            </p>
          )
        })}
      </div>
    )
  }, [contentActivities])

  return (
    <Whisper
      speaker={<Tooltip>{renderTooltip()}</Tooltip>}
      trigger="hover"
      disabled={hideTooltip || Object?.keys(contentActivities || {})?.length <= 1}
      placement="top"
    >
      <div style={{ backgroundColor: fill }} className="statusCode">
        {label}
      </div>
    </Whisper>
  )
}

export default ContentStatus
