import { useEffect, useState } from 'react'
import { isEmpty, isNil, omitBy } from 'lodash'
import ReactPaginate from 'react-paginate'

import {
  ORDER_DIRECTION_ASC,
  SETTINGS_PAGINATION,
  ORDER_DIRECTION_DESC,
  SIZE_PAGINATE_FEEDBACKS,
} from '../../utils/constants'
import { getFeedbacks } from '../../api/feedback'
import FeedbackFilter from './components/FeedbackFilter'
import FeedbackList from './components/FeedbackList'

import './styles.css'

const FeedbackTable = ({ userId = '', paginationSize = SIZE_PAGINATE_FEEDBACKS }) => {
  const [filterData, setFilterData] = useState({})
  const [dataFeedback, setDataFeedback] = useState([])
  const [paginationSettings, setPaginationSettings] = useState({ selected: 1 })
  const [rangeMinimalDate, setRangeMinimalDate] = useState('')

  const changeFilterData = (data) => {
    setPaginationSettings((oldState) => ({ ...oldState, selected: 1 }))
    setFilterData((oldState) => ({ ...oldState, ...data }))
  }
  const orderBy = (columnKey) => {
    setFilterData((oldState) => {
      const newOrderDirection =
        oldState?.order_direction === ORDER_DIRECTION_ASC && oldState?.order_by === columnKey
          ? ORDER_DIRECTION_DESC
          : ORDER_DIRECTION_ASC

      return { ...oldState, order_direction: newOrderDirection, order_by: columnKey }
    })
  }

  useEffect(() => {
    const requestData = omitBy(filterData, isNil)
    const userParams = !isEmpty(userId) ? { user_id: userId } : {}

    getFeedbacks({
      ...requestData,
      ...userParams,
      size: paginationSize,
      page: paginationSettings.selected,
    }).then(({ data }) => {
      const { feedbacks, oldestFeedback } = data
      setRangeMinimalDate(oldestFeedback)
      setDataFeedback(feedbacks.data)
      setPaginationSettings((oldState) => ({ ...oldState, pageCount: feedbacks.lastPage }))
    })
  }, [paginationSettings.selected, filterData, userId])

  return (
    <div className="innerFeedbackPage">
      <div className="containerAdminMainInfo">
        <h1 className="titleAdminMainInfo">Feedback</h1>
        <FeedbackFilter
          minDate={rangeMinimalDate}
          onChangeFilter={changeFilterData}
          selectedFeedbackType={filterData?.type}
        />
      </div>
      <FeedbackList filterData={filterData} dataRender={dataFeedback} onOrder={orderBy} />
      {!isEmpty(dataFeedback) && paginationSettings.pageCount !== 1 ? (
        <ReactPaginate
          {...SETTINGS_PAGINATION}
          pageCount={paginationSettings.pageCount}
          forcePage={paginationSettings.selected - 1}
          onPageChange={({ selected }) =>
            setPaginationSettings((oldState) => ({ ...oldState, selected: selected + 1 }))
          }
        />
      ) : null}
    </div>
  )
}

export default FeedbackTable
