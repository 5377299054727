import { useCallback, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import randomColor from 'randomcolor'

import { SETTINGS_RANDOM_COLOR } from '../../../../utils/constants'
import { TAG_FIELD } from '../../../../utils/constantsForms'
import HookForm from '../../../../components/HookForm'
import PrimaryButton from '../../../../components/PrimaryButton'
import Button from '../../../../components/Button'

const FormManageTag = ({
  show,
  value,
  setShow,
  onSubmit,
  className = '',
  buttonText,
  setTagPreview = () => {},
  showPreview = true,
}) => {
  const formRef = useRef(null)

  const Footer = () => {
    const disableSubmitButton =
      isEmpty(formRef.current?.watch('tagInfo').name) || isEmpty(formRef.current?.dirtyFields)
    const onCancel = useCallback(() => {
      setTagPreview(value)
      setShow(false)
    }, [])

    return (
      <div className="innerButtonsManage">
        <PrimaryButton
          customClass="buttonNewItemList"
          text={buttonText}
          disabled={disableSubmitButton}
        />
        <Button type="button" customClass="buttonNewItemList" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    )
  }

  useEffect(() => {
    const color = randomColor(SETTINGS_RANDOM_COLOR)
    formRef.current?.reset({ tagInfo: { color, name: '', ...value } })
  }, [show])

  if (!show) {
    return null
  }

  return (
    <HookForm
      ref={formRef}
      onSubmit={(data) => onSubmit(data, formRef)}
      className={`formTag ${className}`}
      fields={[{ ...TAG_FIELD, showPreview, setTagPreview }]}
      Footer={Footer}
    />
  )
}

export default FormManageTag
