import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { ISO_DATE_FORMAT } from '../../utils/constants'

import './styles.css'

const InputDate = ({
  value = '',
  error,
  onChange,
  label = '',
  inputRef = null,
  innerClassName = '',
  dateFormat = ISO_DATE_FORMAT,
  placeholder = ISO_DATE_FORMAT,
  ...rest
}) => {
  const handleChangeValue = (date) => {
    const formattedValue = moment(date, dateFormat, true).isValid()
      ? moment(date).utcOffset(0, true).format(dateFormat)
      : ''
    onChange(formattedValue)
  }
  return (
    <div className={innerClassName}>
      {!isEmpty(label) && <label className="inputLabel">{label}</label>}
      <DatePicker
        showIcon
        dateFormat="MM.dd.yyyy"
        dropdownMode="select"
        popperClassName="dropdownDate"
        selected={!isEmpty(value) ? moment(value).toDate() : ''}
        ref={inputRef}
        placeholderText={placeholder}
        onChange={handleChangeValue}
        className={`inputStyle ${error ? 'errorInputText' : ''}`}
        {...rest}
      />
    </div>
  )
}

export default InputDate
