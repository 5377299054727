import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { cloneDeep, debounce, every, findIndex, isEmpty, isNil, some, xorBy } from 'lodash'
import { Virtuoso } from 'react-virtuoso'
import { Nav } from 'rsuite'
import { useMutation } from '@tanstack/react-query'

import {
  CHAT_LIST_TABS,
  SIZE_PAGINATION_CHAT_LIST,
  WEBSOCKET_CONNECTION_CHANNEL,
  WEBSOCKET_STATES,
} from '../../../utils/constants'
import {
  getChats,
  toggleStarred,
  sendAdminMultipleMessage,
  toggleAttention,
} from '../../../api/dialog'
import { hideAdminMenu } from '../../../redux/actions/ui'
import useWebSocket from '../../../utils/hooks/useWebSocket'
import {
  Cross,
  IconStar,
  Messenger,
  Check2All,
  Check2Circle,
  MessageSquareEdit,
  IconStarOff,
  Info,
} from '../../../components/SvgIcon'
import ModalGroupMessageSender from '../../../components/Modals/ModalGroupMessageSender'
import ContextMenu from '../../../components/ContextMenu'
import CustomInput from '../../../components/CustomInput'
import ChatWindow from './components/ChatWindow'
import PreviewChat from './components/PreviewChat'

import './styles.css'

const UsersChat = () => {
  const [chats, setChats] = useState([])
  const [selectedChats, setSelectedChats] = useState([])
  const [lastPage, setLastPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [activeChat, setActiveChat] = useState(null)
  const [activeTab, setActiveTab] = useState(CHAT_LIST_TABS.ALL)
  const [showModalGroupMessageSender, setShowModalGroupMessageSender] = useState(false)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { state } = useLocation()
  const { subscribeChatList } = useWebSocket()
  const webSocketSubscribeChatList = useRef(null)

  const isSelectedOnlyStarred = every(selectedChats, { isStared: {} })
  const IconStarred = !isSelectedOnlyStarred ? IconStar : IconStarOff

  const handleSyncChatInfo = (data) => {
    setChats((oldState) => {
      const userIndex = findIndex(oldState, ({ id }) => id === data.id)
      if (userIndex >= 0) {
        const filterChats = oldState.filter(({ id }) => id !== data.id)
        return [data, ...filterChats]
      }

      if (currentPage < lastPage) {
        const newArrayCharts = cloneDeep(oldState)
        newArrayCharts.pop()

        return [data, ...newArrayCharts]
      }
      return oldState
    })
  }

  const handleActionToggleStarred = (data) => {
    return setChats((oldState) => {
      return oldState.reduce((res, item) => {
        if (data.id === item.id) {
          return !isEmpty(data.isStared) || activeTab === CHAT_LIST_TABS.ALL ? [...res, data] : res
        }
        return [...res, item]
      }, [])
    })
  }

  const handleReadMessage = ({ userId }) => {
    setChats((oldState) => {
      return oldState.reduce((res, value) => {
        if (value.id === userId) {
          const unreadMessagesCount = value.unreadMessagesCount - 1
          return [...res, { ...value, unreadMessagesCount }]
        }

        return [...res, value]
      }, [])
    })
  }

  const handleChatSelect = (user) => {
    setSelectedChats((prevSelectedChats) => xorBy(prevSelectedChats, [user], 'id'))
  }

  const mutationAttentionUser = useMutation({
    mutationKey: ['toggleUserAttention'],
    mutationFn: toggleAttention,
    onSuccess: ({ data }) => {
      setChats((oldState) =>
        oldState.map((userChatInfo) =>
          userChatInfo.id === data.id ? { ...userChatInfo, ...data } : userChatInfo
        )
      )
    },
  })

  const selectChat = (userData) => {
    if (isEmpty(selectedChats)) {
      const { id } = userData
      setActiveChat(id)
      return navigate('.', { state: { selectedUser: id } })
    }
    handleChatSelect(userData)
  }

  const handleSearch = debounce(({ target }) => {
    setChats([])
    setCurrentPage(1)
    setSearchValue(target.value)
  }, 500)

  const switchTab = (tabKey) => {
    if (tabKey !== activeTab) {
      setChats([])
      setActiveTab(tabKey)
      setCurrentPage(1)
    }
  }

  const onEndScrollChatList = () => {
    if (lastPage > currentPage) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1)
    }
  }

  const sendMultipleMessages = (data) => {
    const messageRecipients = selectedChats.map(({ id }) => id)

    return sendAdminMultipleMessage({ users_ids: messageRecipients, ...data }).then(() =>
      setSelectedChats([])
    )
  }

  useEffect(() => {
    const requestOptions = {
      page: currentPage,
      search: searchValue,
      size: SIZE_PAGINATION_CHAT_LIST,
      ...(CHAT_LIST_TABS.STARRED === activeTab && {
        isStared: CHAT_LIST_TABS.STARRED === activeTab,
      }),
      ...(CHAT_LIST_TABS.ATTENTION === activeTab && {
        needsAttention: CHAT_LIST_TABS.ATTENTION === activeTab,
      }),
    }

    getChats(requestOptions).then(({ data: { data, lastPage } }) => {
      setChats((prevChats) => [...prevChats, ...data])
      setLastPage(lastPage)
    })
  }, [currentPage, searchValue, activeTab])

  useEffect(() => {
    webSocketSubscribeChatList.current = subscribeChatList({
      channel: WEBSOCKET_CONNECTION_CHANNEL.ADMIN_CHATS,
      handleReadMessage,
      handleSyncChatInfo,
      handleActionToggleStarred,
      onReady: () => {
        if (!isNil(state?.selectedUser)) {
          setActiveChat(state?.selectedUser)
        }
      },
    })

    return () => {
      if (webSocketSubscribeChatList.current?.state === WEBSOCKET_STATES.OPEN) {
        webSocketSubscribeChatList.current.close()
      }
    }
  }, [])

  useEffect(() => {
    dispatch(hideAdminMenu())
  }, [])

  return (
    <div className="innerAdminGlobalChat">
      <div className={`innerChatList ${activeChat ? 'hide' : ''}`}>
        <div className="innerHeaderChatList">
          <CustomInput
            disabled={false}
            placeholder="Search..."
            onChange={handleSearch}
            innerClassName="inputSearchUserChat"
          />
        </div>
        <Nav
          reversed
          appearance="tabs"
          activeKey={activeTab}
          onSelect={switchTab}
          className="innerNavigationHeader"
        >
          <Nav.Item eventKey={CHAT_LIST_TABS.ALL} icon={<Messenger fill="var(--catalina-blue)" />}>
            All
          </Nav.Item>
          <Nav.Item
            eventKey={CHAT_LIST_TABS.STARRED}
            icon={<IconStar size={20} color="var(--catalina-blue)" fill="var(--catalina-blue)" />}
          >
            Starred
          </Nav.Item>
          <Nav.Item
            eventKey={CHAT_LIST_TABS.ATTENTION}
            icon={<Info size={20} color="var(--catalina-blue)" fill="var(--catalina-blue)" />}
          >
            Attention
          </Nav.Item>
        </Nav>
        {!isEmpty(selectedChats) && (
          <div className="innerFunctionalityChatList">
            <IconStarred
              onClick={() => {
                toggleStarred({
                  ids: selectedChats.map(({ id }) => id),
                  to_track: !isSelectedOnlyStarred,
                }).then(() => setSelectedChats([]))
              }}
              title={!isSelectedOnlyStarred ? 'Add to starred' : 'Remove from starred'}
              size={20}
              fill="var(--catalina-blue)"
            />
            <MessageSquareEdit
              size={20}
              fill="var(--catalina-blue)"
              title="Message multiple users"
              onClick={() => setShowModalGroupMessageSender(true)}
            />
            <Check2All
              size={20}
              fill="var(--catalina-blue)"
              title="Select all"
              onClick={() => setSelectedChats(chats)}
            />
            <Cross onClick={() => setSelectedChats([])} size={20} color="var(--persian-red)" />
          </div>
        )}
        <Virtuoso
          data={chats}
          atBottomThreshold={100}
          endReached={onEndScrollChatList}
          itemContent={(_, rowData) => {
            const { isStared: staredObj, needsAttention, id } = rowData
            const isStared = isEmpty(staredObj)
            const isSelected = some(selectedChats, { id })
            const isActiveChat = id === activeChat

            return (
              <ContextMenu
                dataItem={rowData}
                menuList={[
                  {
                    name: isStared ? 'Add to starred' : 'Remove from starred',
                    Icon: isStared ? IconStar : IconStarOff,
                    onClick: ({ id }) => toggleStarred({ ids: [id], toTrack: isStared }),
                  },
                  {
                    name: !needsAttention ? 'Needs attention' : 'Remove from attention list',
                    Icon: !needsAttention ? Info : Cross,
                    onClick: ({ id }) => mutationAttentionUser.mutate(id),
                  },
                  {
                    name: 'Select',
                    Icon: Check2Circle,
                    onClick: (data) => handleChatSelect(data),
                  },
                ]}
                showContextMenu={isEmpty(selectedChats)}
              >
                <PreviewChat
                  dataInfo={rowData}
                  onSelectChat={selectChat}
                  customClassName={`${isSelected ? 'selectModeChat' : ''} ${
                    isActiveChat ? 'activeChat' : ''
                  }`}
                />
              </ContextMenu>
            )
          }}
        />
      </div>
      <div className="innerUserChat">
        <img className="bgChat" alt="bg-chat" src="/images/bg-chat.svg" />
        {!isNil(activeChat) ? (
          <ChatWindow userId={activeChat} onHide={() => setActiveChat(null)} />
        ) : (
          <div className="messagePlaceholder">Select a chat to start messaging</div>
        )}
      </div>
      <ModalGroupMessageSender
        onSend={sendMultipleMessages}
        show={showModalGroupMessageSender}
        onHide={() => setShowModalGroupMessageSender(false)}
      />
    </div>
  )
}

export default UsersChat
