import { useEffect, useState } from 'react'
import { isEmpty, isNil, isObject, isString } from 'lodash'

import { EXCEPT_TYPE_IMAGE } from '../../utils/constants'
import { Plus } from '../SvgIcon'

import './styles.css'

const CustomInputFile = ({
  error,
  onChange,
  value = '',
  innerClassName = '',
  acceptTypes = EXCEPT_TYPE_IMAGE,
}) => {
  const [image, setImage] = useState('')
  const checkEmptyImage = !isEmpty(image) || (isString(value) && !isEmpty(value))

  const newFile = (e) => {
    const image = e.target?.files?.[0]
    if (!isNil(image)) {
      onChange(image)
      setImage(URL.createObjectURL(image))
    }
  }

  useEffect(() => {
    if (!isObject(value)) {
      setImage(value)
    }
  }, [value])

  return (
    <div style={error ? { borderColor: 'red' } : {}} className={`innerInputFile ${innerClassName}`}>
      {checkEmptyImage ? <img className="filePreview" src={image || value} alt="" /> : null}
      <div style={{ opacity: checkEmptyImage ? 0 : 1 }} className="wrapperBgHover">
        <Plus className="innerAddBtnImage" />
      </div>
      <input onChange={newFile} className="inputFile" type="file" accept={acceptTypes} />
    </div>
  )
}

export default CustomInputFile
