import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useGoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'

import { enableGoogleCalendar } from '../../api/calendar'
import { setNewEnabledProviders } from '../../redux/actions/user'

const useGoogleAuth = ({ onComplete }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onError = () => {
    dispatch(setNewEnabledProviders({ google: false }))
    toast.error('An error occurred during authorization, please try again.')
  }

  const onSuccess = (tokenResponse) => {
    setLoading(true)
    enableGoogleCalendar(tokenResponse)
      .then(onComplete)
      .catch(() => {
        dispatch(setNewEnabledProviders({ google: false }))
        toast.error('An error occurred during sync your calendars')
      })
      .finally(() => setLoading(false))
  }

  const authWithGoogle = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onError,
    flow: 'auto-code',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    scope: 'openid email profile https://www.googleapis.com/auth/calendar',
  })

  return { authWithGoogle, loading }
}

export default useGoogleAuth
